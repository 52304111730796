import React, { useState } from "react";
import "./OfferDepartment.css";
import ERP from "../../../Images/OfferDepartment/erpicon.png";
import Software from "../../../Images/OfferDepartment/software.png";
import WebDev from "../../../Images/OfferDepartment/website.png";
import hardware from "../../../Images/OfferDepartment/HardwareSolutin.png";
import dataCenter from "../../../Images/OfferDepartment/Data Center.png";
import manageServices from "../../../Images/OfferDepartment/ManageService.png";
import SandS from "../../../Images/OfferDepartment/Security&Survillience.png";
import MobileApp from "../../../Images/OfferDepartment/mobileapp.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faRightFromBracket,
} from "@fortawesome/free-solid-svg-icons";

const OfferDepartments = () => {
  return (
    <div className="container">
      <div className="mb-5">
        <h5 className="offer-h5">WHAT WE OFFER</h5>
        <h2 className="offer-h2">We Create Your Amazing Digital Experience</h2>
      </div>

      <section className="row offer-map">
        <div className="offer-card-div col-lg-3 col-md-4 col-sm-6 col-12 ">
          <img className="offerImg1 img-fuild" src={ERP} alt="" />
          <h4 className="home-offer-header">ERP Solution</h4>
          <p className="offer-pText">
            An ERP system provides a centralized real-time view of an
            organization's information, enabling businesses to make data-driven
            decisions{" "}
          </p>
          <a href="/softwareSolution" target="_blank">
            Read More{" "}
            <span>
              <FontAwesomeIcon icon={faChevronRight} />
            </span>
          </a>
        </div>

        <div className="offer-card-div col-lg-3 col-md-4 col-sm-6 col-12">
          <img className="offerImg2 img-fuild" src={Software} alt="" />
          <h4 className="home-offer-header">Software development</h4>
          <p className="offer-pText">
            The process of custom software development begins with gathering
            requirements and defining the scope of the project
          </p>
          <a href="/softwareSolution" target="_blank">
            Read More{" "}
            <span>
              <FontAwesomeIcon icon={faChevronRight} />
            </span>
          </a>
        </div>
        <div className="offer-card-div col-lg-3 col-md-4 col-sm-6 col-12">
          <img className="offerImg3 img-fuild" src={WebDev} alt="" />
          <h4 className="home-offer-header">Website & Webapp</h4>

          <p className="offer-pText">
            A well-designed and developed website can help businesses increase
            their online visibility, reach more customers
          </p>
          <a href="/softwareSolution" target="_blank">
            Read More{" "}
            <span>
              <FontAwesomeIcon icon={faChevronRight} />
            </span>
          </a>
        </div>
        <div className="offer-card-div col-lg-3 col-md-4 col-sm-6 col-12">
          <img className="offerImg4 img-fuild" src={MobileApp} alt="" />
          <h4 className="home-offer-header"> Mobile application</h4>

          <p className="offer-pText">
            A well-designed mobile app can provide businesses with a powerful
            tool to engage with customers, improve brand awareness
          </p>
          <a href="/softwareSolution" target="_blank">
            Read More{" "}
            <span>
              <FontAwesomeIcon icon={faChevronRight} />
            </span>
          </a>
        </div>
        <div className="offer-card-div col-lg-3 col-md-4 col-sm-6 col-12 ">
          <img className="offerImg1 img-fuild" src={hardware} alt="" />
          <h4 className="home-offer-header">Hardware Solution</h4>
          <p className="offer-pText">
            Empowering Your Technology with Reliable Hardware Solutions
          </p>
          <a href="/hardwaresolution" target="_blank">
            Read More{" "}
            <span>
              <FontAwesomeIcon icon={faChevronRight} />
            </span>
          </a>
        </div>
        <div className="offer-card-div col-lg-3 col-md-4 col-sm-6 col-12">
          <img className="offerImg2 img-fuild" src={dataCenter} alt="" />
          <h4 className="home-offer-header">Data Center Infra</h4>
          <p className="offer-pText">
            Maximizing Efficiency and Reliability with Our Data Center Solutions
          </p>
          <a href="/dataCenter" target="_blank">
            Read More{" "}
            <span>
              <FontAwesomeIcon icon={faChevronRight} />
            </span>
          </a>
        </div>
        <div className="offer-card-div col-lg-3 col-md-4 col-sm-6 col-12">
          <img className="offerImg3 img-fuild" src={manageServices} alt="" />
          <h4 className="home-offer-header">Manage Service</h4>

          <p className="offer-pText">
            Simplifying IT Management with Our Comprehensive Managed Services
          </p>
          <a href="/manangedServices" target="_blank">
            Read More{" "}
            <span>
              <FontAwesomeIcon icon={faChevronRight} />
            </span>
          </a>
        </div>
        <div className="offer-card-div col-lg-3 col-md-4 col-sm-6 col-12">
          <img className="offerImg4 img-fuild" src={SandS} alt="" />
          <h4 className="home-offer-header"> Security & Surveillance</h4>

          <p className="offer-pText">
            Ensuring Peace of Mind with Our Security and Surveillance Solutions
          </p>
          <a href="/securitySarvillance" target="_blank">
            Read More{" "}
            <span>
              <FontAwesomeIcon icon={faChevronRight} />
            </span>
          </a>
        </div>
      </section>
    </div>
  );
};

export default OfferDepartments;
