import React, { useEffect } from "react";
import Footer from "../../Shared/Footer/Footer";
import NavigationBar from "../../Shared/NavigationBar/NavigationBar";
import ConnectCutomerServices from "../ConnectCutomerServices/ConnectCutomerServices";
import InnovativeWebsite from "../ConnectCutomerServices/InnovativeWebsite";
import Galary from "../Galary/Galary";
import HomeBanner from "../HomeBanner.js/HomeBanner";
import OfferDepartments from "../OfferDepartment/OfferDepartments";
import ReviewCutomers from "../ReviewCutomers/ReviewCutomers";
import Rules from "../Rules/Rules";
import Services from "../Services/Services";
import UsignTechnology from "../UsingTechnology/UsignTechnology";
import ValuableClients from "../ValuableClients/ValuableClients";
import ScrollTopButton from "../../ScrollTopButton/ScrollTopButton";
// import Layout from "../../QuickChatBox/Layout";
import { Helmet } from "react-helmet";

const Home = () => {
  // Spnning Reload

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <NavigationBar />

      {/* For SEO  */}
      <Helmet>
        <title>
          {" "}
          Pakiza Technovation Limited | Driving Digital Success with Innovative
          Software Services
        </title>
        <meta
          name="description"
          content="Drive digital success with Pakiza Technovation Limited, your trusted partner for innovative software services. We offer a comprehensive suite of solutions including software consulting, product engineering, and digital transformation. Our team of skilled professionals combines technical expertise with industry insights to deliver tailor-made software solutions that accelerate your business growth. From startups to enterprises, we have the expertise to bring your ideas to life and propel your digital journey to new heights. Contact us now to embark on a transformative software experience."
        ></meta>
      </Helmet>

      <HomeBanner />
      <OfferDepartments />
      <ConnectCutomerServices />
      <InnovativeWebsite />
      <Services />
      <ValuableClients />
      {/* <Galary />
      <ReviewCutomers /> 
      <Rules />*/}
      <UsignTechnology />

      <Footer />
      <ScrollTopButton />
    </div>
  );
};

export default Home;
