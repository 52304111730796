import React from "react";
import "./TeamServices.css";
import tserviceimg1 from "../../../Images/Team/team1.png";
import tserviceimg2 from "../../../Images/Team/team2.png";
import tserviceimg3 from "../../../Images/Team/team3.png";
import tserviceimg4 from "../../../Images/Team/team4.png";
import tserviceimg5 from "../../../Images/Team/team5.png";
import tserviceimg6 from "../../../Images/Team/team6.png";
import tserviceimg7 from "../../../Images/Team/team7.png";
import teamCardimg from "../../../Images/Team/bgteam.png";
import TeaService from "./TeaService";

// const teamsCardBg = {
//   background: `url(${teamCardimg})`,
//   backgroundRepeat: "no-repeat",
//   position: "relative",
//   backgroundSize: "cover",
//   overflowX: "hiden",
//   marginTop: -48,
// };

const Teaservices = () => {
  const tservices = [
    {
      _id: 1,
      introduction: "Hi! my name",
      name: "Rashed Shamsul Alam",
      designation: "CEO & Founder",
      img: tserviceimg1,
    },
    {
      _id: 2,
      introduction: "Hi! my name",
      name: "Rashed Alam",
      designation: "CIO & Business-Head",
      img: tserviceimg2,
    },
    {
      _id: 3,
      introduction: "Hi! my name",
      name: "Alam Arafat",
      designation: "Product Manager",
      img: tserviceimg3,
    },
    {
      _id: 4,
      introduction: "Hi! my name",
      name: "Nayeem Banarji",
      designation: "Business Analytics",
      img: tserviceimg4,
    },
    // {
    //   _id: 5,
    //   introduction: "Hi! my name",
    //   name: "Rakib Shaklain Alam",
    //   designation: "Full-stack Developer",
    //   img: tserviceimg5,
    // },
    // {
    //   _id: 6,
    //   introduction: "Hi! my name",
    //   name: "Munjarin Karim",
    //   designation: "Marketing Officer",
    //   img: tserviceimg6,
    // },
    // {
    //   _id: 7,
    //   introduction: "Hi! my name",
    //   name: "Tom Dicken Harry",
    //   designation: "SQA",
    //   img: tserviceimg7,
    // },
    // {
    //   _id: 8,
    //   introduction: "Hi! my name",
    //   name: "Mahmudul hasan",
    //   designation: "Apps Developer",
    //   img: tserviceimg3,
    // },
  ];
  return (
    <div className="tServices-div">
      <section className="container mt-5">
        <div className="tserevices-map">
          {tservices.map((tservice) => (
            <TeaService key={tservice._id} tservice={tservice}></TeaService>
          ))}
        </div>
      </section>
    </div>
  );
};

export default Teaservices;
