import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import React from "react";
import "./TeamServices.css";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

const TeaService = ({ tservice }) => {
  return (
    <div>
      <section className="container">
        <div className="tservice-div">
          <div className="mt-4">
            <img className="tservice-img img-fluid" src={tservice.img} alt="" />
            <p className="tservice-p1">{tservice.introduction}</p>
            <h6 className="tservice-h6">{tservice.name}</h6>
            <p className="tservice-p2">{tservice.designation}</p>
          </div>
          <div>
            <FontAwesomeIcon className="me-3 tservice-icon" icon={faLinkedin} />
            <FontAwesomeIcon className="tservice-icon2" icon={faEnvelope} />
          </div>
        </div>
      </section>
    </div>
  );
};

export default TeaService;
