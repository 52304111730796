import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faThumbsUp,
  faMessage,
  faShareNodes,
} from "@fortawesome/free-solid-svg-icons";
import React, { Component } from "react";
import Slider from "react-slick";
import rules1 from "../../../Images/Rules/rules-1.png";
import rules2 from "../../../Images/Rules/rules-2.png";
import rules3 from "../../../Images/Rules/rules-3.png";
import rules4 from "../../../Images/Rules/rules-4.png";
import rules5 from "../../../Images/Rules/rules-5.png";
import rules6 from "../../../Images/Rules/rules-6.png";
import rules7 from "../../../Images/Rules/rules-7.png";
import rules8 from "../../../Images/Rules/rules-8.png";
import "./Rules.css";

export default class Rules extends Component {
  render() {
    const settings = {
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      speed: 1000,
      autoplaySpeed: 2000,
      cssEase: "linear",
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <div className="rules-full-div container">
        {/* <Slider {...settings}>
          <div className="rules-card-div">
            <div className="rules-img">
              <img className="img fluid" src={rules1} alt="" />
            </div>
            <div className="rules-card-text">
              <h6 className="rules-h6">
                DEVELOPMENT{" "}
                <span className="span-date"> 23 September 2020</span>
              </h6>
              <h4 className="rules-h4">Software development</h4>
              <p className="rules-p">
                The process of custom software development begins with gathering
                requirements and defining the scope of the project.
              </p>
              <div className="rules-card-icon">
                <a href="#" alt="">
                  Read More
                </a>
                <div className="rules-icon">
                  <span>
                    <FontAwesomeIcon
                      icon={faThumbsUp}
                      style={{ marginRight: 10, color: "#169BFF" }}
                    />
                    <FontAwesomeIcon
                      icon={faMessage}
                      style={{ marginRight: 10, color: "#169BFF" }}
                    />
                    <FontAwesomeIcon
                      icon={faShareNodes}
                      style={{ marginRight: 10, color: "#169BFF" }}
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="rules-card-div">
            <div className="rules-img">
              <img className="img fluid" src={rules2} alt="" />
            </div>
            <div className="rules-card-text">
              <h6 className="rules-h6">
                DEVELOPMENT{" "}
                <span className="span-date"> 23 September 2020</span>
              </h6>
              <h4 className="rules-h4">8 Rules of Thumb in UI Design</h4>
              <p className="rules-p">
                A well-designed and developed website can help businesses
                increase their online visibility, reach more customers, and
                generate more revenue.
              </p>
            </div>
            <div className="rules-card-icon">
              <a href="#" alt="">
                Read More
              </a>
              <div className="rules-icon">
                <span>
                  <FontAwesomeIcon
                    icon={faThumbsUp}
                    style={{
                      marginRight: 10,
                      color: "#169BFF",
                    }}
                  />
                  <FontAwesomeIcon
                    icon={faMessage}
                    style={{
                      marginRight: 10,
                      color: "#169BFF",
                    }}
                  />
                  <FontAwesomeIcon
                    icon={faShareNodes}
                    style={{
                      marginRight: 10,
                      color: "#169BFF",
                    }}
                  />
                </span>
              </div>
            </div>
          </div>
          <div className="rules-card-div">
            <div className="rules-img">
              <img className="img fluid" src={rules3} alt="" />
            </div>
            <div className="rules-card-text">
              <h6 className="rules-h6">
                Solution <span className="span-date"> 23 September 2020</span>
              </h6>
              <h4 className="rules-h4">ERP Solution</h4>
              <p className="rules-p">
                An ERP system provides a centralized real-time view of an
                organization's information, enabling businesses to make
                data-driven decisions.
              </p>
            </div>
            <div className="rules-card-icon">
              <a href="#" alt="">
                Read More
              </a>
              <div className="rules-icon">
                <span>
                  <FontAwesomeIcon
                    icon={faThumbsUp}
                    style={{
                      marginRight: 10,
                      color: "#169BFF",
                    }}
                  />
                  <FontAwesomeIcon
                    icon={faMessage}
                    style={{
                      marginRight: 10,
                      color: "#169BFF",
                    }}
                  />
                  <FontAwesomeIcon
                    icon={faShareNodes}
                    style={{
                      marginRight: 10,
                      color: "#169BFF",
                    }}
                  />
                </span>
              </div>
            </div>
          </div>
          <div className="rules-card-div">
            <div className="rules-img">
              <img className="img fluid" src={rules4} alt="" />
            </div>
            <div className="rules-card-text">
              <h6 className="rules-h6">
                DEVELOPMENT{" "}
                <span className="span-date"> 23 September 2020</span>
              </h6>
              <h4 className="rules-h4">8 Rules of Thumb in UI Design</h4>
              <p className="rules-p">
                A well-designed and developed website can help businesses
                increase their online visibility, reach more customers, and
                generate more revenue.
              </p>
            </div>
            <div className="rules-card-icon">
              <a href="#" alt="">
                Read More
              </a>
              <div className="rules-icon">
                <span>
                  <FontAwesomeIcon
                    icon={faThumbsUp}
                    style={{
                      marginRight: 10,
                      color: "#169BFF",
                    }}
                  />
                  <FontAwesomeIcon
                    icon={faMessage}
                    style={{
                      marginRight: 10,
                      color: "#169BFF",
                    }}
                  />
                  <FontAwesomeIcon
                    icon={faShareNodes}
                    style={{
                      marginRight: 10,
                      color: "#169BFF",
                    }}
                  />
                </span>
              </div>
            </div>
          </div>
          <div className="rules-card-div">
            <div className="rules-img">
              <img className="img fluid" src={rules5} alt="" />
            </div>
            <div className="rules-card-text">
              <h6 className="rules-h6">
                DEVELOPMENT{" "}
                <span className="span-date"> 23 September 2020</span>
              </h6>
              <h4 className="rules-h4">Website & Webapp</h4>
              <p className="rules-p">
                A well-designed and developed website can help businesses
                increase their online visibility, reach more customers, and
                generate more revenue.
              </p>
            </div>
            <div className="rules-card-icon">
              <a href="#" alt="">
                Read More
              </a>
              <div className="rules-icon">
                <span>
                  <FontAwesomeIcon
                    icon={faThumbsUp}
                    style={{
                      marginRight: 10,
                      color: "#169BFF",
                    }}
                  />
                  <FontAwesomeIcon
                    icon={faMessage}
                    style={{
                      marginRight: 10,
                      color: "#169BFF",
                    }}
                  />
                  <FontAwesomeIcon
                    icon={faShareNodes}
                    style={{
                      marginRight: 10,
                      color: "#169BFF",
                    }}
                  />
                </span>
              </div>
            </div>
          </div>
          <div className="rules-card-div">
            <div className="rules-img">
              <img className="img fluid" src={rules6} alt="" />
            </div>
            <div className="rules-card-text">
              <h6 className="rules-h6">
                DEVELOPMENT{" "}
                <span className="span-date"> 23 September 2020</span>
              </h6>
              <h4 className="rules-h4">8 Rules of Thumb in UI Design</h4>
              <p className="rules-p">
                A well-designed and developed website can help businesses
                increase their online visibility, reach more customers, and
                generate more revenue.
              </p>
            </div>
            <div className="rules-card-icon">
              <a href="#" alt="">
                Read More
              </a>
              <div className="rules-icon">
                <span>
                  <FontAwesomeIcon
                    icon={faThumbsUp}
                    style={{
                      marginRight: 10,
                      color: "#169BFF",
                    }}
                  />
                  <FontAwesomeIcon
                    icon={faMessage}
                    style={{
                      marginRight: 10,
                      color: "#169BFF",
                    }}
                  />
                  <FontAwesomeIcon
                    icon={faShareNodes}
                    style={{
                      marginRight: 10,
                      color: "#169BFF",
                    }}
                  />
                </span>
              </div>
            </div>
          </div>
          <div className="rules-card-div">
            <div className="rules-img">
              <img className="img fluid" src={rules7} alt="" />
            </div>
            <div className="rules-card-text">
              <h6 className="rules-h6">
                DEVELOPMENT{" "}
                <span className="span-date"> 23 September 2020</span>
              </h6>
              <h4 className="rules-h4">Mobile application</h4>
              <p className="rules-p">
                A well-designed mobile app can provide businesses with a
                powerful tool to engage with customers, improve brand awareness.
              </p>
            </div>
            <div className="rules-card-icon">
              <a className="" href="#" alt="">
                Read More
              </a>
              <div className="rules-icon">
                <span>
                  <FontAwesomeIcon
                    icon={faThumbsUp}
                    style={{
                      marginRight: 10,
                      color: "#169BFF",
                    }}
                  />
                  <FontAwesomeIcon
                    icon={faMessage}
                    style={{
                      marginRight: 10,
                      color: "#169BFF",
                    }}
                  />
                  <FontAwesomeIcon
                    icon={faShareNodes}
                    style={{
                      marginRight: 10,
                      color: "#169BFF",
                    }}
                  />
                </span>
              </div>
            </div>
          </div>
          <div className="rules-card-div">
            <div className="rules-img">
              <img className="img fluid" src={rules8} alt="" />
            </div>
            <div className="rules-card-text">
              <h6 className="rules-h6">
                DEVELOPMENT{" "}
                <span className="span-date"> 23 September 2020</span>
              </h6>
              <h4 className="rules-h4">8 Rules of Thumb in UI Design</h4>
              <p className="rules-p">
                A well-designed and developed website can help businesses
                increase their online visibility, reach more customers, and
                generate more revenue.
              </p>
            </div>
            <div className="rules-card-icon">
              <a href="#" alt="">
                Read More
              </a>
              <div className="rules-icon">
                <span>
                  <FontAwesomeIcon
                    icon={faThumbsUp}
                    style={{
                      marginRight: 10,
                      color: "#169BFF",
                    }}
                  />
                  <FontAwesomeIcon
                    icon={faMessage}
                    style={{
                      marginRight: 10,
                      color: "#169BFF",
                    }}
                  />
                  <FontAwesomeIcon
                    icon={faShareNodes}
                    style={{
                      marginRight: 10,
                      color: "#169BFF",
                    }}
                  />
                </span>
              </div>
            </div>
          </div>
        </Slider> */}
      </div>
    );
  }
}
