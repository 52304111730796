import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, Component } from "react";
import ptvl1 from "../../../Images/ptvl-logo-PNG.png";
import appStoreImg from "../../../Images/app-store.png";
import googleStoreImg from "../../../Images/google-play.png";
import "./Footer.css";
import {
  faBuilding,
  faEnvelope,
  faForward,
  faLocation,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import footerImg1 from "../../../Images/Footer/footer-f.png";
import footerImg2 from "../../../Images/Footer/footer-t.png";
import footerImg3 from "../../../Images/Footer/footerL.png";
import footerImg4 from "../../../Images/Footer/footer-i.png";
import FooterLast from "./FooterLast";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const footerBg = {
  backgroundColor: "#1E397A",
  marginBottom: "-60px",
  backgroundRepeat: "no-repeat",
  position: "relative",
  backgroundSize: "cover",
  overflowX: "hiden",
};

const Footer = () => {
  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  return (
    <div id="footer-id">
      <div style={footerBg} className=" container-fluid footer-full-div">
        <div className="row footer-div">
          <div className="col-4">
            <div>
              <img className="footer-img" src={ptvl1} alt="" />
            </div>{" "}
            <br /> <br />
            <p className="footer-p1">
              With a mission to deliver Excellence, through Innovation in
              designing solutions, implementing those in an innovative way using
              the right technologies. Pakiza Technovation Limited is the newest
              concern of renowned Pakiza Group, the standard of excellence in
              all its business sectors. Pakiza Technovation Limited always love
              to optimize, not to be lowest price, its solution to meet its
              client’s requirement & thus ensure value for money.
            </p>
          </div>
          <div className="col-4 address-footer">
            <h5>Address</h5>
            <div className="">
              <div className="d-flex align-items-center">
                <FontAwesomeIcon
                  icon={faLocation}
                  style={{
                    width: 20,
                    marginRight: 15,
                    height: 17,
                    border: "1px solid white",
                    borderRadius: "100%",
                    padding: "5px",
                  }}
                />
                <p className="">
                  {" "}
                  Head office : House# 97, Road# 11/A,
                  <br /> Dhanmondi, Dhaka-1209, Bangladesh
                </p>
              </div>
              <div className="d-flex align-items-center">
                <FontAwesomeIcon
                  icon={faPhone}
                  style={{
                    width: 20,
                    marginRight: 15,
                    height: 17,
                    border: "1px solid white",
                    borderRadius: "100%",
                    padding: "5px",
                    marginBottom: 10,
                  }}
                />
                <p className=""> +880 9612 811 111</p>
              </div>
              <div className="d-flex align-items-center">
                <FontAwesomeIcon
                  icon={faEnvelope}
                  style={{
                    width: 20,
                    marginRight: 15,
                    height: 17,
                    border: "1px solid white",
                    borderRadius: "100%",
                    padding: "5px",
                  }}
                />
                <p className="text-white">
                  {" "}
                  <a className="text-white" href="mailto:info@pakizatvl.com">
                    {" "}
                    info@pakizatvl.com
                  </a>{" "}
                </p>
              </div>
              <div className="d-flex align-items-center">
                <FontAwesomeIcon
                  icon={faBuilding}
                  style={{
                    width: 28,
                    marginRight: 15,
                    height: 17,
                    border: "1px solid white",
                    borderRadius: "100%",
                    padding: "6px",
                  }}
                />
                <p className="">
                  {" "}
                  Registered office: Khordo Nowpara,Rasulpur Narsingdi (Sadar),
                  Narsingdi-1600, Bangladesh
                </p>
              </div>
            </div>
          </div>

          <div className="col-4 connect-footer-div ">
            <div className="ms-5">
              <h6 className="connect-footer">Get Connected</h6>
              <div>
                <a href="https://www.facebook.com/pakizatvl/" target="_blank">
                  {" "}
                  <img src={footerImg1} alt="facebook" />{" "}
                </a>
                <a href="https://twitter.com/pakizatvl/" target="_blank">
                  <img src={footerImg2} alt="twitter" />
                </a>
                <a
                  href="https://www.linkedin.com/company/pakiza-technovation-limited/"
                  target="_blank"
                >
                  <img src={footerImg3} alt="linkedin" />
                </a>
                <a
                  href="https://www.youtube.com/channel/UCpS2CdU5-N1gayjbjKSpvEg/"
                  target="_blank"
                >
                  {" "}
                  <img src={footerImg4} alt="youtube" />
                </a>
              </div>
            </div>
            <div className="ms-5 mt-3">
              <h6 className="privacy-tag-h1">App privacy and policy</h6>
              <div className="">
                <Link to="/app_privacy_Policies">
                  <img className="footer-store-imgs" src={appStoreImg} alt="" />
                </Link>
                <Link to="/google_privacy_Policies">
                  <img
                    className="footer-store-imgs"
                    src={googleStoreImg}
                    alt=""
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-mobile-div">
          <div className="me-5">
            <h4 className="mobile-text-h4">Get Connected</h4>
            <div className="mobile-imgs">
              <a href="https://www.facebook.com/pakizatvl/" target="_blank">
                {" "}
                <img src={footerImg1} alt="facebook" />{" "}
              </a>
              <a href="https://twitter.com/pakizatvl/" target="_blank">
                <img src={footerImg2} alt="twitter" />
              </a>
              <a
                href="https://www.linkedin.com/company/pakiza-technovation-limited/"
                target="_blank"
              >
                <img src={footerImg3} alt="linkedin" />
              </a>
              <a
                href="https://www.youtube.com/channel/UCpS2CdU5-N1gayjbjKSpvEg/"
                target="_blank"
              >
                {" "}
                <img src={footerImg4} alt="youtube" />
              </a>
            </div>
          </div>

          <div className="footer-mobile-img me-5">
            <img src={ptvl1} alt="" />
          </div>
          <div className="mobile-p-a me-4">
            <p className="footer-mobile-p mb-4">
              With a mission to deliver Excellence, through Innovation in
              designing solutions, implementing those in an innovative way using
              the right technologies. Pakiza Technovation Limited is the newest
              concern of renowned Pakiza Group, the standard of excellence in
              all its business sectors. Pakiza Technovation Limited always love
              to optimize, not to be lowest price, its solution to meet its
              client’s requirement & thus ensure value for money.
            </p>
            <div className=" mt-3">
              <h6 className="privacy-tag-h1">App privacy and policy</h6>
              <div className="">
                <Link to="/app_privacy_Policies">
                  <img className="footer-store-imgs" src={appStoreImg} alt="" />
                </Link>
                <Link to="/google_privacy_Policies">
                  <img
                    className="footer-store-imgs"
                    src={googleStoreImg}
                    alt=""
                  />
                </Link>
              </div>
            </div>
          </div>
          <div className="mobile-view-address me-4">
            <h5 className="mobile-address">Address</h5>

            <div className="d-flex align-items-center mb-3">
              <FontAwesomeIcon
                icon={faLocation}
                style={{
                  width: 20,
                  marginRight: 15,
                  marginBottom: "8px",
                  height: 17,
                  border: "1px solid white",
                  borderRadius: "100%",
                  padding: "4px",
                }}
              />
              <p className="">
                {" "}
                Head office : House# 97, Road# 11/A,
                <br /> Dhanmondi, Dhaka-1209, Bangladesh
              </p>
            </div>
            <div className="d-flex align-items-center mb-3">
              <FontAwesomeIcon
                icon={faPhone}
                style={{
                  width: 20,
                  marginRight: 15,
                  marginBottom: "8px",
                  height: 17,
                  border: "1px solid white",
                  borderRadius: "100%",
                  padding: "4px",
                }}
              />
              <p className=""> +880 9612 811 111</p>
            </div>
            <div className="d-flex align-items-center mb-3">
              <FontAwesomeIcon
                icon={faEnvelope}
                style={{
                  width: 20,
                  marginRight: 15,
                  marginBottom: "8px",
                  height: 17,
                  border: "1px solid white",
                  borderRadius: "100%",
                  padding: "4px",
                }}
              />
              <p className="">
                {" "}
                <a href="mailto:info@pakizatvl.com" style={{ color: "white" }}>
                  {" "}
                  info@pakizatvl.com
                </a>{" "}
              </p>
            </div>
            <div className="d-flex align-items-center mb-3">
              <FontAwesomeIcon
                icon={faBuilding}
                style={{
                  width: 27,
                  marginRight: 15,
                  marginBottom: "8px",
                  height: 17,
                  border: "1px solid white",
                  borderRadius: "100%",
                  padding: "5px",
                }}
              />
              <p className="">
                {" "}
                Registered office: Khordo Nowpara,Rasulpur Narsingdi (Sadar),
                Narsingdi-1600, Bangladesh
              </p>
            </div>

            {/* <div className="ms-5">
              <h4 className="newsletter2">Join a Newsletter</h4>
              <div className="d-flex align-items-center mobile-input-field">
                <form>
                  <input
                    className="input-footer2"
                    type="email"
                    id="email"
                    placeholder="Input Your Email"
                    name="email"
                  />
                </form>
                <button className="footer-input-button2">
                  <FontAwesomeIcon icon={faForward} />
                </button>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <div className="lastfooter">
        <FooterLast />
      </div>
    </div>
  );
};

export default Footer;
