import React, { useState, useEffect, useRef } from 'react';
import { Link, Navigate, useNavigate, Routes, Route, useParams } from "react-router-dom";
import axios from 'axios';
import Swal from 'sweetalert2';
import MasterDashboardLayout from '../../AdminPanel/MasterDashboardLayout'


function EditOurTeam() {
    const navigate = useNavigate();
    let { id } = useParams();

    const handleInputChange = (e) => {
        setEditOurTeamData({
            ...EditOurTeamData, [e.target.name]: e.target.value
        })
    }

    const [image, setImage] = useState('');
    console.log('image info', image)
    const [picture, setPicture] = useState('');

    const onChangePicture = e => {
        console.log('picture: ', picture);
        setPicture(URL.createObjectURL(e.target.files[0]));
        setImage(e.target.files[0]);
    };


    const handleUpdate = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('name', EditOurTeamData.name);
        formData.append('designation', EditOurTeamData.designation);
        formData.append('linkedin_link', EditOurTeamData.linkedin_link);
        formData.append('email_link', EditOurTeamData.email_link);

        formData.append('image', image);


        console.log('check all data', formData);



        axios.post(`/api/update-our-team/${id}`, formData, {
            headers: { "Content-Type": "multipart/form-data" }
        }).then(res => {
            if (res.data.status == 200) {
                Swal.fire(res.data.message, '', 'success')
                navigate('/view-our-team')
                setEditOurTeamData({
                    client_company_name: '',
                    client_message: '',
                    client_name: '',
                    rate: '',
                    testimonial_short_bio: "",
                    client_designation: ""
                });

                setImage('');
                setPicture('');
                document.getElementById('image').value = "";
            }
            // else if (res.data.status == 400) {
            //     setjobDesc({ ...jobDesc, error_list: res.data.errors });
            //     Swal.fire(jobDesc.error_list.job_id[0], '', 'error')

            // }
        })

    }
    const [EditOurTeamData, setEditOurTeamData] = useState('');
    useEffect(() => {
        axios.get(`/api/edit-our-team/${id}`).then(res => {
            if (res.data.status == 200) {
                setEditOurTeamData(res.data.team);
                // setLoading(false);

            }
        })
    }, [])
    return (
        <MasterDashboardLayout>
            <div class="col-12 grid-margin stretch-card">
                <div class="card">
                <div class="card-body">
                        <div className='d-flex justify-content-between my-2'>
                            <h4 class="card-title">Edit Our Team</h4>
                            <Link to="/view-testimonial"><button type='button' className='btn btn-warning btn-sm fw-bold rounded-pill'>View</button></Link>
                        </div>

                        <form class="forms-sample" onSubmit={handleUpdate} >
       
                            <div class="form-group">
                                <label for="exampleInputName1">Name</label>
                                <input type="text" class="form-control" id="exampleInputName1" placeholder="Name" onChange={handleInputChange} name="name" value={EditOurTeamData.name} />
                            </div>
                            <div class="form-group">
                                <label for="exampleInputName1">Designation</label>
                                <input type="text" class="form-control" id="exampleInputName1" placeholder="Designation" onChange={handleInputChange} name="designation" value={EditOurTeamData.designation} />
                            </div>
                            <div class="form-group">
                                <label for="exampleInputName1">Linkedin Link</label>
                                <input type="text" class="form-control" id="exampleInputName1" placeholder="Name" onChange={handleInputChange} name="linkedin_link" value={EditOurTeamData.linkedin_link} />
                            </div>
                            <div class="form-group">
                                <label for="exampleInputName1">Email Link</label>
                                <input type="text" class="form-control" id="exampleInputName1" placeholder="Name" onChange={handleInputChange} name="email_link" value={EditOurTeamData.email_link} />
                            </div>

                            <div class="form-group">
                                <label>Image</label>
                                <div class="input-group col-xs-12">
                                    <input type="file" class="form-control file-upload-info" placeholder="Upload Image" id="image" name="image" onChange={onChangePicture} />
                                    {/* <span class="input-group-append">
                                        <button class="file-upload-browse btn btn-primary" type="button">Upload</button>
                                    </span> */}
                                </div>
                            </div>
                            <div className="form-group " style={{ width: '100px', height: '90px' }}>
                                <img className="playerProfilePic_home_tile" src={`${global.img_url}/images/${EditOurTeamData.image}`} style={{ width: '100px', height: '90px' }}></img>
                            </div>


                            <button type="submit" class="btn btn-primary mr-2" onSubmit={handleUpdate}>Update</button>
                        </form>
                    </div>
                </div>
            </div>
        </MasterDashboardLayout>
    )
}

export default EditOurTeam