import Lottie from "lottie-react";
import React, { useEffect } from "react";
import Footer from "../../Shared/Footer/Footer";
import NavigationBar from "../../Shared/NavigationBar/NavigationBar";
import surveillanceLottie from "./Surveillance.json";
import "./SecuritySarvillance.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faBookOpen,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import "../DataCenter/DataCenter.css";
import securitybg from "../../../Images/Product & Services/Surveillance/security.jpg";
import securityIcon from "../../../Images/Product & Services/Surveillance/securityIcon.png";
import hikvision from "../../../Images/Product & Services/Surveillance/hikvision.webp";
import zkteco from "../../../Images/Product & Services/Surveillance/zkteco.webp";
import garrett from "../../../Images/Product & Services/Surveillance/garrett.jpg";
import suprema from "../../../Images/Product & Services/Surveillance/suprema.png";
import pasys from "../../../Images/Product & Services/Surveillance/pasys.jpg";
import ScrollTopButton from "../../ScrollTopButton/ScrollTopButton";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const SecuritySarvillance = () => {
  // Button
  const handleClickCustom = () => {
    window.location.href = "tel:+8801713060441";
  };

  // MUI
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Reload 0
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="main_product_page software_solution">
      <section>
        <NavigationBar />
      </section>

      <div className="data-center-wrapper">
        <img className="img img-fluid bg_img" src={securitybg} />

        <div className="data-mobile-texts-div ">
          <div className="data-mobile-texts">
            <div className="d-flex align-items-center text-center">
              <img
                className="img img-fluid"
                src={securityIcon}
                alt="software"
              />
              <h5 className="data-head-tag ms-2">Security and Surveillance</h5>
            </div>

            <p className="data-p2">
              Software is an essential component of modern technology, and
              software solutions can provide effective solutions to a wide range
              of problems. By automating tasks, improving collaboration, and
              providing valuable insights, software can help businesses and
              individuals become more productive and efficient. As technology
              continues to evolve, we can expect to see even more innovative
              software solutions that will help us work smarter and achieve our
              goals more effectively. Software solutions is their ability to
              automate repetitive tasks, allowing users to focus on more
              valuable work. For example, a marketing team can use marketing
              automation software to streamline their campaigns and reduce the
              time and effort needed to carry out various tasks. Similarly, a
              customer service team can use a ticketing system to manage
              customer requests more efficiently, reducing the workload on
              individual agents.
            </p>
          </div>
        </div>
      </div>

      <div className="container offer-header">
        <h4 className="data-fe-icons  ">Security and Surveillance we offer</h4>
      </div>

      {/* <!-- Solution we cover-----------------> */}

      <div class="container">
        <div className="hikvision">
          <div className="row ">
            <h3>Hikvision</h3>
            <div className="col-3 p-3  align-items-center justify-content-center">
              <img className="img img-fluid" src={hikvision} alt="" />
              {/* <a className="btn btn-block btn-sm btn-video" href="#">
                  {""}<i className="fa fa-video"></i> See video</a> */}
            </div>
            <div className="col-9">
              {" "}
              <p>
                We are providing the end to end IP CCTV Solution for Medium and
                Large Enterprise Clients.{" "}
              </p>
              <ul>
                <li>NVR or Server bases IP CCTV Solution.</li>
                <li> Thermal Camera Solution.</li>
                <li> Face Detection Access Contro</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="zkteco">
          <div className="row ">
            <h3>ZTKECO</h3>
            <div className="col-3 p-3 align-items-center justify-content-center">
              <img className="img img-fluid" src={zkteco} alt="" />
              {/* <a className="btn btn-block btn-sm btn-video" href="#">
                  {""}<i className="fa fa-video"></i> See video</a> */}
            </div>
            <div className="col-9">
              {" "}
              <p>
                ZKTeco Co., Ltd is a globally renowned enterprise with biometric
                verifications as its core technique.
              </p>
              <ul>
                <li> Biometrics Attendance system</li>
                <li> Face Detection Access Control and Attendance system</li>
                <li> Archway Gate and metal Detector</li>
                <li> Gate Barrier System</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="garrett">
          <div className="row ">
            <h3>GARRETT</h3>
            <div className="col-3 p-3 align-items-center justify-content-center">
              <img className="img img-fluid" src={garrett} alt="" />
              {/* <a className="btn btn-block btn-sm btn-video" href="#">
                  {""}<i className="fa fa-video"></i> See video</a> */}
            </div>
            <div className="col-9">
              <p>
                The Garrett is an USA based Company. The company has set goals
                to empower their customers with better products, better
                technology and better information.
              </p>
              <ul>
                <li>Archway Gate and metal Detector</li>
                <li>Gate Barrier System</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="suprema">
          <div className="row ">
            <h3>SUPREMA</h3>
            <div className="col-3 p-3align-items-center justify-content-center">
              <img className="img img-fluid" src={suprema} alt="" />
              {/* <a className="btn btn-block btn-sm btn-video" href="#">
                  {""}<i className="fa fa-video"></i> See video</a> */}
            </div>
            <div className="col-9">
              <p>
                {" "}
                Suprema is a leading global provider of access control, time &
                attendance, and biometrics solutions.
              </p>{" "}
              <ul>
                <li> Biometrics Attendance system</li>
                <li> Face Detection Access Control and Attendance system</li>
              </ul>
            </div>
          </div>
        </div>
        <br />
        <div className="pa">
          <div className="row ">
            <h3>PA System </h3>
            <div className="col-3 p-3 align-items-center justify-content-center">
              <img className="img img-fluid" src={pasys} alt="" />
              {/* <a className="btn btn-block btn-sm btn-video" href="#">
                  {""}<i className="fa fa-video"></i> See video</a> */}
            </div>
            <div className="col-9">
              <p>
                {" "}
                PA system are announcements of movements at large area with many
                speakers are widely used to make announcements in public,
                institutional and commercial buildings and locations.
              </p>
            </div>
          </div>
        </div>
      </div>

      <hr className="half-hr" />

      <section>
        <Footer />
      </section>
      <ScrollTopButton />
    </div>
  );
};

export default SecuritySarvillance;
