import React, { useState, useEffect, useRef } from 'react';
import { Link, Navigate, useNavigate, Routes, Route } from "react-router-dom";
import axios from 'axios';
import Swal from 'sweetalert2';
import MasterDashboardLayout from '../../AdminPanel/MasterDashboardLayout'


function CreateContactVisit() {
    const navigate = useNavigate();

    const [contactVisit, setcontactVisit] = useState({
        office_address: "",
        office_email: "",
        office_phone: '',
        phone_no: '',


    })

    const handleInputChange = (e) => {
        setcontactVisit({
            ...contactVisit, [e.target.name]: e.target.value
        })
    }



    const handleSubmit = (e) => {
        e.preventDefault();



        axios.post(`/api/save-contact-visit`, contactVisit).then(res => {
            if (res.data.status == 200) {
                Swal.fire(res.data.message, '', 'success')
                navigate('/view-contact-visit')
                setcontactVisit({
                    office_address: "",
                    office_email: "",
                    office_phone: '',
                    phone_no: '',


                })


            }
            // else if (res.data.status == 400) {
            //     setjobDesc({ ...jobDesc, error_list: res.data.errors });
            //     Swal.fire(jobDesc.error_list.job_id[0], '', 'error')

            // }
        })

    }
    return (
        <MasterDashboardLayout>
            <div class="col-12 grid-margin stretch-card">
                <div class="card">
                    <div class="card-body">
                        <div className='d-flex justify-content-between my-2'>
                            <h4 class="card-title">Add Contact Visit</h4>
                            <Link to="/view-contact-visit"><button type='button' className='btn btn-warning btn-sm fw-bold rounded-pill'>View</button></Link>
                        </div>

                        <form class="forms-sample" onSubmit={handleSubmit} >

                            <div class="form-group">
                                <label for="exampleInputName1">Office Address</label>
                                <input type="text" class="form-control" id="exampleInputName1" placeholder="Office address" onChange={handleInputChange} name="office_address" value={contactVisit.office_address} />
                            </div>
                            <div class="form-group">
                                <label for="exampleInputName1">Phone  no</label>
                                <input type="text" class="form-control" id="exampleInputName1" placeholder="Phone no" onChange={handleInputChange} name="phone_no" value={contactVisit.phone_no} />
                            </div>
                            <div class="form-group">
                                <label for="exampleInputName1">Office Phone no</label>
                                <input type="text" class="form-control" id="exampleInputName1" placeholder="Office phone no" onChange={handleInputChange} name="office_phone" value={contactVisit.office_phone} />
                            </div>

                            <div class="form-group">
                                <label for="exampleInputName1">Office Email</label>
                                <input type="text" class="form-control" id="exampleInputName1" placeholder="Office Email" onChange={handleInputChange} name="office_email" value={contactVisit.office_email} />
                            </div>



                            <button type="submit" class="btn btn-primary mr-2" onSubmit={handleSubmit}>Submit</button>
                        </form>
                    </div>
                </div>
            </div>
        </MasterDashboardLayout>
    )
}

export default CreateContactVisit