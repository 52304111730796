import React from "react";
import companyLogo from "../../../Images/PTVL.png";
import companyLogo1 from "../../../Images/ptvl-logo-PNG.png";
import "./NavigationBar.css";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBarsStaggered,
  faArrowDown,
} from "@fortawesome/free-solid-svg-icons";

const NavigationBar = () => {
  const [navbarColor, setNavbarColor] = useState(null);
  const [navbarTextColor, setNavbarTextColor] = useState(null);
  const [dropdownColor, setDropdownColor] = useState(null);
  const [buttonColor, setButtonColor] = useState(null);
  const [logoColor, setLogoColor] = useState("black");
  const location = useLocation();
  const [logoUrl, setLogoUrl] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isSubOpen, setIsSubOpen] = useState(false);

  useEffect(() => {
    if (location.pathname === "/") {
      setLogoUrl(companyLogo);
    } else if (location.pathname === "/aboutUs") {
      setLogoUrl(companyLogo1);
    } else {
      setLogoUrl(companyLogo);
    }
  }, [location]);

  useEffect(() => {
    switch (location.pathname) {
      case "/":
        setNavbarColor("white");
        setNavbarTextColor("#404040");
        setDropdownColor("#404040");
        setButtonColor("#1e397a");
        break;
      case "/home":
        setNavbarColor("white");
        setNavbarTextColor("#404040");
        setDropdownColor("#404040");
        setButtonColor("#1e397a");
        break;
      case "/aboutUs":
        setNavbarColor("#132362");
        setNavbarTextColor("White");
        setDropdownColor("White");
        setButtonColor("#FF8E25");
        break;
      case "/productService":
        setNavbarColor("White");
        setNavbarTextColor("#404040");
        setDropdownColor("#404040");
        setButtonColor("#1e397a");
        break;
      case "/softwareSolution":
        setNavbarColor("White");
        setNavbarTextColor("#404040");
        setDropdownColor("#404040");
        setButtonColor("#1e397a");
        break;
      case "/criptonVatPro":
        setNavbarColor("White");
        setNavbarTextColor("#404040");
        setDropdownColor("#404040");
        setButtonColor("#1e397a");
        break;
      case "/hardwaresolution":
        setNavbarColor("White");
        setNavbarTextColor("#404040");
        setDropdownColor("#404040");
        setButtonColor("#1e397a");
        break;
      case "/dataCenter":
        setNavbarColor("White");
        setNavbarTextColor("#404040");
        setDropdownColor("#404040");
        setButtonColor("#1e397a");
        break;
      case "/securitySarvillance":
        setNavbarColor("White");
        setNavbarTextColor("#404040");
        setDropdownColor("#404040");
        setButtonColor("#1e397a");
        break;
      case "/manangedServices":
        setNavbarColor("White");
        setNavbarTextColor("#404040");
        setDropdownColor("#404040");
        setButtonColor("#1e397a");
        break;
      case "/customizeSolution":
        setNavbarColor("White");
        setNavbarTextColor("#404040");
        setDropdownColor("#404040");
        setButtonColor("#1e397a");
        break;
      case "/team":
        setNavbarColor("White");
        setNavbarTextColor("#404040");
        setDropdownColor("#404040");
        setButtonColor("#1e397a");
        break;
      case "/howWork":
        setNavbarColor("White");
        setNavbarTextColor("#404040");
        setDropdownColor("#404040");
        setButtonColor("#1e397a");
        break;
      case "/contactUs":
        setNavbarColor("white");
        setNavbarTextColor("#404040");
        setDropdownColor("#404040");
        setButtonColor("#1e397a");
        break;
      case "/blogs":
        setNavbarColor("white");
        setNavbarTextColor("#404040");
        setDropdownColor("#404040");
        setButtonColor("#1e397a");
        break;
      case "/app_privacy_Policies":
        setNavbarColor("white");
        setNavbarTextColor("#404040");
        setDropdownColor("#404040");
        setButtonColor("#1e397a");
        break;
      case "/google_privacy_Policies":
        setNavbarColor("white");
        setNavbarTextColor("#404040");
        setDropdownColor("#404040");
        setButtonColor("#1e397a");
        break;
      case "/RequestQuotation":
        setNavbarColor("white");
        setNavbarTextColor("#404040");
        setDropdownColor("#404040");
        setButtonColor("#1e397a");
        break;
      case "/RequestDemo":
        setNavbarColor("white");
        setNavbarTextColor("#404040");
        setDropdownColor("#404040");
        setButtonColor("#1e397a");
        break;
    }
  }, [location.pathname]);
  return (
    <nav
      className="navbar navbar-expand-lg  navbar-light  fixed-top  shadow-sm"
      id="navBar"
      style={{ backgroundColor: navbarColor }}
    >
      <div className="container-fluid nav-div">
        <Link to="/home">
          <img
            style={{ color: logoColor }}
            className="nav-img"
            src={logoUrl}
            alt=""
          />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarTogglerDemo02"
          aria-controls="navbarTogglerDemo02"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="">
            <FontAwesomeIcon icon={faBarsStaggered} />
          </span>
        </button>
        <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <Link
                style={{ color: navbarTextColor }}
                activeStyle={{ borderBottom: "gray" }}
                to="/home"
                className={location.pathname === "/home" ? "active py-1" : ""}
              >
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link
                style={{ color: navbarTextColor }}
                to="/aboutUs"
                className={
                  location.pathname === "/aboutUs" ? "activeAbout py-1" : ""
                }
              >
                About Us
              </Link>
            </li>

            <li className="nav-item dropdown">
              <a
                style={{ color: dropdownColor }}
                className="dropdown-toggle"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Products & Services
              </a>
              <ul className="dropdown-menu">
                <li>
                  <Link class="dropdown-item" to="/softwareSolution">
                    Software Solution
                  </Link>
                </li>
                <div class="dropdown-divider"></div>
                <li>
                  <Link class="dropdown-item" to="/hardwaresolution">
                    Hardware Solution
                  </Link>
                </li>
                <div class="dropdown-divider"></div>

                <li>
                  <Link class="dropdown-item" to="/manangedServices">
                    Managed Service
                  </Link>
                </li>
                <div class="dropdown-divider"></div>

                <li>
                  <Link class="dropdown-item" to="/dataCenter">
                    Data Center Solution
                  </Link>
                </li>

                <div class="dropdown-divider"></div>

                <li>
                  <Link class="dropdown-item" to="/securitySarvillance">
                    Security & Surveillance
                  </Link>
                </li>

                {/* <li>
                      <Link
                        to="/softwareSolution"
                        className={
                          location.pathname === "/softwareSolution"
                            ? "active"
                            : ""
                        }
                      >
                        Software Solution
                      </Link>
                    </li> */}

                {/* <li
                      onClick={() => setIsOpen(!isOpen)}
                      className="dropdown-toggle"
                    >
                      Software Solutions
                    </li>
                    {isOpen && (
                      <div className="mega-menu">
                        <div className="mega-menu-item">
                          <Link to="/solutions/enterprise">Enterprise</Link>
                          <Link onClick={() => setIsSubOpen(!isSubOpen)}>
                            <FontAwesomeIcon icon={faArrowDown} />
                          </Link>
                          {isSubOpen && (
                            <ul className="sub-dropdown">
                              <li>
                                <Link to="/solutions/enterprise/feature-1">
                                  Feature 1
                                </Link>
                              </li>
                              <li>
                                <Link to="/solutions/enterprise/feature-2">
                                  Feature 2
                                </Link>
                              </li>
                              <li>
                                <Link to="/solutions/enterprise/feature-3">
                                  Feature 3
                                </Link>
                              </li>
                            </ul>
                          )}
                        </div>
                        <div className="mega-menu-item">
                          <Link to="/solutions/small-business">
                            Small Business
                          </Link>
                        </div>
                      </div>
                    )} */}

                {/* <li>
                  <Link
                    to="/hardwaresolution"
                    className={
                      location.pathname === "/hardwaresolution"
                        ? "active"
                        : ""
                    }
                  >
                    Hardware solution
                  </Link>
                </li>
                <li>
                  <Link
                    to="/manangedServices"
                    className={
                      location.pathname === "/manangedServices"
                        ? "active"
                        : ""
                    }
                  >
                    Managed Service
                  </Link>
                </li>
                <li>
                  <Link
                    to="/dataCenter"
                    className={
                      location.pathname === "/dataCenter" ? "active" : ""
                    }
                  >
                    Data Center Solution
                  </Link>
                </li>
                <li>
                  <Link
                    to="/securitySarvillance"
                    className={
                      location.pathname === "/securitySarvillance"
                        ? "active"
                        : ""
                    }
                  >
                    Security & Surveillance
                  </Link>
                </li> */}
              </ul>
            </li>
            {/* <li className="nav-item">
                  <Link
                    style={{ color: navbarTextColor }}
                    to="/"
                    className={
                      location.pathname === "/" ? "active py-4" : ""
                    }
                  >
                    Team
                  </Link>
                </li> */}
            <li className="nav-item">
              <Link
                style={{ color: navbarTextColor }}
                to="/howWork"
                className={
                  location.pathname === "/howWork" ? "active py-1" : ""
                }
              >
                How We Work
              </Link>
            </li>
            <li className="nav-item">
              <a>
                <Link
                  style={{ color: navbarTextColor }}
                  to="/contactUs"
                  className={
                    location.pathname === "/contactUs" ? "active py-1" : ""
                  }
                >
                  Contact Us
                </Link>
              </a>
            </li>
            <li className="nav-item dropdown">
              <a
                style={{ color: dropdownColor }}
                className="dropdown-toggle"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Download
              </a>
              <ul className="dropdown-menu">
                <li>
                  <a
                    class="dropdown-item"
                    href="https://drive.google.com/file/d/1iyF5T-iFsKrfQ19wqhF2CXEtomG_n5Rn/ "
                    target="_blank"
                  >
                    Company profile
                  </a>
                </li>{" "}
                <div class="dropdown-divider"></div>
                <li>
                  <a
                    class="dropdown-item"
                    href="https://drive.google.com/file/d/1Xnpkb8lZLZ_WrPeqEKyvIF8nJN5uSB3l/"
                    target="_blank"
                  >
                    Quality policy
                  </a>
                </li>
                <div class="dropdown-divider"></div>
                <li>
                  <a
                    class="dropdown-item"
                    href="https://drive.google.com/file/d/1Wc4K-Aa3Q-2SUAD4fNvQXuVmbRlZXsil/view?usp=sharing"
                    target="_blank"
                  >
                    Trade License
                  </a>
                </li>
              </ul>
            </li>

            <form className="d-flex nav-button">
              <button
                style={{ backgroundColor: buttonColor }}
                className="btn btn-sm"
                type="submit"
              >
                <Link className="button-que" to="/RequestQuotation">
                  Request for Quotation
                </Link>
              </button>
              <div className="gap"></div>
              <button className="btn btn-sm demo-btn" type="submit">
                <Link className="button-demo" to="/RequestDemo">
                  Get Demo
                </Link>
              </button>
            </form>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default NavigationBar;
