import React, { useEffect } from "react";
import Footer from "../../Shared/Footer/Footer";
import NavigationBar from "../../Shared/NavigationBar/NavigationBar";
import AboutBanner from "../AboutBanner/AboutBanner";
import CommunicationInfo from "../CommunicationInfor/CommunicationInfo";
import HardwareResource from "../HardwareResource/HardwareResource";
import InformationTechnology from "../InformationTechnology/InformationTechnology";
import ScrollTopButton from "../../ScrollTopButton/ScrollTopButton";
import { Helmet } from "react-helmet";

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Helmet>
        <title>About Pakiza Technovation Limited</title>
        <meta name="description" content="Get information about PTVL "></meta>
      </Helmet>
      <NavigationBar />
      <AboutBanner />
      <HardwareResource />
      <InformationTechnology />
      <CommunicationInfo />
      <Footer />
      <ScrollTopButton />
    </div>
  );
};

export default AboutUs;
