import React, { useState, useEffect, Component } from "react";
import Footer from "../../Shared/Footer/Footer";
import NavigationBar from "../../Shared/NavigationBar/NavigationBar";
import PropTypes from "prop-types";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import ssp from "../../../Images/Product & Services/Software/s_s_p.png";
import ba from "../../../Images/Product & Services/Software/ba.png";
import softwarebg from "../../../Images/Product & Services/Software/softwarebg.jpg";
import cvp_new from "../../../Images/Product & Services/Software/cvp_new.png";
import ac from "../../../Images/Product & Services/Software/ac.png";

import softTabimg6 from "../../../Images/Product & Services/Software/cripton.png";

import dataBG from "../../../Images/Product & Services/DataS/dataBannerBg.png";
import softwareIcon from "../../../Images/Product & Services/Software/software.png";
import "../DataCenter/DataCenter.css";
import { faFileArrowDown, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Slider from "react-slick";
import manageimg1 from "../../../Images/Product & Services/ManagedS/service.png";
import managedServiceIcon from "../../../Images/Product & Services/ManagedS/managedServiceIcon.png";
import managedbg from "../../../Images/Product & Services/ManagedS/managedbg.png";

import manageditservice from "../../../Images/Product & Services/ManagedS/manageditservice.jpg";
import helpdesk from "../../../Images/Product & Services/ManagedS/helpdesk.png";
import manageimg2 from "../../../Images/Product & Services/ManagedS/Hiring-Decisions.jpg";
import manageimg3 from "../../../Images/Product & Services/ManagedS/service2.png";
import manageimg4 from "../../../Images/Product & Services/ManagedS/helpDesk.jpg";
import manageimg5 from "../../../Images/Product & Services/ManagedS/informationTechnology.jpg";
import manageimg6 from "../../../Images/Product & Services/ManagedS/res.jpg";
import ScrollTopButton from "../../ScrollTopButton/ScrollTopButton";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const ManagedServices = () => {
  const [isDownloading, setIsDownloading] = useState(false);
  const handleDownload = () => {
    setIsDownloading(true);
    const link = document.createElement("a");
    link.href = "/";
    link.download = "/";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setIsDownloading(false);
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // react slick
  const settings = {
    dots: true,
    infinite: true,

    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
  };
  // Reload 0
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="main_product_page software_solution">
      <section>
        <NavigationBar />
      </section>

      <div className="data-center-wrapper">
        <img className="img img-fluid bg_img" src={managedbg} />

        <div className="data-mobile-texts-div ">
          <div className="data-mobile-texts">
            <div className="d-flex align-items-center text-center">
              <img
                className="img img-fluid"
                src={managedServiceIcon}
                alt="software"
              />
              <h5 className="data-head-tag ms-2">Managed Services</h5>
            </div>

            <p className="data-p2">
              <p>
                We are responsible for maintaining and monitoring the IT
                systems, networks, and software of the organization, ensuring
                that they are running efficiently and securely. Managed IT
                services typically include tasks such as software updates,
                network security management, data backup and recovery, and
                helpdesk support for end-users.
              </p>
              By choosing us your organizations can focus on their core business
              activities, while the MSP takes care of the technology
              infrastructure, providing a cost-effective and efficient solution
              to IT management.
            </p>
          </div>
        </div>
      </div>

      <div className="container offer-header">
        <h4 className="data-fe-icons  opacity-0">Softwares we offer</h4>
      </div>

      {/* <!-- Solution we cover-----------------> */}

      <div class="container">
        <div className="commscop" id="managed-service-div">
          <div className="row ">
            <h3>PERSONALIZED SERVICES</h3>
            <div className="col-3 p-3  align-items-center justify-content-center">
              <img className="img img-fluid" src={manageimg1} alt="" />
              {/* <a className="btn btn-block btn-sm btn-video" href="#">
                  {""}<i className="fa fa-video"></i> See video</a> */}
            </div>
            <div className="col-9">
              {" "}
              <p>
                <p>
                  Pakiza Technovation Limited wants to take the opportunity to
                  utilize its experts’ technical team to design, deploy and
                  manage customer’s IT infrastructure in a professional way as
                  agreed upon in a Service Level Agreement (SLA).
                </p>
                <ul>
                  <li>Design , Implementation and integration</li>
                  <li>Network, Security, Mobility, Compute, Storage </li>
                </ul>
              </p>
            </div>
          </div>
        </div>
        <div className="rosenberger">
          <div className="row ">
            <h3>MANAGED IT SERVICE </h3>
            <div className="col-3 p-3 align-items-center justify-content-center">
              <img className="img img-fluid" src={manageditservice} alt="" />
              {/* <a className="btn btn-block btn-sm btn-video" href="#">
                  {""}<i className="fa fa-video"></i> See video</a> */}
            </div>
            <div className="col-9">
              {" "}
              <p>
                <p>
                  {" "}
                  Managed IT services that are coupled with a Network Operations
                  Center to proactively monitor systems, resolve issues and
                  perform work with a level of expertise and efficiency
                  unparalleled to other solutions.
                </p>
                <ul>
                  <li> Managed IT Service..</li>
                  <li>Hardware AMC</li>
                  <li>IT Consultancy</li>
                </ul>
              </p>
            </div>
          </div>
        </div>
        <div className="ssp">
          <div className="row ">
            <h3>BREAK & FIX</h3>
            <div className="col-3 p-3 align-items-center justify-content-center">
              <img className="img img-fluid" src={manageimg3} alt="" />
              {/* <a className="btn btn-block btn-sm btn-video" href="#">
                  {""}<i className="fa fa-video"></i> See video</a> */}
            </div>
            <div className="col-9">
              {" "}
              <p>
                <p>
                  Although it’s an older style for delivering IT services and
                  repairs to organizations, PTVL wants to fulfill the needs of
                  customer. PTVL wants to do spare part replacement and circuit
                  level repairing to run systems.
                </p>
                <ul>
                  <li> Upgrade system</li>
                  <li> Spare Part Replacement</li>
                  <li> Circuit Level Repairing</li>
                </ul>
              </p>
            </div>
          </div>
        </div>
        <div className="ba">
          <div className="row ">
            <h3>HELP DESK</h3>
            <div className="col-3 p-3align-items-center justify-content-center">
              <img className="img img-fluid" src={helpdesk} alt="" />
              {/* <a className="btn btn-block btn-sm btn-video" href="#">
                  {""}<i className="fa fa-video"></i> See video</a> */}
            </div>
            <div className="col-9">
              {" "}
              <p>
                <p>
                  {" "}
                  If you drive a small company and don’t want to spend much
                  money on skilled IT resource. Pakiza Technovation Limited
                  offers Help Desk service for you. It is also a part of managed
                  IT service offering that provides information and technical
                  support to your end user.
                </p>
                <ul>
                  <li> Phone Support</li>
                  <li>Remote Support</li>
                </ul>
              </p>
            </div>
          </div>
        </div>
        <br />
        <div className="ac">
          <div className="row ">
            <h3>RESOURCE SHARING </h3>
            <div className="col-3 p-3 align-items-center justify-content-center">
              <img className="img img-fluid" src={manageimg6} alt="" />
              {/* <a className="btn btn-block btn-sm btn-video" href="#">
                  {""}<i className="fa fa-video"></i> See video</a> */}
            </div>
            <div className="col-9">
              <p>
                An enterprise solution for storing, transmitting, creating, and
                using data through computing devices, networks and
                telecommunications.
              </p>
              <ul>
                <li> Design , Implementation and integration.e</li>
                <li>
                  {" "}
                  Network & Security, Mobility, Compute, Storage and
                  Virtualization Migration.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <hr className="half-hr" />

      <section>
        <Footer />
      </section>
      <ScrollTopButton />
    </div>
  );
};

export default ManagedServices;

// import Lottie from "lottie-react";
// import React from "react";
// import Footer from "../../Shared/Footer/Footer";
// import NavigationBar from "../../Shared/NavigationBar/NavigationBar";
// import manageLottie from "./managed-services.json";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faPhone, faBookOpen } from "@fortawesome/free-solid-svg-icons";
// import PropTypes from "prop-types";
// import Tabs from "@mui/material/Tabs";
// import Tab from "@mui/material/Tab";
// import Typography from "@mui/material/Typography";
// import Box from "@mui/material/Box";
// import "./ManagedServices.css";
// import manageimg1 from "../../../Images/Product & Services/ManagedS/service.png";
// import manageimg2 from "../../../Images/Product & Services/ManagedS/Hiring-Decisions.jpg";
// import manageimg3 from "../../../Images/Product & Services/ManagedS/service2.png";
// import manageimg4 from "../../../Images/Product & Services/ManagedS/helpDesk.jpg";
// import manageimg5 from "../../../Images/Product & Services/ManagedS/informationTechnology.jpg";
// import manageimg6 from "../../../Images/Product & Services/ManagedS/res.jpg";

// function TabPanel(props) {
//   const { children, value, index, ...other } = props;

//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`vertical-tabpanel-${index}`}
//       aria-labelledby={`vertical-tab-${index}`}
//       {...other}
//     >
//       {value === index && (
//         <Box sx={{ pt: 4 }}>
//           <Typography>{children}</Typography>
//         </Box>
//       )}
//     </div>
//   );
// }

// TabPanel.propTypes = {
//   children: PropTypes.node,
//   index: PropTypes.number.isRequired,
//   value: PropTypes.number.isRequired,
// };

// function a11yProps(index) {
//   return {
//     id: `vertical-tab-${index}`,
//     "aria-controls": `vertical-tabpanel-${index}`,
//   };
// }

// const ManagedServices = () => {
//   // Button
//   const handleClickCustom = () => {
//     window.location.href = "tel:+8801713060441";
//   };

//   // MUI
//   const [value, setValue] = React.useState(0);

//   const handleChange = (event, newValue) => {
//     setValue(newValue);
//   };

//   return (
//     <div className="managed_service_page">
//       <section>
//         <NavigationBar />
//       </section>

//         <div className="container container-fluid">
//           <div className="row">
//             <div className="col-lg-12 col-md-12 col-sm-12 col-12 managed-Lottie-div">
//               <Lottie
//                 animationData={manageLottie}
//                 loop={true}
//                 className="managedservice-Lottie"
//               />
//             </div>
//             <div className="col-lg-12 col-md-12 col-sm-12 col-12 managed-head-text-div">
//               <div className="managed-tags-line">
//                 <p className="managed-tag">Managed</p>
//                 <p className="managed-tag-span">SERVICES</p>
//               </div>
//               <div className="managed-p1-div">
//                 <p className="managed-p1">
//                   Custom software development refers to the creation of software
//                   applications tailored to meet specific business requirements.
//                   Unlike off-the-shelf software, custom software is designed and
//                   developed to fit the unique needs of a particular
//                   organization. The development process typically involves close
//                   collaboration between the software development team and the
//                   client to understand the organization's requirements,
//                   constraints, and goals. This leads to the creation of a
//                   customized solution that is optimized for the specific needs
//                   of the business, delivering improved efficiency and better
//                   outcomes compared to generic software solutions. Custom
//                   software development can also provide organizations with a
//                   competitive advantage by allowing them to differentiate their
//                   offerings and better meet the needs of their customers.
//                 </p>
//               </div>
//               {/* <div className="managed-buttons-div">
//                 <button
//                   onClick={handleClickCustom}
//                   className="btn btn-lg managed-button-demo"
//                 >
//                   <span className="me-2">
//                     <FontAwesomeIcon icon={faPhone} />
//                   </span>
//                   CALL FOR APPOINMENT AND DEMO
//                 </button>
//                 <button className=" btn btn-lg managed-button-discover">
//                   <span className="me-2">
//                     <FontAwesomeIcon icon={faBookOpen} />
//                   </span>
//                   DISCOVER CASE STUDIES
//                 </button>
//               </div> */}
//             </div>
//           </div>
//         </div>

//       <div className="container container-fluid" id="tabs-mui">
//         <div className="row">

//         <Box
//           sx={{
//             flexGrow: 1,
//             bgcolor: "#f8f8f8",
//             display: "flex",
//             height: 304,
//           }}
//         >
//           <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4">
//           <Tabs
//             orientation="vertical"
//             variant="scrollable"
//             value={value}
//             onChange={handleChange}
//             aria-label="Vertical tabs example"
//             sx={{ borderColor: "divider" }}
//           >
//             <Tab
//               label="Professional Services"
//               {...a11yProps(0)}
//             />
//             <Tab className="" label="Managed IT service" {...a11yProps(1)} />
//             <Tab label="Break & Fix" {...a11yProps(2)} />
//             <Tab label="Help Desk" {...a11yProps(3)} />
//             <Tab label="Personalized Service" {...a11yProps(4)} />
//             <Tab label="Resources Sharing" {...a11yProps(5)} />
//           </Tabs>
//           </div>
//           <TabPanel value={value} index={0}>

//               <div className="row">
//               <div className=" col-5 tab-cams-div">
//                 <img className="tabs-manage-cams" src={manageimg1} alt="" />
//               </div>
//               <div className="col-7">
//                 <p>
//                   Pakiza Technovation Limited wants to take the opportunity to
//                   utilize its experts’ technical team to design, deploy and
//                   manage customer’s IT infrastructure in a professional way as
//                   agreed upon in a Service Level Agreement (SLA).
//                 </p>
//                 <ul>
//                   <li>Design , Implementation and integration</li>
//                   <li>Network, Security, Mobility, Compute, Storage</li>
//                 </ul>
//               </div>
//               </div>

//           </TabPanel>
//           <TabPanel value={value} index={1}>
//             <div className="row">
//               <div className="col-5">
//                 <img className="tabs-manage-cams" src={manageimg2} alt="" />
//               </div>
//               <div className="col-7">
//                 <p>
//                   Managed IT services that are coupled with a Network Operations
//                   Center to proactively monitor systems, resolve issues and
//                   perform work with a level of expertise and efficiency
//                   unparalleled to other solutions.
//                 </p>
//                 <ul>
//                   <li>Managed IT Service.</li>
//                   <li> Hardware AMC</li>
//                   <li> IT Consultancy</li>
//                 </ul>
//               </div>
//             </div>
//           </TabPanel>
//           <TabPanel value={value} index={2}>
//             <div className="row">
//               <div className="col-5 tab-cams-div">
//                 <img className="tabs-manage-cams" src={manageimg3} alt="" />
//               </div>
//               <div className="col-7">
//                 <p>
//                   Although it’s an older style for delivering IT services and
//                   repairs to organizations, PTVL wants to fulfill the needs of
//                   customer. PTVL wants to do spare part replacement and circuit
//                   level repairing to run systems.
//                 </p>
//                 <ul>
//                   <li>Upgrade system.</li>
//                   <li>Spare Part Replacement</li>
//                   <li> Circuit Level Repairing</li>
//                 </ul>
//               </div>
//             </div>
//           </TabPanel>
//           <TabPanel value={value} index={3}>
//             <div className="row">
//               <div className="col-5  tab-cams-div">
//                 <img className="tabs-manage-cams" src={manageimg4} alt="" />
//               </div>
//               <div className="col-7">
//                 <p>
//                   If you drive a small company and don’t want to spend much
//                   money on skilled IT resource. Pakiza Technovation Limited
//                   offers Help Desk service for you. It is also a part of managed
//                   IT service offering that provides information and technical
//                   support to your end user.
//                 </p>
//                 <ul>
//                   <li>Phone Support</li>
//                   <li> Remote Support</li>
//                 </ul>
//               </div>
//             </div>
//           </TabPanel>
//           <TabPanel value={value} index={4}>
//             <div className="row">
//               <div className="col-5 tab-cams-div">
//                 <img className="tabs-manage-cams" src={manageimg5} alt="" />
//               </div>
//               <div className="col-7">
//                 <p>
//                   An enterprise solution for storing, transmitting, creating,
//                   and using data through computing devices, networks and
//                   telecommunications.
//                 </p>
//                 <ul>
//                   <li> Design , Implementation and integration.</li>
//                   <li>
//                     {" "}
//                     Network & Security, Mobility, Compute, Storage and
//                     Virtualization Migration.
//                   </li>
//                 </ul>
//               </div>
//             </div>
//           </TabPanel>
//           <TabPanel value={value} index={5}>
//             <div className="row">
//               <div className="col-5 tab-cams-div">
//                 <img className="tabs-manage-cams" src={manageimg6} alt="" />
//               </div>
//               <div className="col-7">
//                 <p>
//                   The process where an PTVL hires technical resource and pays
//                   their salary, benefits, and further training, as well as the
//                   infrastructure they oversee. Customer can avail this resource
//                   with a minimal payment.
//                 </p>
//                 <ul>
//                   <li>
//                     {" "}
//                     Skilled Resource at customer office with a strong back-end
//                     team.
//                   </li>
//                 </ul>
//               </div>
//             </div>
//           </TabPanel>
//         </Box>
//       </div>

//     </div>

//         <Footer />

//     </div>
//   );
// };

// export default ManagedServices;
