import React from "react";
import QualityBelive from "./QualityBelive";
import "./Works.css";
import Hardware from "../../../Images/WorksRoute/Hardware solution.png";
import Software from "../../../Images/WorksRoute/Software solution.png";
import Data from "../../../Images/WorksRoute/Data center Solution.png";
import Managed from "../../../Images/WorksRoute/Managed Services.png";
import Security from "../../../Images/WorksRoute/Security & Surveillance.png";
import ERP from "../../../Images/WorksRoute/Customize ERP Solutions.png";
import "./Works.css";

const QualityBelives = () => {
  const qualityBelives = [
    {
      _id: 1,
      name: "Hardware solution",
      description:
        "Power up your technology with our advanced hardware solutions - designed to meet your specific needs",
      img: Hardware,
      URL: "./hardwaresolution",
    },
    {
      _id: 2,
      name: "Software solution",
      description:
        "Transform your business with our custom-built software solutions - designed to meet your specific needs",
      img: Software,
      URL: "./softwareSolution",
    },
    {
      _id: 3,
      name: "Data center Solution",
      description:
        "Elevate your data security to the next level with our secure data center solutions",
      img: Data,
      URL: "./dataCenter",
    },
    {
      _id: 4,
      name: "Managed Services",
      description:
        "Achieve greater success with our fully managed services - designed to meet your specific needs",
      img: Managed,
      URL: "./manangedServices",
    },
    {
      _id: 5,
      name: "Security & Surveillance",
      description:
        "Keep an eye on your assets with our comprehensive security and surveillance solutions",
      img: Security,
      URL: "./securitySarvillance",
    },
    {
      _id: 6,
      name: "Customize ERP Solutions",
      description:
        "Transform your business with our fully customizable ERP solutions - designed to meet your specific needs",
      img: ERP,
      URL: "./customizeSolution",
    },
  ];
  return (
    <div>
      <section className="container qb-full-div">
        <div>
          <h2 className="qb-h2">We always belive in a quality</h2>
          <p className="qb-p">
            Our commitment to quality is unmatched - providing the best products
            and services to our customers.
          </p>
        </div>
        <div className="qb-serevices-map">
          {qualityBelives.map((qualityBelive) => (
            <QualityBelive
              key={qualityBelive._id}
              qualityBelive={qualityBelive}
            ></QualityBelive>
          ))}
        </div>
      </section>
    </div>
  );
};

export default QualityBelives;
