import Topbar from "./Topbar";
import Sidebar from "./Sidebar";
import DashboardHome from "./DashboardHome";
import Footer from "./Footer";
import axios from "axios";
import Swal from "sweetalert2";
import { Link, Navigate, useNavigate, Routes, Route } from "react-router-dom";

function MasterDashboardLayout({ children }) {
    const navigate = useNavigate();

    const handleLogOut = (e) => {
        e.preventDefault();
        axios.get('sanctum/csrf-cookie').then(response => {

            axios.post('/api/admin-logout').then(res => {


                if (res.data.status == 200) {
                    //   setClickedRender(false);

                    //   let admin_auth = {
                    //     full_name: res.data.full_name,
                    //     image: res.data.image,
                    //     user_type: res.data.user_type,
                    //     token: res.data.token,
                    //     email: res.data.email,
                    //     user_id: res.data.user_id,
                    //     status: 'yes'
                    //   }
                    //   // update(admin_auth)
                    //   handleSucessLogin(admin_auth);
                    localStorage.removeItem('auth_token', res.data.token);
                    localStorage.removeItem('name', res.data.name);
                    localStorage.removeItem('email', res.data.email);
                    localStorage.removeItem('user_type', res.data.user_type);
                    localStorage.removeItem('user_id', res.data.user_id);
                    localStorage.removeItem('user_info', JSON.stringify(res.data.user_info));

                    navigate('/admin-login')

                    //     if (res.data.user_type=== 'admin') {

                    //       navigate('/dashboard')
                    //   }
                    //   else if(res.data.user_type=== 'user'){
                    //       navigate('/')

                    //   }

                }
                else if (res.data.status == 401) {
                    Swal.fire(res.data.message, '', 'warning')
                    //   setClickedRender(false)

                }
                else {
                    Swal.fire('Invalid Credentials', '', 'warning')
                    //   setClickedRender(false)

                }
            })

        });

    }
    return (
        <div class="container-scroller">
            {/* <!-- partial:partials/_navbar.html --> */}
            <nav class="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
                <div class="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
                    <a class="navbar-brand brand-logo mr-5" href="index.html"><img src="images/logo.svg" class="mr-2" alt="logo" /></a>
                    <a class="navbar-brand brand-logo-mini" href="index.html"><img src="images/logo-mini.svg" alt="logo" /></a>
                </div>
                <div class="navbar-menu-wrapper d-flex align-items-center justify-content-end">
                    <button class="navbar-toggler navbar-toggler align-self-center" type="button" data-toggle="minimize">
                        <span class="icon-menu"></span>
                    </button>
                    <ul class="navbar-nav mr-lg-2">
                        <li class="nav-item nav-search d-none d-lg-block">
                            <div class="input-group">
                                <div class="input-group-prepend hover-cursor" id="navbar-search-icon">
                                    <span class="input-group-text" id="search">
                                        <i class="icon-search"></i>
                                    </span>
                                </div>
                                <input type="text" class="form-control" id="navbar-search-input" placeholder="Search now" aria-label="search" aria-describedby="search" />
                            </div>
                        </li>
                    </ul>
                    <ul class="navbar-nav navbar-nav-right">
                        <li class="nav-item dropdown">
                            <a class="nav-link count-indicator dropdown-toggle" id="notificationDropdown" href="#" data-toggle="dropdown">
                                <i class="icon-bell mx-0"></i>
                                <span class="count"></span>
                            </a>
                            <div class="dropdown-menu dropdown-menu-right navbar-dropdown preview-list" aria-labelledby="notificationDropdown">
                                <p class="mb-0 font-weight-normal float-left dropdown-header">Notifications</p>
                                <a class="dropdown-item preview-item">
                                    <div class="preview-thumbnail">
                                        <div class="preview-icon bg-success">
                                            <i class="ti-info-alt mx-0"></i>
                                        </div>
                                    </div>
                                    <div class="preview-item-content">
                                        <h6 class="preview-subject font-weight-normal">Application Error</h6>
                                        <p class="font-weight-light small-text mb-0 text-muted">
                                            Just now
                                        </p>
                                    </div>
                                </a>
                                <a class="dropdown-item preview-item">
                                    <div class="preview-thumbnail">
                                        <div class="preview-icon bg-warning">
                                            <i class="ti-settings mx-0"></i>
                                        </div>
                                    </div>
                                    <div class="preview-item-content">
                                        <h6 class="preview-subject font-weight-normal">Settings</h6>
                                        <p class="font-weight-light small-text mb-0 text-muted">
                                            Private message
                                        </p>
                                    </div>
                                </a>
                                <a class="dropdown-item preview-item">
                                    <div class="preview-thumbnail">
                                        <div class="preview-icon bg-info">
                                            <i class="ti-user mx-0"></i>
                                        </div>
                                    </div>
                                    <div class="preview-item-content">
                                        <h6 class="preview-subject font-weight-normal">New user registration</h6>
                                        <p class="font-weight-light small-text mb-0 text-muted">
                                            2 days ago
                                        </p>
                                    </div>
                                </a>
                            </div>
                        </li>
                        <li class="nav-item nav-profile dropdown">
                            <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown" id="profileDropdown">
                                <img src="images/faces/face28.jpg" alt="profile" />
                            </a>
                            <div class="dropdown-menu dropdown-menu-right navbar-dropdown" aria-labelledby="profileDropdown">
                                <a class="dropdown-item">
                                    <i class="ti-settings text-primary"></i>
                                    Settings
                                </a>
                                <a class="dropdown-item" onClick={handleLogOut}>
                                    <i class="ti-power-off text-primary" ></i>
                                    Logout
                                </a>
                            </div>
                        </li>
                        <li class="nav-item nav-settings d-none d-lg-flex">
                            <a class="nav-link" href="#">
                                <i class="icon-ellipsis"></i>
                            </a>
                        </li>
                    </ul>
                    <button class="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-toggle="offcanvas">
                        <span class="icon-menu"></span>
                    </button>
                </div>
            </nav>
            {/* <!-- partial --> */}
            <div class="container-fluid page-body-wrapper">
                {/* <!-- partial:partials/_settings-panel.html --> */}
                <div class="theme-setting-wrapper">
                    <div id="settings-trigger"><i class="ti-settings"></i></div>
                    <div id="theme-settings" class="settings-panel">
                        <i class="settings-close ti-close"></i>
                        <p class="settings-heading">SIDEBAR SKINS</p>
                        <div class="sidebar-bg-options selected" id="sidebar-light-theme"><div class="img-ss rounded-circle bg-light border mr-3"></div>Light</div>
                        <div class="sidebar-bg-options" id="sidebar-dark-theme"><div class="img-ss rounded-circle bg-dark border mr-3"></div>Dark</div>
                        <p class="settings-heading mt-2">HEADER SKINS</p>
                        <div class="color-tiles mx-0 px-4">
                            <div class="tiles success"></div>
                            <div class="tiles warning"></div>
                            <div class="tiles danger"></div>
                            <div class="tiles info"></div>
                            <div class="tiles dark"></div>
                            <div class="tiles default"></div>
                        </div>
                    </div>
                </div>

                {/* <!-- partial -->
      <!-- partial:partials/_sidebar.html --> */}
                <nav class="sidebar sidebar-offcanvas" id="sidebar" style={{ width: "20%" }}>
                    <ul class="nav">
                        <li class="nav-item">
                            <Link class="nav-link" to="/admin-dashboard">
                                <i class="icon-grid menu-icon"></i>
                                <span class="menu-title">Dashboard</span>
                            </Link>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="collapse" href="#ui-basic" aria-expanded="false" aria-controls="ui-basic">
                                <i class="icon-layout menu-icon"></i>
                                <span class="menu-title">UI Elements</span>
                                <i class="menu-arrow"></i>
                            </a>
                            <div class="collapse" id="ui-basic">
                                <ul class="nav flex-column sub-menu">
                                    <li class="nav-item"> <Link class="nav-link" to="/add-testimonial">Add testimonial</Link></li>
                                    <li class="nav-item"> <Link class="nav-link" to="/view-testimonial">View testimonial</Link></li>
                                    <li class="nav-item"> <a class="nav-link" href="pages/ui-features/buttons.html">Buttons</a></li>
                                    <li class="nav-item"> <a class="nav-link" href="pages/ui-features/dropdowns.html">Dropdowns</a></li>
                                    <li class="nav-item"> <a class="nav-link" href="pages/ui-features/typography.html">Typography</a></li>
                                </ul>
                            </div>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="collapse" href="#form-elements" aria-expanded="false" aria-controls="form-elements">
                                <i class="icon-columns menu-icon"></i>
                                <span class="menu-title">Form elements</span>
                                <i class="menu-arrow"></i>
                            </a>
                            <div class="collapse" id="form-elements">
                                <ul class="nav flex-column sub-menu">
                                    <li class="nav-item"><a class="nav-link" href="pages/forms/basic_elements.html">Basic Elements</a></li>
                                </ul>
                            </div>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link" data-toggle="collapse" href="#auth" aria-expanded="false" aria-controls="auth">
                                <i class="icon-head menu-icon"></i>
                                <span class="menu-title">User Pages</span>
                                <i class="menu-arrow"></i>
                            </a>
                            <div class="collapse" id="auth">
                                <ul class="nav flex-column sub-menu">
                                    <li class="nav-item"> <a class="nav-link" href="pages/samples/login.html"> Login </a></li>
                                    <li class="nav-item"> <a class="nav-link" href="pages/samples/register.html"> Register </a></li>
                                </ul>
                            </div>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="collapse" href="#error" aria-expanded="false" aria-controls="error">
                                <i class="icon-ban menu-icon"></i>
                                <span class="menu-title"></span>Contact Us
                                <i class="menu-arrow"></i>
                            </a>
                            <div class="collapse" id="error">
                                <ul class="nav flex-column sub-menu">
                                    <li class="nav-item"> <Link class="nav-link" to="/add-contact-visit">Add contact visit</Link></li>
                                    <li class="nav-item"> <Link class="nav-link" to="/view-contact-visit">View contact visit</Link></li>
                                    <li class="nav-item"> <Link class="nav-link" to="/view-contact-us-info">Contact US Info</Link></li>                                <li class="nav-item"> <Link class="nav-link" to="/view-contact-us-info">Contact US Info</Link></li>


                                    <li class="nav-item"> <a class="nav-link" href="pages/samples/error-500.html"> 500 </a></li>
                                </ul>
                            </div>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link" data-toggle="collapse" href="#error1" aria-expanded="false" aria-controls="error1">
                                <i class="icon-ban menu-icon"></i>
                                <span class="menu-title"></span>Our Team
                                <i class="menu-arrow"></i>
                            </a>
                            <div class="collapse" id="error1">
                                <ul class="nav flex-column sub-menu">
                                    <li class="nav-item"> <Link class="nav-link" to="/add-our-team">Add Team</Link></li>
                                    <li class="nav-item"> <Link class="nav-link" to="/view-our-team">View Team</Link></li>


                                </ul>
                            </div>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link" href="pages/documentation/documentation.html">
                                <i class="icon-paper menu-icon"></i>
                                <span class="menu-title">Documentation</span>
                            </a>
                        </li>
                    </ul>
                </nav>
                {/* <!-- partial --> */}
                <div class="main-panel">
                    <div class="content-wrapper py-3 px-0">
                        {
                            children
                        }


                    </div>
                    {/* <!-- content-wrapper ends --> */}
                    {/* <!-- partial:partials/_footer.html --> */}
                    <footer class="footer">
                        <div class="d-sm-flex justify-content-center justify-content-sm-between">
                            <span class="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2021.  Premium <a href="https://www.bootstrapdash.com/" target="_blank">Bootstrap admin template</a> from BootstrapDash. All rights reserved.</span>
                            <span class="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i class="ti-heart text-danger ml-1"></i></span>
                        </div>
                    </footer>
                    {/* <!-- partial --> */}
                </div>
                {/* <!-- main-panel ends --> */}
            </div>
            {/* <!-- page-body-wrapper ends --> */}
        </div>

    )
}

export default MasterDashboardLayout;