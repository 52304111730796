import React from "react";
import "./HardwareResource.css";
import hardwareImg from "../../../Images/About-Us/about-banner-img2.png";
import hardResLottieImg from "./hardware-resource-lottie.json";
import Lottie from "lottie-react";

const HardwareResource = () => {
  return (
    <section className="container">
      <div className="row hardware-div">
        <div className="col-lg-6 col-md-6 col-12 Web-hard-view">
          <h2 className="hardware-h2">Mission</h2>
          <hr className="HardRes_hr" />
          <p className="hardwareRe-p">
            To deliver Excellence through Innovation Using Technology.
          </p>
          <h2 className="hardware-h2">Vision</h2>
          <hr className="HardRes_hr" />
          <p className="hardwareRe-p">
            To be the technology partner of choice for forward-looking customers
            by collaboratively transforming technology into business advantage.
          </p>
          <h2 className="hardware-h2">Values</h2>
          <hr className="HardRes_hr" />
          <p className="hardwareRe-p">
            PTVL always love to optimize, not to be lowest in price, its
            solution to meet its client’s requirement and thus ensure value for
            money As the visibility of ICT projects are result driven, it
            ensures the promised result and the project outcome becomes visible.
          </p>
          <h2 className="hardware-h2">Quality Policy</h2>
          <hr className="HardRes_hr" />
          <p className="hardwareRe-p">
            Our goal is to be one of the recognized organizations for IT
            hardware solutions and Software development in the country. To
            achieve this goal, we ensure - IT Hardware and solution business,
            delivery of Bespoke Software (Design, Coding and Testing), and the
            follow-on customer service is an essential part of the quality
            process. - All employees receive training to ensure awareness and
            understanding of quality and its impact on customer service. - The
            leadership team are responsible for ensuring that the quality
            objectives, and commitments to satisfy any applicable requirements
            continually improve the quality management system.
          </p>
        </div>
        {/* <div className="col-lg-6 col-md-6 col-12">
          <img className="img-fluid harwareRe-img" src={hardwareImg} alt="" />
        </div> */}
        <div className="lottie-3-div col-lg-6 col-md-6 col-12">
          <Lottie
            animationData={hardResLottieImg}
            loop={true}
            className="lottie-3"
          />
        </div>
        <div className="mobile-hard-view">
          <h2 className="hardware-h2-mobile">Mission</h2>
          <hr className="HardRes_hr-mobile" />
          <p className="hardwareRe-p-mobile">
            To deliver Excellence through Innovation Using Technology.
          </p>
          <h2 className="hardware-h2-mobile">Vision</h2>
          <hr className="HardRes_hr-mobile" />
          <p className="hardwareRe-p-mobile">
            To be the technology partner of choice for forward-looking customers
            by collaboratively transforming technology into business advantage.
          </p>
          <h2 className="hardware-h2-mobile">Values</h2>
          <hr className="HardRes_hr-mobile" />
          <p className="hardwareRe-p-mobile">
            PTVL always love to optimize, not to be lowest in price, its
            solution to meet its client’s requirement and thus ensure value for
            money As the visibility of ICT projects are result driven, it
            ensures the promised result and the project outcome becomes visible.
          </p>
          <h2 className="hardware-h2-mobile">Quality Policy</h2>
          <hr className="HardRes_hr-mobile" />
          <p className="hardwareRe-p-mobile">
            Our goal is to be one of the recognized organizations for IT
            hardware solutions and Software development in the country. To
            achieve this goal, we ensure - IT Hardware and solution business,
            delivery of Bespoke Software (Design, Coding and Testing), and the
            follow-on customer service is an essential part of the quality
            process. - All employees receive training to ensure awareness and
            understanding of quality and its impact on customer service. - The
            leadership team are responsible for ensuring that the quality
            objectives, and commitments to satisfy any applicable requirements
            continually improve the quality management system.
          </p>
        </div>
      </div>
    </section>
  );
};

export default HardwareResource;
