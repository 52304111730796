import React from "react";
import bannerTop from "../../Images/Works/workbanner.png";
import "./Blogs.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMessage,
  faShare,
  faThumbsUp,
} from "@fortawesome/free-solid-svg-icons";
import BlogsSlider from "./BlogsSlider";

import howWeLottieImg from "./howWeWork.json";
import Lottie from "lottie-react";
import NavigationBar from "../Shared/NavigationBar/NavigationBar";
import Footer from "../Shared/Footer/Footer";

const Blogs = () => {
  return (
    <div>
      <NavigationBar />
      <section className="works-div-banner">
        <div>
          <img className="works-banner" src={bannerTop} alt="" />
        </div>
      </section>
      <section className="works-mobile-texts-div">
        <div className="blogs-mobile-texts">
          <h2 className="blog-mobile-h2">Blog</h2>
          <p className="blog-mobile-p1">
            Hardware <span className="blog-moblie-span">23 September 2020</span>
          </p>
          <h4 className="blog-mobile-h4">
            How to make a website look more attractive with llustrations
          </h4>
          <p className="blog-mobile-p2">
            Podcasting operational change management inside of workflows to
            establish a framework. Taking seamless key performance indicators
            while performing a deep dive on the start-up mentality to derive
            convergence on cross-platform integration.{" "}
            <a href="/howWork">Read More </a>
          </p>
        </div>
      </section>
      <section className="works-texts-div">
        <div className="blogs-mobile-texts">
          <h2 className="blog-h2">Blog</h2>
          <p className="blog-mobile-p1">
            Hardware <span className="blog-span">23 September 2020</span>
          </p>
          <h4 className="blog-h4">
            How to make a website look more attractive with llustrations
          </h4>
          <p className="blog-p2">
            Podcasting operational change management inside of workflows to
            establish a framework. Taking seamless key performance indicators
            offline to maximise the long tail. Keeping your eye on the ball
            while performing a deep dive on the start-up mentality to derive
            convergence on cross-platform integration.{" "}
          </p>
          <p className="blog-p3">
            Podcasting operational change management inside of workflows to
            establish a framework. Taking seamless key performance{" "}
            <a href="/howWork">Read More </a>
          </p>
        </div>
      </section>
      <section className="container">
        <h2 className="works-h2-summ">Recent Posts</h2>
        <div className="row works-post-text-div">
          {/* <div className="col-lg-6 col-md-12 col-12 ">
            <img className="works-post-img" src={recentImg} alt="" />
          </div> */}
          <div className="lottie-4-div col-lg-6 col-md-12 col-12">
            <Lottie
              animationData={howWeLottieImg}
              loop={true}
              className="lottie-4"
            />
          </div>
          <div class="col-lg-6 col-md-12 col-12">
            <p className="works-p1">
              DEVELOPMENT <span className="works-span">23 September 2020</span>
            </p>
            <h2 className="works-h2">
              {" "}
              How to make a website look more attractive with llustrations
            </h2>
            <p className="works-p2">
              Capitalize on low hanging fruit to identify a ballpark value added
              activity to beta test. Override the digital divide with additional
              clickthroughs from DevOps. Nanotechnology immersion along the
              information highway will close the loop on focusing solely on the
              bottom line.....{" "}
              <a className="blog-anchor-tag" href="/howWork">
                Read More
              </a>
            </p>
            <p className="works-p12">
              {" "}
              Capitalize on low hanging fruit to identify a ballpark value added
              activity to beta test. Override the digital divide with additional
              clickthroughs from DevOps. Nanotechnology immersion along the
              information highway.
            </p>
            <div className="works-icons-read">
              <a href="/howWork">Read More </a>
              <div>
                <FontAwesomeIcon className="me-3" icon={faThumbsUp} />
                <FontAwesomeIcon className="me-3" icon={faMessage} />
                <FontAwesomeIcon className="icon-last" icon={faShare} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="container mt-5">
        <BlogsSlider />
      </section>
      <Footer />
    </div>
  );
};

export default Blogs;
