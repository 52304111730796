import { Routes, Route } from "react-router-dom";
import "./App.css";
import NavigationBar from "./Pages/Shared/NavigationBar/NavigationBar";
import axios from "axios";
import imgUrl from "./imgUrl";
import MasterDashboardLayout from "./AdminPanel/MasterDashboardLayout";
import ProtectedRoutes from "./BackendComponent/Authentication/ProtectedRoutes";
import Home from "./Pages/HomePage/Home/Home";
import Footer from "./Pages/Shared/Footer/Footer";
import AboutUs from "./Pages/AboutUsPage/AboutUs/AboutUs";
import Team from "./Pages/TeamPage/Team/Team";
import Work from "./Pages/HowWeWorkPage/Work/Work";
import Contact from "./Pages/ContactUsPage/Contact/Contact";
import Pservices from "./Pages/ProductAndServicePage/ProductService/Pservices";
import Blogs from "./Pages/Blogs/Blogs";
import DashboardHome from "./AdminPanel/DashboardHome";
import CreateTestimonial from "./BackendComponent/HomePage/CreateTestimonial";
import { useEffect, useState } from "react";
import ViewTestimonial from "./BackendComponent/HomePage/ViewTestimonial";
import EditTestimonial from "./BackendComponent/HomePage/EditTestimonial";
import AdminLogin from "./BackendComponent/Authentication/AdminLogin";
import ForgotPassword from "./BackendComponent/Authentication/ForgotPassword";
import AdminPasswordResetForm from "./BackendComponent/Authentication/AdminPasswordResetForm";
import ViewUserContact from "./BackendComponent/ContactUs/ViewUserContact";
import CreateContactVisit from "./BackendComponent/ContactUs/CreateContactVisit";
import ViewContactVisit from "./BackendComponent/ContactUs/ViewContactVisit";
import EditeditContactVisitData from "./BackendComponent/ContactUs/EditContactVisit";
import CreateOurTeam from "./BackendComponent/Our Team/CreateOurTeam";
import ViewOurTeam from "./BackendComponent/Our Team/ViewOurTeam";
import EditOurTeam from "./BackendComponent/Our Team/EditOurTeam";
import SoftwareSolution from "./Pages/ProductAndServicePage/SoftwareSolution/SoftwareSolution";
import HardwareSolution from "./Pages/ProductAndServicePage/HardwareSolution/HardwareSolution";
import DataCenter from "./Pages/ProductAndServicePage/DataCenter/DataCenter";
import ManagedServices from "./Pages/ProductAndServicePage/ManagedServices/ManagedServices";
import SecuritySarvillance from "./Pages/ProductAndServicePage/SecuritySarvillance/SecuritySarvillance";
import RequestQuotation from "./Pages/Shared/RequestQuotation/RequestQuotation";
import RequestDemo from "./Pages/Shared/RequestDemo/RequestDemo";
import CustomizeSolution from "./Pages/ProductAndServicePage/CustomizeSolution/CustomizeSolution";
import CriptonVat from "./Pages/ProductAndServicePage/CriptonVat/CriptonVat";
import PrivacyPolicies from "./Pages/PrivacyPolicies/AppPrivacyPolicies";
import AppPrivacyPolicies from "./Pages/PrivacyPolicies/AppPrivacyPolicies";
import GooglePrivacyPolicies from "./Pages/PrivacyPolicies/GooglePrivacyPolicies";
import PageLoader from "./Pages/PageLoader/PageLoader";
import CriptonVatPro from "./Pages/ProductAndServicePage/CriptonVatPro/CriptonVatPro";
import { Helmet } from "react-helmet";

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate an asynchronous operation (e.g., API call) to load data
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // const [storage, setStorage] = useState('');
  // const data=localStorage.getItem('user_type') === 'admin';

  // console.log('sorage check',storage)

  // useEffect(() => {

  //   setStorage(data);
  // }, [])

  return (
    <div className="App">
      {/* <NavigationBar /> */}

      {/* SEO  */}
      <Helmet>
        <title>
          Pakiza Technovation Limited | Transforming Businesses through Advanced
          Software Solutions
        </title>
        <meta
          name="description"
          content="Pakiza Technovation Limited is a leading software company dedicated to transforming businesses through advanced software solutions. With a team of skilled developers and designers, we offer a comprehensive range of services including custom software development, enterprise application integration, and cloud-based solutions. Trust us to optimize your operations, enhance user experiences, and drive your business forward in today's digital landscape. Contact us now for innovative software solutions tailored to your specific needs."
        ></meta>
      </Helmet>
      {isLoading ? (
        <PageLoader />
      ) : (
        <Routes>
          <Route path="/home" element={<Home />} />
          <Route path="/" element={<Home />} />
          <Route path="/aboutUs" element={<AboutUs />} />
          <Route path="/productService" element={<Pservices />} />
          <Route path="/hardwaresolution" element={<HardwareSolution />} />
          <Route path="/softwareSolution" element={<SoftwareSolution />} />
          <Route path="/softwareSolution" element={<SoftwareSolution />} />
          <Route path="/criptonVatPro" element={<CriptonVatPro />} />
          <Route path="/dataCenter" element={<DataCenter />} />
          <Route
            path="/securitySarvillance"
            element={<SecuritySarvillance />}
          />
          <Route path="/manangedServices" element={<ManagedServices />} />
          <Route path="/customizeSolution" element={<CustomizeSolution />} />
          <Route path="/cirotonVat" element={<CriptonVat />} />
          <Route path="/team" element={<Team />} />
          <Route path="/howWork" element={<Work />} />
          <Route path="/contactUs" element={<Contact />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route
            path="/app_privacy_Policies"
            element={<AppPrivacyPolicies />}
          />
          <Route
            path="/google_privacy_Policies"
            element={<GooglePrivacyPolicies />}
          />
          <Route path="/RequestQuotation" element={<RequestQuotation />} />
          <Route path="/RequestDemo" element={<RequestDemo />} />

          {/* <--Backend Routes--> */}

          {/* <--Backend Routes--> */}
          <Route element={<ProtectedRoutes />}>
            <Route path="admin-dashboard" element={<DashboardHome />} />
            <Route path="add-testimonial" element={<CreateTestimonial />} />
            <Route path="view-testimonial" element={<ViewTestimonial />} />
            <Route path="edit-testimonial/:id" element={<EditTestimonial />} />

            <Route path="add-our-team" element={<CreateOurTeam />} />
            <Route path="view-our-team" element={<ViewOurTeam />} />
            <Route path="edit-our-team/:id" element={<EditOurTeam />} />

            <Route path="add-contact-visit" element={<CreateContactVisit />} />
            <Route path="view-contact-visit" element={<ViewContactVisit />} />
            <Route
              path="edit-contact-visit/:id"
              element={<EditeditContactVisitData />}
            />

            <Route path="view-contact-us-info" element={<ViewUserContact />} />
          </Route>

          <Route path="admin-login" element={<AdminLogin />} />
          <Route path="admin-forgot-password" element={<ForgotPassword />} />
          <Route
            path="admin-password-reset/:id"
            element={<AdminPasswordResetForm />}
          />
          {/* <Route path="/admin-dashboard" element={<MasterDashboardLayout />} /> */}

          <Route />
        </Routes>
      )}
      {/* <Footer></Footer> */}
    </div>
  );
}

export default App;

{
  /* <Home></Home>
<Footer/> */
}
