import React, { Component } from "react";
import Slider from "react-slick";
import "./Blogs.css";
import img1 from "../../Images/Works/blogs-1.png";
import img2 from "../../Images/Works/blogs-2.png";
import img3 from "../../Images/Works/blogs-3.png";
import img4 from "../../Images/Works/blogs-4.png";
import img5 from "../../Images/Works/blogs-5.png";
import img6 from "../../Images/Works/blogs-6.png";
import img7 from "../../Images/Works/blogs-7.png";
import img8 from "../../Images/Works/blogs-8.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMessage,
  faShare,
  faThumbsUp,
} from "@fortawesome/free-solid-svg-icons";

export default class BlogsSlider extends Component {
  // render() {
  //   const settings = {
  //     infinite: true,
  //     speed: 500,
  //     slidesToShow: 3,
  //     slidesToScroll: 3,
  //     responsive: [
  //       {
  //         breakpoint: 1024,
  //         settings: {
  //           slidesToShow: 3,
  //           slidesToScroll: 3,
  //           infinite: true,
  //           dots: true,
  //         },
  //       },
  //       {
  //         breakpoint: 600,
  //         settings: {
  //           slidesToShow: 2,
  //           slidesToScroll: 2,
  //           initialSlide: 2,
  //         },
  //       },
  //       {
  //         breakpoint: 480,
  //         settings: {
  //           slidesToShow: 1,
  //           slidesToScroll: 1,
  //         },
  //       },
  //     ],
  //   };
  //   return (
  //     <div className="mb-5">
  //       <Slider {...settings}>
  //         <div className="blogs-slider-single">
  //           <img className="img-fluid blog-rule-img" src={img1} alt="" />
  //           <p className="blogs-slider-p">
  //             DEVELOPMENT{" "}
  //             <span className="blogs-slider-span">23 September 2020</span>
  //           </p>
  //           <h4 className="blogs-slider-h4">8 Rules of Thumb in UI Design</h4>
  //           <p className="blogs-slider-p2">
  //             Bring to the table win-win survival strategies to ensure proactive
  //             domination. At the end of the day, going forward, a new normal
  //             that has evolved from
  //           </p>
  //           <div className="d-flex justify-content-between">
  //             <a href="">Read More</a>
  //             <div>
  //               <FontAwesomeIcon
  //                 style={{ color: "#169BFF" }}
  //                 className="me-2"
  //                 icon={faThumbsUp}
  //               />
  //               <FontAwesomeIcon
  //                 style={{ color: "#169BFF" }}
  //                 className="me-2"
  //                 icon={faMessage}
  //               />
  //               <FontAwesomeIcon
  //                 style={{ color: "#169BFF" }}
  //                 className="blog-slid-icon-last"
  //                 icon={faShare}
  //               />
  //             </div>
  //           </div>
  //         </div>
  //         <div className="blogs-slider-single">
  //           <img className="img-fluid blog-rule-img" src={img2} alt="" />
  //           <p className="blogs-slider-p">
  //             DEVELOPMENT{" "}
  //             <span className="blogs-slider-span">23 September 2020</span>
  //           </p>
  //           <h4 className="blogs-slider-h4">8 Rules of Thumb in UI Design</h4>
  //           <p className="blogs-slider-p2">
  //             Bring to the table win-win survival strategies to ensure proactive
  //             domination. At the end of the day, going forward, a new normal
  //             that has evolved from
  //           </p>
  //           <div className="d-flex justify-content-between">
  //             <a href="">Read More</a>
  //             <div>
  //               <FontAwesomeIcon
  //                 style={{ color: "#169BFF" }}
  //                 className="me-2"
  //                 icon={faThumbsUp}
  //               />
  //               <FontAwesomeIcon
  //                 style={{ color: "#169BFF" }}
  //                 className="me-2"
  //                 icon={faMessage}
  //               />
  //               <FontAwesomeIcon
  //                 style={{ color: "#169BFF" }}
  //                 className="blog-slid-icon-last"
  //                 icon={faShare}
  //               />
  //             </div>
  //           </div>
  //         </div>
  //         <div className="blogs-slider-single">
  //           <img className="img-fluid blog-rule-img" src={img3} alt="" />
  //           <p className="blogs-slider-p">
  //             DEVELOPMENT{" "}
  //             <span className="blogs-slider-span">23 September 2020</span>
  //           </p>
  //           <h4 className="blogs-slider-h4">8 Rules of Thumb in UI Design</h4>
  //           <p className="blogs-slider-p2">
  //             Bring to the table win-win survival strategies to ensure proactive
  //             domination. At the end of the day, going forward, a new normal
  //             that has evolved from
  //           </p>
  //           <div className="d-flex justify-content-between">
  //             <a href="">Read More</a>
  //             <div>
  //               <FontAwesomeIcon
  //                 style={{ color: "#169BFF" }}
  //                 className="me-2"
  //                 icon={faThumbsUp}
  //               />
  //               <FontAwesomeIcon
  //                 style={{ color: "#169BFF" }}
  //                 className="me-2"
  //                 icon={faMessage}
  //               />
  //               <FontAwesomeIcon
  //                 style={{ color: "#169BFF" }}
  //                 className="blog-slid-icon-last"
  //                 icon={faShare}
  //               />
  //             </div>
  //           </div>
  //         </div>
  //         <div className="blogs-slider-single">
  //           <img className="img-fluid blog-rule-img" src={img4} alt="" />
  //           <p className="blogs-slider-p">
  //             DEVELOPMENT{" "}
  //             <span className="blogs-slider-span">23 September 2020</span>
  //           </p>
  //           <h4 className="blogs-slider-h4">8 Rules of Thumb in UI Design</h4>
  //           <p className="blogs-slider-p2">
  //             Bring to the table win-win survival strategies to ensure proactive
  //             domination. At the end of the day, going forward, a new normal
  //             that has evolved from
  //           </p>
  //           <div className="d-flex justify-content-between">
  //             <a href="">Read More</a>
  //             <div>
  //               <FontAwesomeIcon
  //                 style={{ color: "#169BFF" }}
  //                 className="me-2"
  //                 icon={faThumbsUp}
  //               />
  //               <FontAwesomeIcon
  //                 style={{ color: "#169BFF" }}
  //                 className="me-2"
  //                 icon={faMessage}
  //               />
  //               <FontAwesomeIcon
  //                 style={{ color: "#169BFF" }}
  //                 className="blog-slid-icon-last"
  //                 icon={faShare}
  //               />
  //             </div>
  //           </div>
  //         </div>
  //         <div className="blogs-slider-single">
  //           <img className="img-fluid blog-rule-img" src={img5} alt="" />
  //           <p className="blogs-slider-p">
  //             DEVELOPMENT{" "}
  //             <span className="blogs-slider-span">23 September 2020</span>
  //           </p>
  //           <h4 className="blogs-slider-h4">8 Rules of Thumb in UI Design</h4>
  //           <p className="blogs-slider-p2">
  //             Bring to the table win-win survival strategies to ensure proactive
  //             domination. At the end of the day, going forward, a new normal
  //             that has evolved from
  //           </p>
  //           <div className="d-flex justify-content-between">
  //             <a href="">Read More</a>
  //             <div>
  //               <FontAwesomeIcon
  //                 style={{ color: "#169BFF" }}
  //                 className="me-2"
  //                 icon={faThumbsUp}
  //               />
  //               <FontAwesomeIcon
  //                 style={{ color: "#169BFF" }}
  //                 className="me-2"
  //                 icon={faMessage}
  //               />
  //               <FontAwesomeIcon
  //                 style={{ color: "#169BFF" }}
  //                 className="blog-slid-icon-last"
  //                 icon={faShare}
  //               />
  //             </div>
  //           </div>
  //         </div>
  //         <div className="blogs-slider-single">
  //           <img className="img-fluid blog-rule-img" src={img6} alt="" />
  //           <p className="blogs-slider-p">
  //             DEVELOPMENT{" "}
  //             <span className="blogs-slider-span">23 September 2020</span>
  //           </p>
  //           <h4 className="blogs-slider-h4">8 Rules of Thumb in UI Design</h4>
  //           <p className="blogs-slider-p2">
  //             Bring to the table win-win survival strategies to ensure proactive
  //             domination. At the end of the day, going forward, a new normal
  //             that has evolved from
  //           </p>
  //           <div className="d-flex justify-content-between">
  //             <a href="">Read More</a>
  //             <div>
  //               <FontAwesomeIcon
  //                 style={{ color: "#169BFF" }}
  //                 className="me-2"
  //                 icon={faThumbsUp}
  //               />
  //               <FontAwesomeIcon
  //                 style={{ color: "#169BFF" }}
  //                 className="me-2"
  //                 icon={faMessage}
  //               />
  //               <FontAwesomeIcon
  //                 style={{ color: "#169BFF" }}
  //                 className="blog-slid-icon-last"
  //                 icon={faShare}
  //               />
  //             </div>
  //           </div>
  //         </div>
  //         <div className="blogs-slider-single">
  //           <img className="img-fluid blog-rule-img" src={img7} alt="" />
  //           <p className="blogs-slider-p">
  //             DEVELOPMENT{" "}
  //             <span className="blogs-slider-span">23 September 2020</span>
  //           </p>
  //           <h4 className="blogs-slider-h4">8 Rules of Thumb in UI Design</h4>
  //           <p className="blogs-slider-p2">
  //             Bring to the table win-win survival strategies to ensure proactive
  //             domination. At the end of the day, going forward, a new normal
  //             that has evolved from
  //           </p>
  //           <div className="d-flex justify-content-between">
  //             <a href="">Read More</a>
  //             <div>
  //               <FontAwesomeIcon
  //                 style={{ color: "#169BFF" }}
  //                 className="me-2"
  //                 icon={faThumbsUp}
  //               />
  //               <FontAwesomeIcon
  //                 style={{ color: "#169BFF" }}
  //                 className="me-2"
  //                 icon={faMessage}
  //               />
  //               <FontAwesomeIcon
  //                 style={{ color: "#169BFF" }}
  //                 className="blog-slid-icon-last"
  //                 icon={faShare}
  //               />
  //             </div>
  //           </div>
  //         </div>
  //         <div className="blogs-slider-single">
  //           <img className="img-fluid blog-rule-img" src={img8} alt="" />
  //           <p className="blogs-slider-p">
  //             DEVELOPMENT{" "}
  //             <span className="blogs-slider-span">23 September 2020</span>
  //           </p>
  //           <h4 className="blogs-slider-h4">8 Rules of Thumb in UI Design</h4>
  //           <p className="blogs-slider-p2">
  //             Bring to the table win-win survival strategies to ensure proactive
  //             domination. At the end of the day, going forward, a new normal
  //             that has evolved from
  //           </p>
  //           <div className="d-flex justify-content-between">
  //             <a href="">Read More</a>
  //             <div>
  //               <FontAwesomeIcon
  //                 style={{ color: "#169BFF" }}
  //                 className="me-2"
  //                 icon={faThumbsUp}
  //               />
  //               <FontAwesomeIcon
  //                 style={{ color: "#169BFF" }}
  //                 className="me-2"
  //                 icon={faMessage}
  //               />
  //               <FontAwesomeIcon
  //                 style={{ color: "#169BFF" }}
  //                 className="blog-slid-icon-last"
  //                 icon={faShare}
  //               />
  //             </div>
  //           </div>
  //         </div>
  //       </Slider>
  //     </div>
  //   );
  // }
}
