import React, { useState, Component } from "react";
import "./CriptonVatPro.css";
import Slider from "react-slick";
import NavigationBar from "../../Shared/NavigationBar/NavigationBar";
import Footer from "../../Shared/Footer/Footer";
import vatProBackground from "../../../Images/Product & Services/Software/criptonVatPro.png";

import criptonicon1 from "../../../Images/Product & Services/Software/cripton-vat-pro/warehouse.png";
import criptonicon2 from "../../../Images/Product & Services/Software/cripton-vat-pro/inventory.png";
import criptonicon3 from "../../../Images/Product & Services/Software/cripton-vat-pro/production.png";
import criptonicon4 from "../../../Images/Product & Services/Software/cripton-vat-pro/ledger.png";
import criptonicon5 from "../../../Images/Product & Services/Software/cripton-vat-pro/sales.png";
import criptonicon6 from "../../../Images/Product & Services/Software/cripton-vat-pro/distribution.png";
import criptonicon7 from "../../../Images/Product & Services/Software/cripton-vat-pro/voucher.png";
import criptonicon8 from "../../../Images/Product & Services/Software/cripton-vat-pro/audit.png";
import criptonicon9 from "../../../Images/Product & Services/Software/cripton-vat-pro/accounting.png";
import criptonicon10 from "../../../Images/Product & Services/Software/cripton-vat-pro/products.png";
import criptonicon11 from "../../../Images/Product & Services/Software/cripton-vat-pro/local_purchase.png";
import criptonicon12 from "../../../Images/Product & Services/Software/cripton-vat-pro/reports.png";
import mushaks from "../../../Images/Product & Services/Software/Vat-Mushak.png";
import mushak9_1 from "../../../Images/Product & Services/Software/Mushak/Mushak 9.1.jpeg";
import mushak4_3 from "../../../Images/Product & Services/Software/Mushak/Mushak 4.3.png";
import mushak6_3 from "../../../Images/Product & Services/Software/Mushak/Mushak 6.3.png";
import mushak6_7 from "../../../Images/Product & Services/Software/Mushak/Mushak 6.7.png";
import mushak_tr from "../../../Images/Product & Services/Software/Mushak/Tresure6.png";
import Mushak6_1 from "../../../Images/Product & Services/Software/Mushak/Mushak 6.1.png";
import Mushak6_4 from "../../../Images/Product & Services/Software/Mushak/Mushak 6.4.png";
import Mushak6_8 from "../../../Images/Product & Services/Software/Mushak/Mushak 6.8.png";
import Mushak6_2 from "../../../Images/Product & Services/Software/Mushak/Mushak 6.2.png";
import Mushak6_5 from "../../../Images/Product & Services/Software/Mushak/Mushak 6.5.png";
import Mushak6_10 from "../../../Images/Product & Services/Software/Mushak/Mushak 4.3.png";
import Mushak6_2_1 from "../../../Images/Product & Services/Software/Mushak/Mushak 6.2.1.png";
import Mushak6_6 from "../../../Images/Product & Services/Software/Mushak/Mushak 6.7.png";
import Mushak7_1 from "../../../Images/Product & Services/Software/Mushak/Mushak 6.8.png";
import criptonClientsEdi from "../../../Images/Company-Client/Clients- 9.png";
import criptonClientNorth from "../../../Images/Company-Client/Clients- 8.png";
import criptonClientFore from "../../../Images/Company-Client/Clients- 4.png";

import criptonClientsAmana from "../../../Images/Company-Client/Clients- 3.jpg";

const criptonVatProBanner = {
  background: `url(${vatProBackground})`,
  width: "100%",
  height: "110vh",
  backgroundRepeat: "no-repeat",
  position: "relative",
  backgroundSize: "cover",
  overflowX: "hidden",
  opacity: 1,
};

const settings1 = {
  autoplay: true,
  infinite: true,
  speed: 2000,
  autoplaySpeed: 3000,
  dots: true,

  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
};

const CriptonVatPro = () => {
  const [showModal, setShowModal] = useState(null);

  const handleOpenModal = (id) => {
    setShowModal(id);
  };

  const handleCloseModal = () => {
    setShowModal(null);
  };
  const handleModalClick = (e) => {
    // Close the modal if the click occurred outside the modal content
    if (e.target.classList.contains("modal")) {
      handleCloseModal();
    }
  };

  // Clients slider
  var settings2 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    arrows: false,
    speed: 2000,
    autoplaySpeed: 2000,
    // cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  var settings3 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    speed: 2500,
    autoplaySpeed: 1000,
    // cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div>
      <div className="">
        <NavigationBar />
      </div>
      <div className="" style={criptonVatProBanner}>
        <div className="vat-pro-div container">
          <Slider {...settings1}>
            <div className="vat-pro-slide-text-div">
              <h1 className="vat-pro-slide-h1">
                Enhance Your Business with
                <br /> Cripton VAT Pro.
              </h1>
              <p className="vat-pro-slide-p">
                Drive Efficiency and Simplify VAT Calculation and Filing
              </p>
              <div className="vat-slider-btn-div">
                <button className="vat-slider-btn">Learn More</button>
              </div>
            </div>
            <div className="vat-pro-slide-text-div">
              <h1 className="vat-pro-slide-h1">
                Seamless Business Management with
                <br /> Cripton VAT Pro.
              </h1>
              <p className="vat-pro-slide-p">
                Optimize Operations and Ensure VAT Compliance.
              </p>
              <div className="vat-slider-btn-div">
                <button className="vat-slider-btn">Learn More</button>
              </div>
            </div>
            <div className="vat-pro-slide-text-div">
              <h1 className="vat-pro-slide-h1">
                Experience Efficiency with Advanced
                <br /> ERP Solutions and Cripton VAT Pro.
              </h1>
              <p className="vat-pro-slide-p">
                Streamline Your Business Processes and Ensure Accurate VAT
                Reporting
              </p>
              <div className="vat-slider-btn-div">
                <button className="vat-slider-btn">Learn More</button>
              </div>
            </div>
            <div className="vat-pro-slide-text-div">
              <h1 className="vat-pro-slide-h1">
                Optimize Business Performance with
                <br /> Cripton VAT Pro.
              </h1>
              <p className="vat-pro-slide-p">
                Efficiently Manage Operations and Streamline VAT Compliance
              </p>
              <div className="vat-slider-btn-div">
                <button className="vat-slider-btn">Learn More</button>
              </div>
            </div>
          </Slider>
        </div>
      </div>

      <div className="cripton-icons-alldiv container-fluid">
        <div className="row">
          <div className="col-xl-3 col-lg-3 col-md-6 col-12">
            <div className="single-icon-div">
              <img className="vatpro-icon" src={criptonicon1} alt="" />
              <div>
                <div className="second-text">
                  <p>Multi warehouse</p>
                  <p>management</p>
                </div>
              </div>
            </div>
            <div className="single-icon-div">
              <img className="vatpro-icon" src={criptonicon2} alt="" />
              <p>Inventory with HS Code</p>
            </div>
            <div className="single-icon-div">
              <img className="vatpro-icon" src={criptonicon3} alt="" />
              <p>Production Process</p>
            </div>
          </div>
          <div className="col-xl-3 col-lg-3 col-md-6 col-12">
            <div className="single-icon-div">
              <img className="vatpro-icon" src={criptonicon4} alt="" />
              <p>Multi Ledger</p>
            </div>
            <div className="single-icon-div">
              <img className="vatpro-icon" src={criptonicon5} alt="" />
              <div className="second-text">
                <p>Sales &</p>
                <p>Collection</p>
              </div>
            </div>
            <div className="single-icon-div">
              <img className="vatpro-icon" src={criptonicon6} alt="" />
              <div className="second-text">
                <p>Distribution</p>
                <p>process</p>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-3 col-md-6 col-12">
            <div className="single-icon-div">
              <img className="vatpro-icon" src={criptonicon7} alt="" />
              <p>Voucher</p>
            </div>
            <div className="single-icon-div">
              <img className="vatpro-icon" src={criptonicon8} alt="" />
              <p>Audit Trail</p>
            </div>
            <div className="single-icon-div">
              <img className="vatpro-icon" src={criptonicon9} alt="" />
              <p>Accounting</p>
            </div>
          </div>
          <div className="col-xl-3 col-lg-3 col-md-6 col-12">
            <div className="single-icon-div">
              <img className="vatpro-icon" src={criptonicon10} alt="" />
              <p>Dynamic Product Mangement</p>
            </div>
            <div className="single-icon-div">
              <img className="vatpro-icon" src={criptonicon11} alt="" />
              <p>Local and foreign purchase</p>
            </div>
            <div className="single-icon-div">
              <img className="vatpro-icon" src={criptonicon12} alt="" />
              <p>Report Mangement</p>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="cripton-third-secton-tag">
          <h2>VAT Compliance</h2>
          <p>A Complete Software Solution for the VAT & SD Act 2012.</p>
        </div>
        <div className="row" onClick={handleModalClick}>
          <div className="col-xl-3 col-lg-3 col-md-6 col-12">
            <div>
              <div
                onClick={() => handleOpenModal(1)}
                className="single-vat-compliance-div"
              >
                <img src={mushaks} alt="" />
                <div>
                  <p>Mushak 9.1</p>
                  <h6>VAT Return</h6>
                </div>
              </div>

              {showModal === 1 && (
                <div
                  className="modal fade show"
                  tabIndex="-1"
                  role="dialog"
                  style={{ display: "block" }}
                >
                  <div className="modal-dialog" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <button
                          type="button"
                          className="close"
                          onClick={handleCloseModal}
                        >
                          <span>&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <img
                          src={mushak9_1}
                          alt="mushak9_1"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div>
              <div
                onClick={() => handleOpenModal(2)}
                className="single-vat-compliance-div"
              >
                <img src={mushaks} alt="" />
                <div>
                  <p>Mushak 4.3</p>
                  <h6>Input Output Coefficient</h6>
                </div>
              </div>
              {showModal === 2 && (
                <div
                  className="modal fade show"
                  tabIndex="-1"
                  role="dialog"
                  style={{ display: "block" }}
                >
                  <div className="modal-dialog" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <button
                          type="button"
                          className="close"
                          onClick={handleCloseModal}
                        >
                          <span>&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <img
                          src={mushak4_3}
                          alt="mushak4_3"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div>
              <div
                className="single-vat-compliance-div"
                onClick={() => handleOpenModal(3)}
              >
                <img src={mushaks} alt="" />
                <div>
                  <p>Mushak 6.3</p>
                  <h6>Tax Chalan</h6>
                </div>
              </div>

              {showModal === 3 && (
                <div
                  className="modal fade show"
                  tabIndex="-1"
                  role="dialog"
                  style={{ display: "block" }}
                >
                  <div className="modal-dialog" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <button
                          type="button"
                          className="close"
                          onClick={handleCloseModal}
                        >
                          <span>&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <img
                          src={mushak6_3}
                          alt="Modal Image 3"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div>
              <div
                className="single-vat-compliance-div"
                onClick={() => handleOpenModal(4)}
              >
                <img src={mushaks} alt="" />
                <div>
                  <p>Mushak 6.7</p>
                  <h6>Credit Note</h6>
                </div>
              </div>
              {showModal === 4 && (
                <div
                  className="modal fade show"
                  tabIndex="-1"
                  role="dialog"
                  style={{ display: "block" }}
                >
                  <div className="modal-dialog" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <button
                          type="button"
                          className="close"
                          onClick={handleCloseModal}
                        >
                          <span>&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <img
                          src={mushak6_7}
                          alt="mushak6_7"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div>
              <div
                className="single-vat-compliance-div"
                onClick={() => handleOpenModal(5)}
              >
                <img src={mushaks} alt="" />
                <div>
                  <p>TR-6</p>
                  <h6>Treasury Challan</h6>
                </div>
              </div>
              {showModal === 5 && (
                <div
                  className="modal fade show"
                  tabIndex="-1"
                  role="dialog"
                  style={{ display: "block" }}
                >
                  <div className="modal-dialog" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <button
                          type="button"
                          className="close"
                          onClick={handleCloseModal}
                        >
                          <span>&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <img
                          src={mushak_tr}
                          alt="mushak6_7"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="col-xl-3 col-lg-3 col-md-6 col-12">
            <div>
              <div
                className="single-vat-compliance-div"
                onClick={() => handleOpenModal(6)}
              >
                <img src={mushaks} alt="" />
                <div>
                  <p>Mushak 6.1</p>
                  <h6>Purchase Book</h6>
                </div>
              </div>
              {showModal === 6 && (
                <div
                  className="modal fade show"
                  tabIndex="-1"
                  role="dialog"
                  style={{ display: "block" }}
                >
                  <div className="modal-dialog" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <button
                          type="button"
                          className="close"
                          onClick={handleCloseModal}
                        >
                          <span>&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <img
                          src={Mushak6_1}
                          alt="mushak6_7"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div>
              <div
                className="single-vat-compliance-div"
                onClick={() => handleOpenModal(7)}
              >
                <img src={mushaks} alt="" />
                <div>
                  <p>Mushak 6.4</p>
                  <h6>Contractual Production Challan</h6>
                </div>
              </div>
              {showModal === 7 && (
                <div
                  className="modal fade show"
                  tabIndex="-1"
                  role="dialog"
                  style={{ display: "block" }}
                >
                  <div className="modal-dialog" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <button
                          type="button"
                          className="close"
                          onClick={handleCloseModal}
                        >
                          <span>&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <img
                          src={Mushak6_4}
                          alt="mushak6_7"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div>
              <div
                className="single-vat-compliance-div"
                onClick={() => handleOpenModal(8)}
              >
                <img src={mushaks} alt="" />
                <div>
                  <p>Mushak 6.8</p>
                  <h6>Dabit Note</h6>
                </div>
              </div>
              {showModal === 8 && (
                <div
                  className="modal fade show"
                  tabIndex="-1"
                  role="dialog"
                  style={{ display: "block" }}
                >
                  <div className="modal-dialog" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <button
                          type="button"
                          className="close"
                          onClick={handleCloseModal}
                        >
                          <span>&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <img
                          src={Mushak6_8}
                          alt="mushak6_7"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div>
              <div
                className="single-vat-compliance-div"
                onClick={() => handleOpenModal(9)}
              >
                <img src={mushaks} alt="" />
                <div>
                  <p>MIS Report</p>
                  <h6>Pack</h6>
                </div>
              </div>
              {showModal === 9 && (
                <div
                  className="modal fade show"
                  tabIndex="-1"
                  role="dialog"
                  style={{ display: "block" }}
                >
                  <div className="modal-dialog" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <button
                          type="button"
                          className="close"
                          onClick={handleCloseModal}
                        >
                          <span>&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        {/* <img
                          src={Mushak6_8}
                          alt="mushak6_7"
                          className="img-fluid"
                        /> */}
                        <h2>Content Comming Soon......</h2>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="col-xl-3 col-lg-3 col-md-6 col-12">
            <div>
              <div
                className="single-vat-compliance-div"
                onClick={() => handleOpenModal(10)}
              >
                <img src={mushaks} alt="" />
                <div>
                  <p>Mushak 6.2.2</p>
                  <h6>Sales Book</h6>
                </div>
              </div>
              {showModal === 10 && (
                <div
                  className="modal fade show"
                  tabIndex="-1"
                  role="dialog"
                  style={{ display: "block" }}
                >
                  <div className="modal-dialog" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <button
                          type="button"
                          className="close"
                          onClick={handleCloseModal}
                        >
                          <span>&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <img
                          src={Mushak6_2}
                          alt="mushak6_7"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div>
              <div
                className="single-vat-compliance-div"
                onClick={() => handleOpenModal(10)}
              >
                <img src={mushaks} alt="" />
                <div>
                  <p>Mushak 6.2</p>
                  <h6>Sales Book</h6>
                </div>
              </div>
              {showModal === 10 && (
                <div
                  className="modal fade show"
                  tabIndex="-1"
                  role="dialog"
                  style={{ display: "block" }}
                >
                  <div className="modal-dialog" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <button
                          type="button"
                          className="close"
                          onClick={handleCloseModal}
                        >
                          <span>&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <img
                          src={Mushak6_2}
                          alt="mushak6_7"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div>
              <div
                className="single-vat-compliance-div"
                onClick={() => handleOpenModal(11)}
              >
                <img src={mushaks} alt="" />
                <div>
                  <p>Mushak 6.5</p>
                  <h6>Transfer Challan</h6>
                </div>
              </div>
              {showModal === 11 && (
                <div
                  className="modal fade show"
                  tabIndex="-1"
                  role="dialog"
                  style={{ display: "block" }}
                >
                  <div className="modal-dialog" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <button
                          type="button"
                          className="close"
                          onClick={handleCloseModal}
                        >
                          <span>&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <img
                          src={Mushak6_5}
                          alt="mushak6_7"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div>
              <div
                className="single-vat-compliance-div"
                onClick={() => handleOpenModal(12)}
              >
                <img src={mushaks} alt="" />
                <div>
                  <p>Mushak 6.10</p>
                  <h6>Sale-purchase over 2 lac</h6>
                </div>
              </div>
              {showModal === 12 && (
                <div
                  className="modal fade show"
                  tabIndex="-1"
                  role="dialog"
                  style={{ display: "block" }}
                >
                  <div className="modal-dialog" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <button
                          type="button"
                          className="close"
                          onClick={handleCloseModal}
                        >
                          <span>&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <img
                          src={Mushak6_10}
                          alt="mushak6_7"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="col-xl-3 col-lg-3 col-md-6 col-12">
            <div>
              <div
                className="single-vat-compliance-div"
                onClick={() => handleOpenModal(13)}
              >
                <img src={mushaks} alt="" />
                <div>
                  <p>Mushak 6.2.1</p>
                  <h6>Sale Purchase Book</h6>
                </div>
              </div>
              {showModal === 13 && (
                <div
                  className="modal fade show"
                  tabIndex="-1"
                  role="dialog"
                  style={{ display: "block" }}
                >
                  <div className="modal-dialog" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <button
                          type="button"
                          className="close"
                          onClick={handleCloseModal}
                        >
                          <span>&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <img
                          src={Mushak6_2_1}
                          alt="mushak6_7"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div>
              <div
                className="single-vat-compliance-div"
                onClick={() => handleOpenModal(14)}
              >
                <img src={mushaks} alt="" />
                <div>
                  <p>Mushak 6.6</p>
                  <h6>VDS Certificate</h6>
                </div>
              </div>
              {showModal === 14 && (
                <div
                  className="modal fade show"
                  tabIndex="-1"
                  role="dialog"
                  style={{ display: "block" }}
                >
                  <div className="modal-dialog" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <button
                          type="button"
                          className="close"
                          onClick={handleCloseModal}
                        >
                          <span>&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <img
                          src={Mushak6_6}
                          alt="mushak6_7"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div>
              <div
                className="single-vat-compliance-div"
                onClick={() => handleOpenModal(15)}
              >
                <img src={mushaks} alt="" />
                <div>
                  <p>Mushak 7.1</p>
                  <h6>Application for adjustment of SD</h6>
                </div>
              </div>
              {showModal === 15 && (
                <div
                  className="modal fade show"
                  tabIndex="-1"
                  role="dialog"
                  style={{ display: "block" }}
                >
                  <div className="modal-dialog" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <button
                          type="button"
                          className="close"
                          onClick={handleCloseModal}
                        >
                          <span>&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <img
                          src={Mushak7_1}
                          alt="mushak6_7"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className=" pb-5 mt-5 clients">
        <div className="container">
          <div className="Cripton-Clients-text">
            <h3>CLIENTS</h3>
          </div>
          <div>
            <Slider {...settings2}>
              <div className="slider">
                <a href="https://www.edison-properties.com/" target="_blank">
                  <img
                    className="cripton-clients"
                    src={criptonClientsEdi}
                    alt=""
                  />
                </a>
              </div>
              <div className="slider">
                <a href="https://nwpgcl.gov.bd/" target="_blank">
                  <img
                    className="cripton-clients"
                    src={criptonClientNorth}
                    alt=""
                  />
                </a>
              </div>
              <div className="slider">
                <a href="https://amanagroupbd.com/" target="_blank">
                  <img
                    className="cripton-clients"
                    src={criptonClientsAmana}
                    alt=""
                  />
                </a>
              </div>
              <div className="slider">
                <a href="https://www.forefront-bd.com/" target="_blank">
                  <img
                    className="cripton-clients"
                    src={criptonClientFore}
                    alt=""
                  />
                </a>
              </div>
              <div className="slider">
                <a href="https://www.edison-properties.com/" target="_blank">
                  <img
                    className="cripton-clients"
                    src={criptonClientsEdi}
                    alt=""
                  />
                </a>
              </div>
            </Slider>
          </div>
        </div>
      </div>

      <div className="container pb-5 Cripton-Clients-div">
        <div>
          <div className="Cripton-Clients-text">
            <h3>Client Review</h3>
          </div>
          <div>
            <Slider {...settings3}>
              <div className="slider-review">
                <div className="single-review">
                  <h5>Name: Alison Burger</h5>
                  <h4>Designation: CEO </h4>
                  <h4>Conpany: CompanyX </h4>
                </div>
              </div>
              <div className="slider-review">
                <div className="single-review">
                  <h5>Name: Alison Burger</h5>
                  <h4>Designation: CEO </h4>
                  <h4>Conpany: CompanyX </h4>
                </div>
              </div>
              <div className="slider-review">
                <div className="single-review">
                  <h5>Name: Alison Burger</h5>
                  <h4>Designation: CEO </h4>
                  <h4>Conpany: CompanyX </h4>
                </div>
              </div>
              <div className="slider-review">
                <div className="single-review">
                  <h5>Name: Alison Burger</h5>
                  <h4>Designation: CEO </h4>
                  <h4>Conpany: CompanyX </h4>
                </div>
              </div>
              <div className="slider-review">
                <div className="single-review">
                  <h5>Name: Alison Burger</h5>
                  <h4>Designation: CEO </h4>
                  <h4>Conpany: CompanyX </h4>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>

      <div className="cripton-vat-contact-div">
        <div className="cripton-vat-contact-texts">
          <h2>Contact us for Implementation & Support</h2>
          <p>
            First implementation and skilled support professional is ready to
            serve customer demands
          </p>
          <button className="vat-action-btm">Action</button>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default CriptonVatPro;
