import React, { useState, useEffect } from 'react';
import { Link, Navigate, useNavigate, Routes, Route } from "react-router-dom";

import Swal from 'sweetalert2';
import axios from 'axios';

function ForgotPassword() {

    const [successMessage,setSuccessMessage]=useState('')
    const navigate = useNavigate();
    const [state, setstate] = useState({
        email: '',
    })
    const handleChange = (e) => {
        setstate({
            ...state, [e.target.name]: e.target.value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        // setClickedRender(true);
        console.log('admin info check', state)

        // update('yes')

        // console.log('state checking', state)

        axios.get('sanctum/csrf-cookie').then(response => {

            axios.post('/api/admin-forget-password', state).then(res => {


                if (res.data.status == 200) {
                  setSuccessMessage(res.data.message)

                }
               
                else {
                    setSuccessMessage(res.data.message)
                    //   setClickedRender(false)

                }
            })

        });
    }

    return (
        <>

            <div class="container-scroller">
                <div class="container-fluid page-body-wrapper full-page-wrapper">
                    <div class="content-wrapper d-flex align-items-center auth px-0">
                        <div class="row w-100 mx-0">
                            <div class="col-lg-4 mx-auto">
                                <div class="auth-form-light text-left py-5 px-4 px-sm-5">
                                    <div class="brand-logo">
                                        <img src="../../images/logo.svg" alt="logo" />
                                    </div>
                                    <h6 class="font-weight-light">Email Or Phone.</h6>
                                    <form class="pt-3" onSubmit={handleSubmit}>
                                        <div class="form-group">
                                            <input type="text" class="form-control form-control-lg" id="exampleInputEmail1" placeholder="Email Or Phone" name='email' onChange={handleChange} />
                                        </div>
                                        <span className='text-danger py-0 my-0'>{successMessage} </span>

                                        <div class="mt-3">
                                            <button class="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn" type='submit' >RESET PASSWORD</button>
                                        </div>

                                        <div class="my-2 d-flex justify-content-between align-items-center">
                      
                                            <a href="#" class="auth-link text-black my-2">Forgot password?</a>
                                        </div>

                                        <div class="text-center mt-4 font-weight-light">
                                            Already have an account? <Link to="/admin-login"> <a href="register.html" class="text-primary">Login</a></Link>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default ForgotPassword