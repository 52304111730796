import React from "react";
import aboutImgBanner from "../../../Images/About-Us/about-banner.png";
import "./AboutBanner.css";
import { Parallax } from "react-parallax";

// const aboutBanner = {
//   background: `url(${aboutImgBanner})`,
//   width: "100%",
//   height: "70vh",
//   marginTop: 50,
//   backgroundRepeat: "no-repeat",
//   position: "relative",
//   backgroundSize: "cover",
//   overflowX: "hidden",
// };

const AboutBanner = () => {
  return (
    <div className="container-fluid">
      <div className="row">
        <Parallax bgImage={aboutImgBanner} bgImageAlt="" strength={300}>
          <div className=".col-xxl-12 .col-xl-12 .col-lg-12 .col-md-12 .col-sm-12 .col-12 about-banner-text-div container">
            <h1 className="text-h1">
              <span className="text-h1-span">Pakiza Technovation Limited</span>
              <br />a sister concern of Pakiza Group
            </h1>
            <p className="text-p">
              With a mission to deliver Excellence, through Innovation in
              designing solutions, implementing those in an innovative way using
              the right technologies. PTVL is the newest concern of renowned
              Pakiza Group, the standard of excellence in all its business
              sectors. PTVL always love to optimize, not to be lowest price, its
              solution to meet its client’s requirement & thus ensure value for
              money. Ensuring high quality in both product & service, PTVL wants
              to give its client’s a great experience & to be reliable and first
              choice of its kind.
            </p>

            <a className=" btn btn-md about-banner-btn" href="/RequestQuotation" target="_blank">Request for Quation</a>
          </div>
        </Parallax>
      </div>
      {/* <div style={aboutBanner}>
      <div className=".col-xxl-12 .col-xl-12 .col-lg-12 .col-md-12 .col-sm-12 .col-12 about-banner-text-div container">
        <h1 className="text-h1">Best ERP support with Top class product </h1>
        <p className="text-p">
          With a mission to deliver Excellence, through Innovation in designing
          solutions, implementing those in an innovative way <br /> using the
          right technologies. PTVL is the newest concern of renowned Pakiza
          Group,
          <br /> the standard of excellence in all its business sectors.
        </p>

        <button className="about-banner-btn">Request for Quation</button>
      </div>
      </div> */}
    </div>
  );
};

export default AboutBanner;
