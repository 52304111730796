import React, { useState, useEffect } from "react";
import bannerTop from "../../../Images/Product & Services/Software/CVP.png";
import "./CriptonVat.css";
import slideimg1 from "../../../Images/Product & Services/Software/cvp_1.png";
import slideimg2 from "../../../Images/Product & Services/Software/cvp_2.png";
import slideimg3 from "../../../Images/Product & Services/Software/cvp_3.png";
import slideimg4 from "../../../Images/Product & Services/Software/cvp_4.png";
import nvrApproved from "../../../Images/Product & Services/Software/nbrapproved.png";

import { faFileArrowDown, faPhone } from "@fortawesome/free-solid-svg-icons";

import Slider from "react-slick";
import criptonVatimg from "../../../Images/Product & Services/Software/criptonVatTagimg.png";
import Lottie from "lottie-react";
import NavigationBar from "../../Shared/NavigationBar/NavigationBar";
import Footer from "../../Shared/Footer/Footer";
import modified from "./modifiedCalculator.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CriptonVat = () => {
  const [isDownloading, setIsDownloading] = useState(false);
  const handleDownload = () => {
    setIsDownloading(true);
    const link = document.createElement("a");
    link.href = "/";
    link.download = "/";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setIsDownloading(false);
  };

  // Slick Slider

  var settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // Button
  const handleClick = () => {
    window.location.href = "tel:+8801713060441";
  };

  // Reload 0
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <NavigationBar />
      <section className="software-div-banner">
        <div>
          <img className="software-banner" src={bannerTop} alt="" />
        </div>
      </section>
      <section className="software-mobile-texts-div">
        <div className="software-mobile-texts">
          <div className="d-flex align-items-center text-center">
            <img src={criptonVatimg} alt="" />
            <h5 className="cripton-pro-mobile ms-3">
              <span className="cripton-Vat-moblie">CRIPTON VAT </span> PRO
            </h5>
          </div>
          <p className="softwares-p1 mt-3">
            NRB Enlisted dynamic & secure VAT Software
          </p>
          <p className="software-p2 ">
            Cripton VAT (Value-Added Tax) software is a computer program
            designed to help businesses calculate, manage and file VAT returns
            to the relevant tax authorities. The software automates the process
            of VAT calculation and helps businesses comply with the VAT
            regulations in their country. It can handle multiple VAT rates,
            manage invoices and receipts, and provide detailed reports to
            support VAT returns. Cripton VAT software can also integrate with
            accounting and bookkeeping systems to provide a comprehensive
            solution for VAT management. By using VAT software, businesses can
            save time, reduce errors and ensure they meet their VAT obligations
            in a timely manner <a href="/howWork">Read More </a>
          </p>
        </div>
      </section>
      <section className="software-texts-div">
        <div className="software-mobile-texts">
          <div className="d-flex align-items-center text-center">
            <img className="" src={criptonVatimg} alt="" />
            <h5 className="cripton-pro ms-4">
              <span className="cripton-Vat">CRIPTON VAT </span> PRO
            </h5>
          </div>
          <p className="softwares-p1">
            NRB Enlisted dynamic & secure VAT Software
          </p>
          <p className="software-p2">
            Cripton VAT (Value-Added Tax) software is a computer program
            designed to help businesses calculate, manage and file VAT returns
            to the relevant tax authorities. The software automates the process
            of VAT calculation and helps businesses comply with the VAT
            regulations in their country. It can handle multiple VAT rates,
            manage invoices and receipts, and provide detailed reports to
            support VAT returns. Cripton VAT software can also integrate with
            accounting and bookkeeping systems to provide a comprehensive
            solution for VAT management. By using VAT software, businesses can
            save time, reduce errors and ensure they meet their VAT obligations
            in a timely manner .
          </p>
        </div>
        <div className="soft-buttons">
          <button
            className="download-file-soft"
            Click={handleDownload}
            disabled={isDownloading}
          >
            {" "}
            <span className="me-2">
              <FontAwesomeIcon icon={faFileArrowDown} />
            </span>
            {isDownloading ? "Downloading..." : "Download Brochure"}
          </button>
          <button className=" ms-2 me-2 soft-button1" type="submit">
            Request a demo
          </button>
        </div>
      </section>

      <section className="container">
        <div className="soft-fe-icons-div">
          <h3 className="soft-fe-icons">Features</h3>
        </div>
        <div class="lottie-mobile-Software">
          <Lottie
            animationData={modified}
            loop={true}
            className="lottie-mobile-Software"
          />
        </div>
        <div className="row software-post-text-div">
          {/* <div className="col-lg-6 col-md-12 col-12 ">
            <img className="works-post-img" src={recentImg} alt="" />
          </div> */}

          <div className="software-middle-div col-lg-6 col-md-12 col-12">
            <h6 className="soft-crip-h6">VAT Act Compliant</h6>
            <p className="soft-crip-p">
              Cripton VAT Pro as an NBR-approved VAT software is 100% Compliant
              with the VAT & SD act 2012.
            </p>
            <h6 className="soft-crip-h6">NBR Prescribed Mushak Reports</h6>
            <p className="soft-crip-p">
              Cripton VAT PRO software can help businesses stay compliant with
              local VAT laws, by providing information on the VAT rate that
              applies to each sale, and automatically calculating the VAT owed.
              You Can generate each and every NBR prescribed Mushak report with
              a single click.
            </p>
            <h6 className="soft-crip-h6">Configurable Modules</h6>
            <p className="soft-crip-p">
              The configurable modules in this cripton VAT sofware will give you
              complete operational flexibility.
            </p>
            <h6 className="soft-crip-h6">Automated Taxation Process</h6>
            <p className="soft-crip-p">
              Cripton VAT PRO Management software keeps the VAT & Taxation
              process error-free and automated
            </p>
            <h6 className="soft-crip-h6">Alteration of Tax Rules</h6>
            <p className="soft-crip-p">
              Critpon VAT Pro will give you complete freedom to alter the rules
              as per your need.
            </p>
            <h6 className="soft-crip-h6">User Access Control</h6>
            <p className="soft-crip-p">
              You’re in total control over user access and can prevent any
              unauthorized entry.
            </p>

            <h6 className="soft-crip-h6">Multi-brunch VAT Management</h6>
            <p className="soft-crip-p">
              If you’ve more than one brunch, you can ensure VAT Management from
              one end with ease.
            </p>
            <h6 className="soft-crip-h6">Highly-Secured and Cloud-based</h6>
            <p className="soft-crip-p">
              Being hosted in Microsoft Azure made this VAT Management software
              a secured one.
            </p>
            <h6 className="soft-crip-h6">Detailed Reporting</h6>
            <p className="soft-crip-p">
              You’ve got all the reports related to VAT at your fingertips with
              total transparency.
            </p>
            <h6 className="soft-crip-h6">Time & Effort Saver</h6>
            <p className="soft-crip-p">
              This VAT Management software is all about minimizing time
              consumption and effort investment.
            </p>
            <h6 className="soft-crip-h6">Convenient Data Migration</h6>
            <p className="soft-crip-p">
              It makes performing data migration not only highly convenient but
              also less time-consuming.
            </p>
            <h6 className="soft-crip-h6">Automatic Data Backup System</h6>
            <p className="soft-crip-p">
              An automatic data backup backed by the restoration facility will
              keep all your data protected.
            </p>
          </div>

          <div class="col-lg-6 col-md-12 col-12">
            <Lottie
              animationData={modified}
              loop={true}
              className="lottie-Software"
            />
          </div>
        </div>
      </section>
      {/* modal1 */}
      <div
        class="modal fade"
        id="exampleModal1"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modalcontpreview">
          <div class="modal-content ">
            <div class="modal-header border-0 py-2">
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div
                className="sof-slide"
                onClick={() => console.log("clicked")}
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                <img
                  className="img img-fluid sof-slide"
                  src={slideimg1}
                  alt=""
                />
                <div className="inner-overlay-image">
                  <img src={nvrApproved} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* modal2 */}

      <div
        class="modal fade"
        id="exampleModal2"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modalcontpreview">
          <div class="modal-content ">
            <div class="modal-header border-0 py-2">
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div
                className="sof-slide"
                onClick={() => console.log("clicked")}
                data-bs-toggle="modal"
                data-bs-target="#exampleModal2"
              >
                <img
                  className="img img-fluid sof-slide"
                  src={slideimg2}
                  alt=""
                />
                <div className="inner-overlay-image">
                  <img src={nvrApproved} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* modal3 */}

      <div
        class="modal fade"
        id="exampleModal3"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modalcontpreview">
          <div class="modal-content ">
            <div class="modal-header border-0 py-2">
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div
                className="sof-slide"
                onClick={() => console.log("clicked")}
                data-bs-toggle="modal"
                data-bs-target="#exampleModal3"
              >
                <img
                  className="img img-fluid sof-slide"
                  src={slideimg3}
                  alt=""
                />
                <div className="inner-overlay-image">
                  <img src={nvrApproved} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* modal4 */}

      <div
        class="modal fade"
        id="exampleModal4"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modalcontpreview">
          <div class="modal-content ">
            <div class="modal-header border-0 py-2">
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div
                className="sof-slide"
                onClick={() => console.log("clicked")}
                data-bs-toggle="modal"
                data-bs-target="#exampleModal4"
              >
                <img
                  className="img img-fluid sof-slide"
                  src={slideimg4}
                  alt=""
                />
                <div className="inner-overlay-image">
                  <img src={nvrApproved} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="mt-5" style={{ cursor: "pointer" }}>
        <Slider {...settings}>
          <div
            className="sof-slide"
            onClick={() => {}}
            data-bs-toggle="modal"
            data-bs-target="#exampleModal1"
          >
            <img className="img img-fluid sof-slide" src={slideimg1} alt="" />
            <div className="inner-overlay-image">
              <img src={nvrApproved} />
            </div>
          </div>

          <div className="sof-slide">
            <img
              className="img img-fluid sof-slide"
              src={slideimg2}
              alt=""
              data-bs-toggle="modal"
              data-bs-target="#exampleModal2"
            />
            <div className="inner-overlay-image">
              <img src={nvrApproved} />
            </div>
          </div>
          <div className="sof-slide">
            <img
              className="img img-fluid sof-slide"
              src={slideimg3}
              alt=""
              data-bs-toggle="modal"
              data-bs-target="#exampleModal3"
            />
            <div className="inner-overlay-image">
              <img src={nvrApproved} />
            </div>
          </div>
          <div className="sof-slide">
            <img
              className="img img-fluid sof-slide"
              src={slideimg4}
              alt=""
              data-bs-toggle="modal"
              data-bs-target="#exampleModal4"
            />
            <div className="inner-overlay-image">
              <img src={nvrApproved} />
            </div>
          </div>
        </Slider>
      </section>
      <section className="container button-vat-call-div">
        <button onClick={handleClick} className="button-vat-call">
          <span className="me-3">
            <FontAwesomeIcon icon={faPhone} />
          </span>
          CALL FOR APPOINMENT AND DEMO
        </button>
      </section>
      <Footer />
    </div>
  );
};

export default CriptonVat;

// import React from "react";
// import Footer from "../../Shared/Footer/Footer";
// import NavigationBar from "../../Shared/NavigationBar/NavigationBar";

// const SoftwareSolution = () => {
//   return (
//     <div>
//       <NavigationBar />
//       <div>
//         <h1>Hello</h1>
//       </div>
//       <Footer />
//     </div>
//   );
// };

// export default SoftwareSolution;
