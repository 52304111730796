import React from "react";
import "./Services.css";
import Hardware from "../../../Images/Services/Hardware solution.gif";
import Software from "../../../Images/Services/Software solution.gif";
import Data from "../../../Images/Services/Data center Solution.gif";
import Managed from "../../../Images/Services/Managed Services.gif";
import Security from "../../../Images/Services/Security & Surveillance.gif";
import ERP from "../../../Images/Services/Customize ERP Solutions.gif";
import Service from "./Service";

const Services = () => {
  const services = [
    {
      _id: 1,
      name: "Hardware solution",
      description:
        "Hardware solution is a physical device or system that is designed to solve a specific problem or meet a specific need. Hardware solutions can include a wide range of devices.",
      img: Hardware,
      URL: "./hardwaresolution",
      backgroundColor: "#FCA100",
    },
    {
      _id: 2,
      name: "Software solution",
      description:
        "PTVL software solution is a program or set of programs that are designed to solve a specific problem. Including desktop computers, mobile devices, and the web.",
      img: Software,
      URL: "./softwareSolution",
    },
    {
      _id: 3,
      name: "Data center Solution",
      description:
        "Stay ahead of envolving threats with our network and infrastructure security service, while maximizing your budget andscale based on your business needs",
      img: Data,
      URL: "./dataCenter",
    },
    {
      _id: 4,
      name: "Managed Services",
      description:
        "PTVL focuses on customers need and always suggest the best and costeffective sizing of servers and storage form its range of servers and storage which is customer-inspired, future-ready technology.",
      img: Managed,
      URL: "./manangedServices",
    },
    {
      _id: 5,
      name: "Security & Surveillance",
      description:
        "Its all about premise security where you want to emphasize and we ensure it. We take care of every element in the premise starting from pathways to indoor, electrical to mechinaries.",
      img: Security,
      URL: "./securitySarvillance",
    },
    {
      _id: 6,
      name: "Customize Solutions",
      description:
        "Irrespective of size and nature of business, PTVL has solutions to automate its processes for all. We go deep into each business process to analyze and automate the process.",
      img: ERP,
      URL: "./customizeSolution",
    },
  ];

  return (
    <div className="container container-fluid">
      <div className="services-div">
        <container>
          <h1 className="services-h1"> Services</h1>
          <hr className="half_hr" />
          <div className="serevices-map container">
            <div className="row">
              {services.map((service) => (
                <Service key={service._id} service={service}></Service>
              ))}
            </div>
          </div>
        </container>
      </div>
    </div>
  );
};

export default Services;
