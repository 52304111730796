import React, { useState, useEffect, useRef } from 'react';
import { Link, Navigate, useNavigate, Routes, Route } from "react-router-dom";
import axios from 'axios';
import Swal from 'sweetalert2';
import MasterDashboardLayout from '../../AdminPanel/MasterDashboardLayout'

import { DataGrid } from '@mui/x-data-grid';

function ViewOurTeam() {
    const navigate = useNavigate();
    const [renderData, setRenderData] = useState('')
    const columns = [
        { field: 'id', headerName: 'ID', width: 70, padding: 50 },
        { field: 'name', headerName: 'Short bio', flex:1,width: 100 },
        { field: 'designation', headerName: 'Client message',flex:1, width: 130 },
        { field: 'email_link', headerName: 'Client name', flex:1,width: 130 },
        { field: 'linkedin_link', headerName: 'Compnay name', flex:1,width: 130 },

        {
            field: 'client_image',
            headerName: 'Image',
            sortable: false,
            width: 160,
            renderCell: (params) => <img className="rounded " src={`${global.img_url}/images/${params.row.image}`} width="100px" height="70px" alt="No Image" />,
            // renderCell: (params) => console.log('baaal',params.row)
        },
        {
            headerName: 'Action',
            renderCell: (params) =>
                <div class="fs-6" style={{ cursor: 'pointer' }}>
                    <Link to={`/edit-our-team/${params.id}`}><i class="fa text-warning fa-edit"></i></Link>
                    <i class="fa text-danger fa-trash mx-2" onClick={(e) => deleteOurTeam(e, params.id)}></i>
                </div>
        },
    ];

    const [allTeam, setAllTeam] = useState([]);
    console.log('all testi', allTeam)
    useEffect(() => {
        axios.get(`/api/all-our-team`).then(res => {
            if (res.data.status == 200) {
                setAllTeam(res.data.all_team);
                // setLoading(false);

            }
        })
    }, [renderData])

    //delete functionality start 

    const deleteOurTeam = (e, id) => {
        e.preventDefault();
        const thisClicked = e.currentTarget;
        //  thisClicked.innerText = "Deleting";

        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post(`/api/delete-our-team/${id}`).then(res => {
                    if (res.data.status === 200) {
                        setRenderData(res.data)
                        thisClicked.closest("tr").remove();

                        //   swal("Success", res.data.message, "success");
                    }
                });
                Swal.fire(
                    'Deleted!',
                    'Your data has been deleted.',
                    'success'
                )
            }
        })

    }

    

    return (
        <MasterDashboardLayout>
            <div class="col-12 grid-margin stretch-card">
                <div class="card">
                    <div class="card-body">
                        <div className='d-flex justify-content-between my-2'>
                            <h4 class="card-title">View Our Team</h4>
                            <Link to="/add-our-team"><button type='button' className='btn btn-warning btn-sm fw-bold rounded-pill'>Create</button></Link>
                        </div>

                        <div style={{ height: '70vh', width: '100%' }}>
                            <DataGrid
                                rows={allTeam}
                                columns={columns}
                                pageSize={5}
                                rowHeight={100}
                                rowsPerPageOptions={[5]}
                            // checkboxSelection
                            />
                        </div>
                    </div>
                </div>
            </div>
        </MasterDashboardLayout>
    )
}

export default ViewOurTeam