import {
  faBriefcase,
  faEnvelope,
  faLocationDot,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import contactBannerImg from "../../../Images/Contact-Us/contact-banner-1.png";
import Footer from "../../Shared/Footer/Footer";
import NavigationBar from "../../Shared/NavigationBar/NavigationBar";
//Lazy load
//import LazyLoad from 'react-lazy-load';
import "./Contact.css";
import ScrollTopButton from "../../ScrollTopButton/ScrollTopButton";

const contactbannerbg = {
  background: `url(${contactBannerImg})`,
  backgroundRepeat: "no-repeat",
  position: "relative",
  backgroundSize: "cover",
  overFlowX: "hiden",
  position: "relative",
};

const Contact = () => {
  // Location
  // const [map, setMap] = useState(null);

  // useEffect(() => {
  //   if (!map) {
  //     const mapContainer = document.createElement("div");
  //     mapContainer.style.width = "100%";
  //     mapContainer.style.height = "400px";

  //     const latitude = 37.7749;
  //     const longitude = -122.4194;
  //     const center = { lat: latitude, lng: longitude };

  //     const mapOptions = {
  //       center: center,
  //       zoom: 12,
  //     };

  //     const googleMap = new window.google.maps.Map(mapContainer, mapOptions);
  //     setMap(googleMap);
  //   }
  // }, [map]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <NavigationBar />
      <div className="container-fluid">
        <div className="row">
          <div className="contact-header-text-div" style={contactbannerbg}>
            <p className="container contact-p mt-5">
              We love to talk with you about our products & more ...
            </p>
            <h1 className="container contact-h1">Contact us</h1>
          </div>

          <div className="contacts">
            <section className="container">
              <div className="row address-visit">
                <div className="col-lg-6 col-md-6 col-sm-12 col-12 contact-input">
                  <input
                    className="blogs-input"
                    type="name"
                    placeholder="Name"
                  />{" "}
                  <br />
                  <input
                    className="blogs-input"
                    type="email"
                    placeholder="Email"
                  />
                  <br />
                  <input
                    className="blogs-input"
                    type="number"
                    placeholder="Phone"
                  />
                  <br />
                  <input
                    className="blogs-input-massage blogs-input"
                    type="text"
                    placeholder="Message"
                  />{" "}
                  <br />
                  <button className="blogs-button" type="submit">
                    Submit
                  </button>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12 col-12 contact-address">
                  <h6 className="contact-h6">Contact</h6>
                  <h2 className="contact-h2">Contact for Visit US</h2>
                  <div className="d-flex align-items-center text-align-center">
                    <FontAwesomeIcon
                      className="contact-icon"
                      icon={faLocationDot}
                    />
                    <p className="contact-p2">
                      Head office : House# 97, Road# 11/A,
                      <br /> Dhanmondi, Dhaka-1209, Bangladesh
                    </p>
                  </div>
                  <div className="d-flex align-items-center text-align-center">
                    <FontAwesomeIcon className="contact-icon" icon={faPhone} />
                    <p className="contact-p2">+880 9612 811 111</p>
                  </div>
                  <div className="d-flex align-items-center text-align-center">
                    <FontAwesomeIcon
                      className="contact-icon"
                      icon={faBriefcase}
                    />
                    <p className="contact-p2">+880 9612 811 111</p>
                  </div>
                  <div className="d-flex align-items-center text-align-center">
                    <FontAwesomeIcon
                      className="contact-icon"
                      icon={faEnvelope}
                    />
                    <p className="contact-p2">
                      {" "}
                      <a href="mailto:info@pakizatvl.com">info@pakizatvl.com</a>
                    </p>
                  </div>
                </div>
              </div>
            </section>
            <section></section>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <br />
            <div>
              <iframe
                width="100%"
                height="300"
                frameborder="0"
                scrolling="no"
                src="https://maps.google.com/maps?width=100%25&amp;height=300&amp;hl=en&amp;q=11/A%20Rd%20Number%2011A+(Pakiza%20Technovation%20Ltd)&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
              >
                <a href="https://www.maps.ie/distance-area-calculator.html">
                  measure acres/hectares on map
                </a>
              </iframe>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <ScrollTopButton />
    </div>
  );
};

export default Contact;
