import React, { useState, useEffect, Component } from "react";
import Footer from "../../Shared/Footer/Footer";
import NavigationBar from "../../Shared/NavigationBar/NavigationBar";
import dataBG from "../../../Images/Product & Services/DataS/dataBannerBg.png";
import commonScope from "../../../Images/Product & Services/DataS/commonScope.png";
import commonscope2 from "../../../Images/Product & Services/DataS/comms1.png";
import commonScop3 from "../../../Images/Product & Services/DataS/comms3.png";
import rosenberger2 from "../../../Images/Product & Services/DataS/rossenberger2.jpg";
import rosenberger3 from "../../../Images/Product & Services/DataS/rosenberger3.jfif";
import rosenberger4 from "../../../Images/Product & Services/DataS/rossenberger3.jpg";
import rosenberger from "../../../Images/Product & Services/DataS/rosenberger.png";
import dataBox from "../../../Images/Product & Services/DataS/databox.png";
import dataBox2 from "../../../Images/Product & Services/DataS/databox2.png";
import dataLogo from "../../../Images/Product & Services/DataS/topLogo.png";
import hardSolu from "../../../Images/Product & Services/DataS/Hardware-solutions.png";
import manageSer from "../../../Images/Product & Services/DataS/manage-service.png";
import dcLowerBg from "../../../Images/Product & Services/DataS/DCLowerPage.png";
import securityServillience from "../../../Images/Product & Services/DataS/security and servillience.png";
import "./DataCenter.css";
import { faFileArrowDown, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Slider from "react-slick";
import ScrollTopButton from "../../ScrollTopButton/ScrollTopButton";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const DataCenter = () => {
  const [isDownloading, setIsDownloading] = useState(false);
  const handleDownload = () => {
    setIsDownloading(true);
    const link = document.createElement("a");
    link.href = "/";
    link.download = "/";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setIsDownloading(false);
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // react slick
  const settings = {
    dots: true,
    infinite: true,

    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
  };
  // Reload 0
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="main_product_page data_center">
      <section>
        <NavigationBar />
      </section>

      <div className="data-center-wrapper">
        <img src={dataBG} />

        <div className="data-mobile-texts-div  ">
          <div className="data-mobile-texts">
            <div className="d-flex align-items-center text-center">
              <img src={dataLogo} alt="" />
              <h5 className="data-head-tag ms-2">Data center Infra Solution</h5>
            </div>
            <p className="data-p1">
              A Comprehensive architectural solution for growing{" "}
              <span className="data-p1-span">BUSINESS</span>
            </p>
            <p className="data-p2">
              A data center infrastructure solution typically refers to the
              hardware and software components that make up a data center,
              including servers, storage systems, network equipment, backup and
              disaster recovery systems, and various management tools. The goal
              of a data center infrastructure solution is to provide a secure,
              scalable, and reliable platform for storing, processing, and
              managing large amounts of data and applications. A comprehensive
              data center infrastructure solution may also include features such
              as virtualization, cloud computing, and automation to maximize
              efficiency and reduce costs.
            </p>
          </div>
        </div>
      </div>
      <div className="container offer-header">
        <h4 className="data-fe-icons  ">Solutions We Cover</h4>
      </div>

      <div className="container">
        {/* <div className="data-fe-icons-div  ">
          <h4 className="data-fe-icons">Solutions We Cover</h4>
        </div> */}
        {/* <!-- Solution we cover-----------------> */}

        <div className="commscop">
          <div className="row ">
            <h3>COMMSCOPE</h3>
            <div className="col-3 p-3 d-flex align-items-center justify-content-center">
              <img className="img img-fluid" src={commonScope} alt="" />
            </div>
            <div className="col-9">
              {" "}
              <p>
                CommScope Inc. is an American global network infrastructure
                provider company.
                <br /> “SYSTIMAX” is one of the world's leading networking
                solutions portfolio under CommScope.
              </p>
            </div>
          </div>
        </div>
        <div className="rosenberger">
          <div className="row ">
            <h3>ROSENBERGER</h3>
            <div className="col-3 p-3 d-flex align-items-center justify-content-center">
              <img className="img img-fluid" src={rosenberger} alt="" />
            </div>
            <div className="col-9">
              {" "}
              <p>
                Rosenberger designs and manufactures connectors that are
                engineered to reliably transmit data and power in numerous types
                of applications with various requirements. <br />
              </p>
            </div>
          </div>
        </div>
        <div className="pac">
          <div className="row ">
            <h3>Precision air conditioner(PAC)</h3>
            <div className="col-3 p-3 d-flex align-items-center justify-content-center">
              <img
                className="img img-fluid"
                src="https://res.cloudinary.com/dendlnj8l/image/upload/v1677064305/PTVL/pac_3_lxuk85.jpg"
                alt=""
              />
            </div>
            <div className="col-9">
              {" "}
              <p>
                The precision air conditioners are refrigerating equipment
                specifically designed to provide precise control of temperature
                and humidity in all applications in which is required a very
                high degree of precision
              </p>
            </div>
          </div>
        </div>
        <div className="ems">
          <div className="row ">
            <h3>Envoirnment management soltuion(EMS)</h3>
            <div className="col-3 p-3 d-flex align-items-center justify-content-center">
              <img
                className="img img-fluid"
                src="https://res.cloudinary.com/dendlnj8l/image/upload/v1677064305/PTVL/ems_1_pr17ff.jpg"
                alt=""
              />
            </div>
            <div className="col-9">
              {" "}
              <p>
                In a data center, there are many pieces of hardware and
                equipment that make up the moving parts necessary to protect
                critical information.
              </p>
              <ul>
                <li>Sensors Types</li>
                <li>Temperature detection </li>
                <li>Humidity detection </li>
                <li>Liquid detection</li>
                <li>Vibration detection</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="oups">
          <div className="row ">
            <h3>Online UPS</h3>
            <div className="col-3 p-3 d-flex align-items-center justify-content-center">
              <img
                className="img img-fluid"
                src="https://res.cloudinary.com/dendlnj8l/image/upload/v1677064306/PTVL/ups_1_exnumm.jpg"
                alt=""
              />
            </div>
            <div className="col-9">
              <p>
                On-line UPS is its ability to provide an "electrical firewall"
                between the incoming utility power and sensitive electronic
                equipment. The online UPS is ideal for environments where
                electrical isolation is necessary or for equipment that is very
                sensitive to power fluctuations. <br /> “SYSTIMAX” is one of the
                world's leading networking
              </p>
              <ul>
                <li>Total IT Infrastructure Solution (Copper & Fiber)</li>
                <li>Modular Data Center Solution (MDC).</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="fire">
          <div className="row ">
            <h3>Fire protection</h3>
            <div className="col-3 p-3 d-flex align-items-center justify-content-center">
              <img
                className="img img-fluid"
                src="https://res.cloudinary.com/dendlnj8l/image/upload/v1677064305/PTVL/fire2_xwrtxu.gif"
                alt=""
              />
            </div>
            <div className="col-9">
              <p>
                There are numerous fire risks present in data centers, large and
                small. Fires can occur within the digital equipment, wires,
                cables, HVAC equipment, raised floors, suspended ceilings, and
                other combustibles found in data centers
              </p>
            </div>
          </div>
        </div>
      </div>
      <hr className="half-hr" />

      <section className="container mb-5 discover-div">
        <div>
          <h4 className="disco-h4">Also discover</h4>
        </div>
        <div className="row requ-div">
          <div className="col-lg-4 col-md-4 col-sm-6 col-12">
            <a href="./hardwaresolution" className="icons-BC mb-4">
              <img className="icons-com" src={hardSolu} alt="" />
              <div className="icons-text">
                <h6 className="icons-h6">Hardware solution</h6>
                <p className="text-com-p">
                  HP, HPE, CISCO, JUNIPER, F5, FORTINET, SHOPHOS
                </p>
              </div>
            </a>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-6 col-12">
            <a href="./manangedServices" className="icons-BC">
              <img className="icons-com" src={manageSer} alt="" />
              <div className="icons-text">
                <h6 className="icons-h6">Manged Service</h6>
                <p className="text-com-p">
                  BreakFix, Monitoring, Help desk, Resource outsourcing
                </p>
              </div>
            </a>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-6 col-12">
            <a href="./securitySarvillance" className="icons-BC">
              <img className="icons-com" src={securityServillience} alt="" />
              <div className="icons-text">
                <h6 className="icons-h6">Security & Surveillance</h6>
                <p className="text-com-p">
                  HikVision, ZKTEco, Grrrett, Suprema, P A System
                </p>
              </div>
            </a>
          </div>
        </div>
      </section>
      <section>
        <div className="data-inpu-text-div">
          <h3 className="data-inpu-text-h3">Request a Quote</h3>
          <p className="data-inpu-text-p">
            Confuse about product or project budget? Tell us more about your
            idea. Our expert team will evaluate your idea(s) and provide you a
            guideline
          </p>
        </div>
        <div>
          <img className="data-lower-back-img" src={dcLowerBg} alt="" />
        </div>
        <div className="data-lower-part ">
          <div className="data-lower-full-input-div container">
            <div className="row g-2">
              <div className="col-md ">
                <div className="form-floating">
                  <input
                    type="name"
                    class="form-control"
                    id="floatingInputGrid"
                  />
                  <label for="floatingInputGrid">Your Name</label>
                </div>
              </div>
              <div className="col-md">
                <div className="form-floating">
                  <input
                    type="email"
                    className="form-control"
                    id="floatingInputGrid"
                  />
                  <label for="floatingInputGrid">Email</label>
                </div>
              </div>
            </div>
            <div className="row g-2 mt-2">
              <div className="col-md">
                <div className="form-floating">
                  <input
                    type="number"
                    className="form-control"
                    id="floatingInputGrid"
                  />
                  <label for="floatingInputGrid">Contact No</label>
                </div>
              </div>
              <div className="col-md">
                <div className="form-floating">
                  <input
                    type="text"
                    className="form-control"
                    id="floatingInputGrid"
                  />
                  <label for="floatingSelectGrid">Company</label>
                </div>
              </div>
            </div>
            <div className="row g-2 mt-2">
              <div className="col-md">
                <div className="form-floating">
                  <input
                    type="text"
                    className="form-control"
                    id="floatingInputGrid"
                  />
                  <label for="floatingSelectGrid">What You Need ?</label>
                </div>
              </div>
              <div className="col-md">
                <div className="form-floating">
                  <input
                    type="email"
                    class="form-control"
                    id="floatingInputGrid"
                  />
                  <label for="floatingInputGrid">Reference Sources</label>
                </div>
              </div>
            </div>
            <div className="form-floating mt-3">
              <textarea
                className="form-control hard-texT-area"
                placeholder="Leave a comment here"
                id="floatingTextarea"
              ></textarea>
              <label for="floatingTextarea">Your Queries</label>
            </div>
            <div>
              <button className="data-submit-button" type="submit">
                Submit Your Request
              </button>
            </div>
          </div>
        </div>
      </section>
      <section>
        <Footer />
      </section>
      <ScrollTopButton />
    </div>
  );
};

export default DataCenter;
