import React, { useEffect } from "react";
import { Parallax } from "react-parallax";
import teamBanner from "../../../Images/Team/teamBanner.png";
import Footer from "../../Shared/Footer/Footer";
import NavigationBar from "../../Shared/NavigationBar/NavigationBar";
import Teaservices from "../TeamServices/Teaservices";
import "./Team.css";

// const teamsbg = {
//   background: `url(${teamBanner})`,
//   backgroundRepeat: "no-repeat",
//   position: "relative",
//   backgroundSize: "cover",
//   overflowX: "hiden",
//   margin: 0,
// };

const Team = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <NavigationBar />

      <Parallax
        blur={10}
        bgImage={teamBanner}
        bgImageAlt="background-image"
        strength={200}
      >
        <div className="team-header-texts">
          <h6 className="container team-header-h6">
            Why we become largest in IT industry in Bangladesh
          </h6>
          <h3 className="container team-header-h3">Our Team</h3>
        </div>
      </Parallax>

      <div>
        <Teaservices></Teaservices>\
      </div>
      <Footer />
    </div>
  );
};

export default Team;
