import React, { Component } from "react";
import Slider from "react-slick";
import "./ReviewCustomers.css";
import img1 from "../../../Images/ReviewCustomer/review-1.png";
import img2 from "../../../Images/ReviewCustomer/review-2.png";
import img3 from "../../../Images/ReviewCustomer/review-3.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

export default class ReviewSlider extends Component {
  render() {
    const settings = {
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      speed: 2000,
      autoplaySpeed: 2000,
      cssEase: "linear",
      responsive: [
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <div>
        <Slider {...settings}>
          <div className="review-mobile-div">
            <div className="review-mobile-img-text">
              <img className="review-mobile-img" src={img1} alt="" />
              <p className="review-mobile-p">
                Interface Solutions Limited, as business integrators, reviewed
                my company processes and helped resolve complex business
                difficulties using technology.
              </p>
            </div>
            <div className="review-mobile-icon">
              <span>
                <FontAwesomeIcon icon={faStar} style={{ margin: 7 }} />
                <FontAwesomeIcon icon={faStar} style={{ margin: 7 }} />
                <FontAwesomeIcon icon={faStar} style={{ margin: 7 }} />
                <FontAwesomeIcon icon={faStar} style={{ margin: 7 }} />
              </span>
              <h6 className="review-mobile-h6">Shalima Hayden</h6>
              <p className="review-mobile-p2">Product Designer</p>
            </div>
          </div>
          <div className="review-mobile-div">
            <div className="review-mobile-img-text">
              <img className="review-mobile-img" src={img2} alt="" />
              <p className="review-mobile-p">
                Interface Solutions Limited, as business integrators, reviewed
                my company processes and helped resolve complex business
                difficulties using technology.
              </p>
            </div>
            <div className="review-mobile-icon">
              <span>
                <FontAwesomeIcon icon={faStar} style={{ margin: 7 }} />
                <FontAwesomeIcon icon={faStar} style={{ margin: 7 }} />
                <FontAwesomeIcon icon={faStar} style={{ margin: 7 }} />
                <FontAwesomeIcon icon={faStar} style={{ margin: 7 }} />
              </span>
              <h6 className="review-mobile-h6">Clay Johnson</h6>
              <p className="review-mobile-p2">Business Director</p>
            </div>
          </div>
          <div className="review-mobile-div">
            <div className="review-mobile-img-text">
              <img className="review-mobile-img" src={img3} alt="" />
              <p className="review-mobile-p">
                Interface Solutions Limited, as business integrators, reviewed
                my company processes and helped resolve complex business
                difficulties using technology.
              </p>
            </div>
            <div className="review-mobile-icon">
              <span>
                <FontAwesomeIcon icon={faStar} style={{ marginRight: 7 }} />
                <FontAwesomeIcon icon={faStar} style={{ marginRight: 7 }} />
                <FontAwesomeIcon icon={faStar} style={{ marginRight: 7 }} />
                <FontAwesomeIcon icon={faStar} style={{ marginRight: 7 }} />
              </span>
              <h6 className="review-mobile-h6">Shalima Hayden</h6>
              <p className="review-mobile-p2">Product Designer</p>
            </div>
          </div>
        </Slider>
      </div>
    );
  }
}
