import React, { useState, useEffect, useRef } from 'react';
import { Link, Navigate, useNavigate, Routes, Route, useParams } from "react-router-dom";
import axios from 'axios';
import Swal from 'sweetalert2';
import MasterDashboardLayout from '../../AdminPanel/MasterDashboardLayout'


function EditTestimonial() {
    const navigate = useNavigate();
    let { id } = useParams();

    // const [EditTestimonialData, setEditTestimonialData] = useState({
    //     testimonial_short_bio:"",
    //     client_message:"",
    //     rate:'',
    //     client_name: '',
    //     client_designation: "",
    //     client_company_name: '',

    // })

    const handleInputChange = (e) => {
        setEditTestimonialData({
            ...EditTestimonialData, [e.target.name]: e.target.value
        })
    }

    const [image, setImage] = useState('');
    console.log('image info', image)
    const [picture, setPicture] = useState('');

    const onChangePicture = e => {
        console.log('picture: ', picture);
        setPicture(URL.createObjectURL(e.target.files[0]));
        setImage(e.target.files[0]);
    };


    const handleUpdate = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('client_name', EditTestimonialData.client_name);
        formData.append('client_company_name', EditTestimonialData.client_company_name);
        formData.append('client_designation', EditTestimonialData.client_designation);
        formData.append('client_message', EditTestimonialData.client_message);
        formData.append('testimonial_short_bio', EditTestimonialData.testimonial_short_bio);
        formData.append('rate', EditTestimonialData.rate);

        formData.append('client_image', image);


        console.log('check all data', formData);



        axios.post(`/api/update-testimonial/${id}`, formData, {
            headers: { "Content-Type": "multipart/form-data" }
        }).then(res => {
            if (res.data.status == 200) {
                Swal.fire(res.data.message, '', 'success')
                navigate('/view-testimonial')
                setEditTestimonialData({
                    client_company_name: '',
                    client_message: '',
                    client_name: '',
                    rate: '',
                    testimonial_short_bio: "",
                    client_designation: ""
                });

                setImage('');
                setPicture('');
                document.getElementById('client_image').value = "";
            }
            // else if (res.data.status == 400) {
            //     setjobDesc({ ...jobDesc, error_list: res.data.errors });
            //     Swal.fire(jobDesc.error_list.job_id[0], '', 'error')

            // }
        })

    }
    const [EditTestimonialData, setEditTestimonialData] = useState('');
    useEffect(() => {
        axios.get(`/api/edit-testimonial/${id}`).then(res => {
            if (res.data.status == 200) {
                setEditTestimonialData(res.data.testimonial);
                // setLoading(false);

            }
        })
    }, [])
    return (
        <MasterDashboardLayout>
            <div class="col-12 grid-margin stretch-card">
                <div class="card">
                    <div class="card-body">
                        <div className='d-flex justify-content-between my-2'>
                        <h4 class="card-title">Edit Testimonial</h4>
                        <Link to="/view-testimonial"><button type='button' className='btn btn-warning btn-sm fw-bold rounded-pill'>Back</button></Link>
                        </div>

                        <form class="forms-sample" onSubmit={handleUpdate} >
                            <div class="form-group">
                                <label for="exampleTextarea1">Testimonial short bio</label>
                                <textarea class="form-control" id="exampleTextarea1" rows="4" onChange={handleInputChange} name="testimonial_short_bio" value={EditTestimonialData.testimonial_short_bio} ></textarea>
                            </div>
                            <div class="form-group">
                                <label for="exampleTextarea1">Client Testimonial</label>
                                <textarea class="form-control" id="exampleTextarea1" rows="4" name="client_message" onChange={handleInputChange} value={EditTestimonialData.client_message} ></textarea>
                            </div>


                            <div class="form-group">
                                <label for="exampleSelectGender">Rate</label>
                                <select class="form-control" id="exampleSelectGender" onChange={handleInputChange} name="rate" value={EditTestimonialData.rate}>
                                    <option disabled selected>Choose</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                </select>
                            </div>
                            <div class="form-group">
                                <label for="exampleInputName1">Client Name</label>
                                <input type="text" class="form-control" id="exampleInputName1" placeholder="Name" onChange={handleInputChange} name="client_name" value={EditTestimonialData.client_name} />
                            </div>
                            <div class="form-group">
                                <label for="exampleInputName1">Client's Company Name</label>
                                <input type="text" class="form-control" id="exampleInputName1" placeholder="Name" onChange={handleInputChange} name="client_company_name" value={EditTestimonialData.client_company_name} />
                            </div>
                            <div class="form-group">
                                <label for="exampleInputName1">Client's Company Designation</label>
                                <input type="text" class="form-control" id="exampleInputName1" placeholder="Name" onChange={handleInputChange} name="client_designation" value={EditTestimonialData.client_designation} />
                            </div>

                            <div class="form-group">
                                <label>Client Image</label>
                                <div class="input-group col-xs-12">
                                    <input type="file" class="form-control file-upload-info" placeholder="Upload Image" id="client_image" name="client_image" onChange={onChangePicture} />
                                    {/* <span class="input-group-append">
                                        <button class="file-upload-browse btn btn-primary" type="button">Upload</button>
                                    </span> */}
                                </div>
                            </div>
                            <div className="form-group " style={{ width: '100px', height: '90px' }}>
                                <img className="playerProfilePic_home_tile" src={`${global.img_url}/images/${EditTestimonialData.client_image}`} style={{ width: '100px', height: '90px' }}></img>
                            </div>

                            <button type="submit" class="btn btn-primary mr-2" onSubmit={handleUpdate}>Update</button>
                        </form>
                    </div>
                </div>
            </div>
        </MasterDashboardLayout>
    )
}

export default EditTestimonial