import React from "react";
import Footer from "../Footer/Footer";
import NavigationBar from "../NavigationBar/NavigationBar";
import "./RequestQuotation.css";
import { Parallax } from "react-parallax";
import quatationBg from "../../../Images/buttons-bg/ButtonBG.png";

const RequestQuotation = () => {
  return (
    <div>
      <section>
        <NavigationBar />
      </section>

      <section className="parallax-qurties">
        <Parallax
          bgImage={quatationBg}
          bgImageAlt="background-image"
          strength={50}
        >
          <div className="quarties-header-texts ">
            <h6 className="container team-header-h6">REQUEST FOR</h6>
            <h3 className="container team-header-h3">Quotation</h3>
          </div>
        </Parallax>
      </section>

      <section className="quarties-full-div">
        <div className="ques-inpu-text-div">
          <h3 className="ques-inpu-text-h3">Request a Quote</h3>
          <p className="ques-inpu-text-p">
            Confuse about product or project budget? Tell us more about your
            idea. <br />
            Our expert team will evaluate your idea(s) and provide you a
            guideline
          </p>
        </div>
        <div className="ques-lower-part ">
          <div className="ques-lower-full-input-div container">
            <div className="row g-2">
              <div className="col-md">
                <div className="form-floating">
                  <input
                    type="name"
                    class="form-control"
                    id="floatingInputGrid"
                  />
                  <label for="floatingInputGrid">Your Name</label>
                </div>
              </div>
              <div className="col-md">
                <div className="form-floating">
                  <input
                    type="email"
                    className="form-control"
                    id="floatingInputGrid"
                  />
                  <label for="floatingInputGrid">Email</label>
                </div>
              </div>
            </div>
            <div className="row g-2 mt-2">
              <div className="col-md">
                <div className="form-floating">
                  <input
                    type="number"
                    className="form-control"
                    id="floatingInputGrid"
                  />
                  <label for="floatingInputGrid">Contact No</label>
                </div>
              </div>
              <div className="col-md">
                <div className="form-floating">
                  <input
                    type="text"
                    className="form-control"
                    id="floatingInputGrid"
                  />
                  <label for="floatingSelectGrid">Company</label>
                </div>
              </div>
            </div>
            <div className="row g-2 mt-2">
              <div className="col-md">
                <div className="form-floating">
                  <input
                    type="text"
                    className="form-control"
                    id="floatingInputGrid"
                  />
                  <label for="floatingSelectGrid">What You Need ?</label>
                </div>
              </div>
              <div className="col-md">
                <div className="form-floating">
                  <input
                    type="email"
                    class="form-control"
                    id="floatingInputGrid"
                  />
                  <label for="floatingInputGrid">Reference Sources</label>
                </div>
              </div>
            </div>
            <div className="form-floating mt-3">
              <textarea
                className="form-control hard-texT-area"
                placeholder="Leave a comment here"
                id="floatingTextarea"
              ></textarea>
              <label for="floatingTextarea">Your Queries</label>
            </div>
            <div>
              <button className="ques-submit-button" type="submit">
                Submit Your Request
              </button>
            </div>
          </div>
        </div>
      </section>
      <section>
        <Footer />
      </section>
    </div>
  );
};

export default RequestQuotation;
