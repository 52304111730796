import React, { useState, useEffect, useRef } from 'react';
import { Link, Navigate, useNavigate, Routes, Route } from "react-router-dom";
import axios from 'axios';
import Swal from 'sweetalert2';
import MasterDashboardLayout from '../../AdminPanel/MasterDashboardLayout'

import { DataGrid } from '@mui/x-data-grid';

function ViewContactVisit() {
    const navigate = useNavigate();
    const [renderData, setRenderData] = useState('')
    const columns = [
        { field: 'id', headerName: 'ID', padding: 50 },
        { field: 'office_address', headerName: 'Office address', flex: 1 },
        { field: 'office_email', headerName: 'Email', flex: 1 },
        { field: 'phone_no', headerName: 'Phone', flex: 1 },
        { field: 'office_phone', headerName: 'Office_phone', flex: 1 },

        {
            headerName: 'Action',
            renderCell: (params) =>
                <div class="fs-6" style={{ cursor: 'pointer' }}>
                <Link to={`/edit-contact-visit/${params.id}`}><i class="fa text-warning fa-edit"></i></Link>

                    <i class="fa text-danger fa-trash mx-2" onClick={(e) => deleteContactVisit(e, params.id)}></i>
                </div>
        },
    ];

    const [allContactUs, setAllContactUs] = useState([]);
    console.log('all testi', allContactUs)
    useEffect(() => {
        axios.get(`/api/all-contact-visit`).then(res => {
            if (res.data.status == 200) {
                setAllContactUs(res.data.all_contact_visit);
                // setLoading(false);

            }
        })
    }, [renderData])

    //delete functionality start 

    const deleteContactVisit = (e, id) => {
        e.preventDefault();
        const thisClicked = e.currentTarget;
        //  thisClicked.innerText = "Deleting";

        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post(`/api/delete-contact-visit/${id}`).then(res => {
                    if (res.data.status === 200) {
                        setRenderData(res.data)
                        thisClicked.closest("tr").remove();

                        //   swal("Success", res.data.message, "success");
                    }
                });
                Swal.fire(
                    'Deleted!',
                    'Your data has been deleted.',
                    'success'
                )
            }
        })

    }


    return (
        <MasterDashboardLayout>
            <div class="col-12 grid-margin stretch-card">
                <div class="card">
                    <div class="card-body">
                        <div className='d-flex justify-content-between my-2'>
                            <h4 class="card-title">View User Contact Information</h4>
                            <Link to="/add-contact-visit"><button type='button' className='btn btn-warning btn-sm fw-bold rounded-pill'>Create</button></Link>

                        </div>

                        <div style={{ height: '70vh', width: '100%' }}>
                            <DataGrid
                                rows={allContactUs}
                                columns={columns}
                                pageSize={5}
                                rowHeight={100}
                                rowsPerPageOptions={[5]}
                            // checkboxSelection
                            />
                        </div>
                    </div>
                </div>
            </div>
        </MasterDashboardLayout>
    )
}

export default ViewContactVisit