import React, { useState, useEffect } from 'react';
import { Link, Navigate, useNavigate, Routes, Route } from "react-router-dom";

import Swal from 'sweetalert2';
import axios from 'axios';

function AdminLogin(){
    const navigate=useNavigate();
    const [loginInput, setLoginInput] = useState({
        email: '',
        password: ''
      })
      const handleChange = (e) => {
        setLoginInput({
          ...loginInput, [e.target.name]: e.target.value
        })
      }
    
      const handleSubmit = (e) => {
        e.preventDefault();
        // setClickedRender(true);
        console.log('admin info check', loginInput)
    
        // update('yes')
    
        // console.log('state checking', loginInput)
    
        axios.get('sanctum/csrf-cookie').then(response => {
    
          axios.post('/api/admin-login', loginInput).then(res => {
    
    
            if (res.data.status == 200) {
            //   setClickedRender(false);
    
            //   let admin_auth = {
            //     full_name: res.data.full_name,
            //     image: res.data.image,
            //     user_type: res.data.user_type,
            //     token: res.data.token,
            //     email: res.data.email,
            //     user_id: res.data.user_id,
            //     status: 'yes'
            //   }
            //   // update(admin_auth)
            //   handleSucessLogin(admin_auth);
                  localStorage.setItem('auth_token',res.data.token);
              localStorage.setItem('name',res.data.name);
              localStorage.setItem('email',res.data.email);
              localStorage.setItem('user_type',res.data.user_type);
              localStorage.setItem('user_id',res.data.user_id);
              localStorage.setItem('user_info',JSON. stringify(res.data.user_info) );
    
              navigate('/admin-dashboard')
    
            //     if (res.data.user_type=== 'admin') {
    
            //       navigate('/dashboard')
            //   }
            //   else if(res.data.user_type=== 'user'){
            //       navigate('/')
    
            //   }
    
            }
            else if (res.data.status == 401) {
              Swal.fire(res.data.message, '', 'warning')
            //   setClickedRender(false)
    
            }
            else {
              Swal.fire('Invalid Credentials', '', 'warning')
            //   setClickedRender(false)
    
            }
          })
    
        });
      }
    
return(
    <>
    
    <div class="container-scroller">
    <div class="container-fluid page-body-wrapper full-page-wrapper">
      <div class="content-wrapper d-flex align-items-center auth px-0">
        <div class="row w-100 mx-0">
          <div class="col-lg-4 mx-auto">
            <div class="auth-form-light text-left py-5 px-4 px-sm-5">
              <div class="brand-logo">
                <img src="../../images/logo.svg" alt="logo"/>
              </div>
              <h6 class="font-weight-light">Sign in to continue.</h6>
              <form class="pt-3" onSubmit={handleSubmit}>
                <div class="form-group">
                  <input type="email" class="form-control form-control-lg" id="exampleInputEmail1" placeholder="Email" name='email' onChange={handleChange}/>
                </div>
                <div class="form-group">
                  <input type="password" class="form-control form-control-lg" id="exampleInputPassword1" placeholder="Password" name='password' onChange={handleChange} />
                </div>
                <div class="mt-3">
                  <button class="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn" type='submit' >SIGN IN</button>
                </div>
                <div class="my-2 d-flex justify-content-between align-items-center">
                  {/* <div class="form-check">
                    <label class="form-check-label text-muted">
                      <input type="checkbox" class="form-check-input"/>
                      Keep me signed in
                    <i class="input-helper"></i></label>
                  </div> */}
                  <Link to="/admin-forgot-password">  <a href="" class="auth-link text-black my-2">Forgot password?</a></Link>
                </div>
               
                <div class="text-center mt-4 font-weight-light">
                  Don't have an account? <a href="register.html" class="text-primary">Create</a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
    
    </>
)
}

export default AdminLogin