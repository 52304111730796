import React from "react";
import "./ConnectCutomerServices.css";
import laptop from "../../../Images/ConnectCustomerService/Laptop.png";
import bg from "../../../Images/ConnectCustomerService/Connect-customer-background.png";
import lapMobile from "../../../Images/ConnectCustomerService/laptop-mobile.png";
import innovationLottieImg from "./innovationLottie.json";
import Lottie from "lottie-react";

const innovativeBanner = {
  background: `url(${bg})`,
  marginBottom: "5%",
  backgroundRepeat: "no-repeat",
  position: "relative",
  backgroundSize: "cover",
  overflowX: "hiden",
};

const InnovativeWebsite = () => {
  return (
    <div className="container-fluid" style={innovativeBanner}>
      <div className="row">
        <div id="connect-background-cover-div">
          <div className="innovation-first-div">
            {/* <img className="innovation-img" src={laptop} alt="" /> */}
            <div className=" col-xxl-6 col-xl-5 col-lg-5 col-md-5 col-sm-5">
              <div className="lottie-2-div">
                <Lottie
                  animationData={innovationLottieImg}
                  loop={true}
                  className="lottie-2"
                />
              </div>
            </div>
            <div className=" col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-4">
              <div className="innovation-text">
                <div>
                  <h3 className="innovation-h3">
                    WE THINK, WE CREATE, WE CARE
                  </h3>
                  <div className="mobile-view-export">
                    <button className="btn btn-light btn-md ">
                      Get an expert
                    </button>
                  </div>
                </div>
                <h5 className="innovation_sub_header">
                  Have ideas? or in problem? Tell us about your situation
                </h5>
                <p className="innovation-p2">
                  Our expert team will assist you to get raid from the headache.
                  From the low end to high end, our team is ready to meet your
                  goals with PDD & TDD
                </p>
                <button className="btn btn-light btn-md web-view-export">
                  Get an expert
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InnovativeWebsite;
