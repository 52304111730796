import React, { useState, useEffect, Component } from "react";
import Footer from "../../Shared/Footer/Footer";
import NavigationBar from "../../Shared/NavigationBar/NavigationBar";
import PropTypes from "prop-types";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import ssp from "../../../Images/Product & Services/Software/s_s_p.png";
import ba from "../../../Images/Product & Services/Software/ba.png";
import softwarebg from "../../../Images/Product & Services/Software/softwarebg.jpg";
import cvp_new from "../../../Images/Product & Services/Software/cvp_new.png";
import ac from "../../../Images/Product & Services/Software/ac.png";

import softTabimg6 from "../../../Images/Product & Services/Software/cripton.png";

import dataBG from "../../../Images/Product & Services/DataS/dataBannerBg.png";
import softwareIcon from "../../../Images/Product & Services/Software/software.png";
import "../DataCenter/DataCenter.css";
import { faFileArrowDown, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Slider from "react-slick";
import hardLogoImg from "../../../Images/Product & Services/HardwareS/dell-logo.png";
import hpe from "../../../Images/Product & Services/HardwareS/hpe.png";
import juniper from "../../../Images/Product & Services/HardwareS/juniper.png";
import cisco from "../../../Images/Product & Services/HardwareS/cisco.png";
import sophos from "../../../Images/Product & Services/HardwareS/sophos.png";
import fortinet from "../../../Images/Product & Services/HardwareS/fortinet.png";
import f5 from "../../../Images/Product & Services/HardwareS/f5.jpeg";
import ScrollTopButton from "../../ScrollTopButton/ScrollTopButton";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const HardwareSolution = () => {
  const [isDownloading, setIsDownloading] = useState(false);
  const handleDownload = () => {
    setIsDownloading(true);
    const link = document.createElement("a");
    link.href = "/";
    link.download = "/";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setIsDownloading(false);
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // react slick
  const settings = {
    dots: true,
    infinite: true,

    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
  };
  // Reload 0
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="main_product_page software_solution">
      <section>
        <NavigationBar />
      </section>

      <div className="data-center-wrapper">
        <img
          className="img img-fluid bg_img"
          src="https://www.shutterstock.com/image-illustration/central-computer-processors-cpu-concept-260nw-332111609.jpg"
        />

        <div className="data-mobile-texts-div ">
          <div className="data-mobile-texts">
            <div className="d-flex align-items-center text-center">
              <img
                className="img img-fluid"
                src={softwareIcon}
                alt="software"
              />
              <h5 className="data-head-tag ms-2">Hardware & Solution</h5>
            </div>

            <p className="data-p2">
              <p>
                We take pride in providing top-quality computer hardware
                solutions that are tailored to meet the needs of our clients.
                Our commitment to quality and customer satisfaction is
                unwavering, and we strive to exceed your expectations with every
                interaction. If you are looking for reliable and
                high-performance computer hardware solutions, look no further
                than our website.
              </p>
              <p>
                we offer comprehensive computer hardware solutions to meet all
                your technology needs. We understand that having reliable and
                high-performance computer hardware is essential in today's
                fast-paced world. That's why we offer a range of hardware
                solutions that are specifically designed to enhance your
                computing experience.{" "}
              </p>
            </p>
          </div>
        </div>
      </div>

      <div className="container offer-header">
        <h4 className="data-fe-icons  ">What We Offer</h4>
      </div>

      {/* <!-- Solution we cover-----------------> */}

      <div class="container">
        <div className="commscop">
          <div className="row ">
            <h3>DELL TECHNOLOGIES</h3>
            <div className="col-3 p-3  align-items-center justify-content-center">
              <img className="img img-fluid" src={hardLogoImg} alt="" />
              {/* <a className="btn btn-block btn-sm btn-video" href="#">
                  {""}<i className="fa fa-video"></i> See video</a> */}
            </div>
            <div className="col-9">
              {" "}
              <p>
                <p>
                  Dell provides end to end solution for customers Desktop
                  Notebook Server Storage Hyper-converged Infrastructure Virtual
                  Desktop Infrastructure Networking
                </p>
                <ul>
                  <li> Desktop</li>
                  <li>Notebook</li>
                  <li>Server. </li>
                  <li> Storage.</li>
                  <li>Hyper-converged Infrastructuret. </li>
                  <li>Virtual Desktop Infrastructure. </li>
                  <li>Networking. </li>
                </ul>
              </p>
            </div>
          </div>
        </div>
        <div className="rosenberger">
          <div className="row ">
            <h3>HP</h3>
            <div className="col-3 p-3 align-items-center justify-content-center">
              <img
                className="img img-fluid"
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQnlkydxKgEnHm9ZtiB2-ftkCrgxHs_Zf2FoGJ37RxMJYg25wXyQiezbmIJXwuF2swH-WA&usqp=CAU"
                alt=""
              />
              {/* <a className="btn btn-block btn-sm btn-video" href="#">
                  {""}<i className="fa fa-video"></i> See video</a> */}
            </div>
            <div className="col-9">
              {" "}
              <p>
                <p> Hp provides end to end solution for customers</p>
                <ul>
                  <li> Desktop</li>
                  <li>Notebook</li>
                  <li>Printer</li>
                  <li>Scanner .</li>
                </ul>
              </p>
            </div>
          </div>
        </div>
        <div className="ssp">
          <div className="row ">
            <h3>HPE</h3>
            <div className="col-3 p-3 align-items-center justify-content-center">
              <img className="img img-fluid" src={hpe} alt="" />
              {/* <a className="btn btn-block btn-sm btn-video" href="#">
                  {""}<i className="fa fa-video"></i> See video</a> */}
            </div>
            <div className="col-9">
              {" "}
              <p>
                <p>Hpe provides end to end solution for customers</p>
                <ul>
                  <li> Server</li>
                  <li> Hyper-converged Infrastructure</li>
                  <li> Virtual Desktop Infrastructure</li>
                  <li>Networking </li>
                </ul>
              </p>
            </div>
          </div>
        </div>
        <div className="ba">
          <div className="row ">
            <h3>CISCO</h3>
            <div className="col-3 p-3align-items-center justify-content-center">
              <img className="img img-fluid" src={cisco} alt="" />
              {/* <a className="btn btn-block btn-sm btn-video" href="#">
                  {""}<i className="fa fa-video"></i> See video</a> */}
            </div>
            <div className="col-9">
              {" "}
              <p>
                <p>
                  Our comprehensive suite of hardware solutions and countrywide
                  logistics services span the entire hardware manufacturing
                  lifecycle
                </p>
                <ul>
                  <li> Networking.</li>
                  <li>Network Security.</li>
                  <li>Servers . . </li>
                  <li> Hyper-converged Infrastructure .</li>
                </ul>{" "}
              </p>
            </div>
          </div>
        </div>
        <br />
        <div className="ac">
          <div className="row ">
            <h3>JUNIPER </h3>
            <div className="col-3 p-3 align-items-center justify-content-center">
              <img className="img img-fluid" src={juniper} alt="" />
              {/* <a className="btn btn-block btn-sm btn-video" href="#">
                  {""}<i className="fa fa-video"></i> See video</a> */}
            </div>
            <div className="col-9">
              <p>
                {" "}
                Juniper Networks provides high-performance networking &
                cybersecurity solutions to service providers, enterprise
                companies & public sector organizations
              </p>
              <ul>
                <li> Networking</li>
                <li> Network Security</li>
                <li> Wireless Solution</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="ac">
          <div className="row ">
            <h3>F5 </h3>
            <div className="col-3 p-3 align-items-center justify-content-center">
              <img className="img img-fluid" src={f5} alt="" />
              {/* <a className="btn btn-block btn-sm btn-video" href="#">
                  {""}<i className="fa fa-video"></i> See video</a> */}
            </div>
            <div className="col-9">
              <p>
                {" "}
                F5 application services ensure that applications are always
                secure and perform the way they should—in any environment and on
                any device
              </p>
              <ul>
                <li> Local Traffic Manager</li>
                <li> Web Application Firewall</li>
                <li> Advanced Firewall Manager</li>
                <li>Access Policy Manager</li>
                <li>Fraud Protection Service</li>
                <li> DNS Cloud Service</li>
                <li> Policy Enforcement Manager</li>
                <li> Secure Web Gateway</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="ac">
          <div className="row ">
            <h3>FORTINET</h3>
            <div className="col-3 p-3 align-items-center justify-content-center">
              <img className="img img-fluid" src={fortinet} alt="" />
              {/* <a className="btn btn-block btn-sm btn-video" href="#">
                  {""}<i className="fa fa-video"></i> See video</a> */}
            </div>
            <div className="col-9">
              <p>
                {" "}
                F5 application services ensure that applications are always
                secure and perform the way they should—in any environment and on
                any device
              </p>
              <ul>
                <li> Local Traffic Manager</li>
                <li> Web Application Firewall</li>
                <li> Advanced Firewall Manager</li>
                <li>Access Policy Manager</li>
                <li>Fraud Protection Service</li>
                <li> DNS Cloud Service</li>
                <li> Policy Enforcement Manager</li>
                <li> Secure Web Gateway</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="ac">
          <div className="row ">
            <h3>SOPHOS</h3>
            <div className="col-3 p-3 align-items-center justify-content-center">
              <img className="img img-fluid" src={sophos} alt="" />
              {/* <a className="btn btn-block btn-sm btn-video" href="#">
                  {""}<i className="fa fa-video"></i> See video</a> */}
            </div>
            <div className="col-9">
              <p>
                It's available for multiple platforms including hardware
                appliances, virtual environments and as a software ISO to
                install on Intel x86 hardware of your choice.
              </p>
              <ul>
                <li>Sophos Firewall.</li>
                <li>Sophos Wireless.</li>
                <li> Sophos Switch.</li>
                <li>Sophos Zero Trust Network.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <hr className="half-hr" />

      <section>
        <Footer />
      </section>
      <ScrollTopButton />
    </div>
  );
};

export default HardwareSolution;
