import React, { Component } from "react";
import img1 from "../../../Images/Galary/01.jpg";
import img2 from "../../../Images/Galary/02.jpg";
import img3 from "../../../Images/Galary/03.jpg";
import img4 from "../../../Images/Galary/04.jpg";
import img5 from "../../../Images/Galary/05.jpg";
import img6 from "../../../Images/Galary/06.jpg";
import img7 from "../../../Images/Galary/07.jpg";
import img8 from "../../../Images/Galary/08.jpeg";
import "./Galary.css";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faEye } from "@fortawesome/free-solid-svg-icons";

export default class Galary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null,
    };
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
    });
  }

  render() {
    return (
      <div className="container-fluid" id="gallery_page">
        {/* <div className="row">
          <div className="col-xxl-8 col-xl-8 col-lg-6 col-md-7 col-sm-12 gallery_size">
            <h1 className="galary-h1">Gallery</h1>
            <hr className="half_hr" />
            <Slider
              asNavFor={this.state.nav2}
              ref={(slider) => (this.slider1 = slider)}
            >
              <div>
                <div className="card text-bg-light card-div">
                  <img
                    src={img1}
                    className="img-fluid card-img img1"
                    alt="..."
                  />
                  <div className="card-img-overlay">
                    <h5 className="word-h5">
                      Technovaion approach your business and declare your costs
                      and terms for providing your goods and services.
                    </h5>
                    <h5 className="wordMobile-h5">
                      Technovaion approach your business and declare your costs.
                    </h5>

                    <div className="card-text">
                      <p className="me-3 single-title1">
                        <span>
                          <FontAwesomeIcon icon={faCalendar} />
                        </span>{" "}
                        16.05.2022
                      </p>
                      <p className="single-title2">
                        <span>
                          <FontAwesomeIcon icon={faEye} />
                        </span>{" "}
                        130k
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div class="card text-bg-light">
                  <img src={img2} className="card-img img1" alt="..." />
                  <div className="card-img-overlay">
                    <h5 className="word-h5">
                      Pakiza Technovaion is great marketing decisions, who have
                      ever been made on qualitative data.
                    </h5>
                    <h5 className="wordMobile-h5">
                      Pakiza Technovaion is great marketing decisions.
                    </h5>

                    <div className="card-text">
                      <p className="me-3 single-title1">
                        <span>
                          <FontAwesomeIcon icon={faCalendar} />
                        </span>{" "}
                        16.05.2022
                      </p>
                      <p className="single-title2">
                        <span>
                          <FontAwesomeIcon icon={faEye} />
                        </span>{" "}
                        130k
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div class="card text-bg-light ">
                  <img src={img3} className="card-img img1" alt="..." />
                  <div className="card-img-overlay">
                    <h5 className="word-h5">
                      Technovaion can increased standardization,and reduced
                      operational costs were recognized of ERP software
                      implementation.
                    </h5>
                    <h5 className="wordMobile-h5 text-dark">
                      Technovaion can iMobilencreased standardization,and
                      reduced.
                    </h5>

                    <div className="card-text">
                      <p className="me-3 single-title1">
                        <span>
                          <FontAwesomeIcon icon={faCalendar} />
                        </span>{" "}
                        16.05.2022
                      </p>
                      <p className="single-title2">
                        <span>
                          <FontAwesomeIcon icon={faEye} />
                        </span>{" "}
                        130k
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div class="card text-bg-light ">
                  <img src={img4} class="card-img img1" alt="..." />
                  <div className="card-img-overlay">
                    <h5 className="word-h5">
                      A computer once beat me at chess, but it was no match for
                      me at kick boxing.
                    </h5>
                    <h5 className="wordMobile-h5">
                      A computer once beat me at chess.
                    </h5>

                    <div className="card-text">
                      <p className="me-3 single-title1">
                        <span>
                          <FontAwesomeIcon icon={faCalendar} />
                        </span>{" "}
                        16.05.2022
                      </p>
                      <p className="single-title2">
                        <span>
                          <FontAwesomeIcon icon={faEye} />
                        </span>{" "}
                        130k
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div class="card text-bg-light">
                  <img src={img5} className="card-img img1" alt="..." />
                  <div class="card-img-overlay">
                    <h5 className="word-h5">
                      Technovaion have clear, concise and complete
                      communication. Communication is the foundation of
                      community
                    </h5>
                    <h5 className="wordMobile-h5">
                      Technovaion have clear, concise and complete
                      communication.
                    </h5>
                    <div className="card-text">
                      <p className="me-3 single-title1">
                        <span>
                          <FontAwesomeIcon icon={faCalendar} />
                        </span>{" "}
                        16.05.2022
                      </p>
                      <p className="single-title2">
                        <span>
                          <FontAwesomeIcon icon={faEye} />
                        </span>{" "}
                        130k
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="card text-bg-light ">
                  <img src={img2} class="card-img img1" alt="..." />
                  <div className="card-img-overlay">
                    <h5 className="word-h5">
                      Pakiza Technovaion brings software is a set of
                      instructions, data or programs used to operate computers
                      and execute specific tasks.
                    </h5>
                    <h5 className="wordMobile-h5">
                      Pakiza Technovaion brings software is a set of
                      instructions.
                    </h5>
                    <div className="card-text">
                      <p className="me-3 single-title1">
                        <span>
                          <FontAwesomeIcon icon={faCalendar} />
                        </span>{" "}
                        16.05.2022
                      </p>
                      <p className="single-title2">
                        <span>
                          <FontAwesomeIcon icon={faEye} />
                        </span>{" "}
                        130k
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Slider>

            <Slider
              className="img-div-slider"
              asNavFor={this.state.nav1}
              ref={(slider) => (this.slider2 = slider)}
              slidesToShow={4}
              swipeToSlide={true}
              focusOnSelect={true}
            >
              <div className="me-3 img-div-slider">
                <img className="img2" src={img1} alt="" />
              </div>
              <div className="img-div-slider">
                <img className="img2" src={img2} alt="" />
              </div>
              <div className="img-div-slider">
                <img className="img2" src={img3} alt="" />
              </div>
              <div className="img-div-slider">
                <img className="img2" src={img4} alt="" />
              </div>
              <div className="img-div-slider">
                <img className="img2" src={img5} alt="" />
              </div>
              <div className="img-div-slider">
                <img className="img2" src={img6} alt="" />
              </div>
              <div className="img-div-slider">
                <img className="img2" src={img7} alt="" />
              </div>
              <div className="img-div-slider">
                <img className="img2" src={img8} alt="" />
              </div>
            </Slider>
          </div>
        </div> */}
      </div>
    );
  }
}
