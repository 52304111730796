import React from "react";
import "./UsignTechnology.css";
import techno1 from "../../../Images/Using Technology/tecno-1.png";
import techno2 from "../../../Images/Using Technology/tecno-2.png";
import techno4 from "../../../Images/Using Technology/tecno-4.png";
import techno5 from "../../../Images/Using Technology/tecno-5.png";
import techno6 from "../../../Images/Using Technology/tecno-6.png";
import techno7 from "../../../Images/Using Technology/tecno-7.png";
import techno8 from "../../../Images/Using Technology/tecno-8.png";
import techno9 from "../../../Images/Using Technology/tecno-9.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import TechLang from "./TechLang";

const UsignTechnology = () => {
  return (
    <div className="container">
      <div className="tech-full-div">
        <h6 className="tech-h6">TECHNOLOGY</h6>
        <h4 className="tech-h4">We use modern technology</h4>
        <p className="tech-p">
          Elevate your business with our state-of-the-art modern technology
          <br /> Experience the future of technology with our advanced solutions
        </p>
        <div className="mt-5 ">
          <TechLang />
        </div>
      </div>
    </div>
  );
};

export default UsignTechnology;
