import React, { useHistory } from "react";
import Lottie from "lottie-react";
import communication from "./communication.json";
import ISOCerti from "../../../Images/About-Us/Communication/iso.png";
import "./CommunicationInfo.css";
import teamMem from "../../../Images/About-Us/Communication/team-member.png";
import client from "../../../Images/About-Us/Communication/client.png";
import software from "../../../Images/About-Us/Communication/software.png";
import positiveFeedback from "../../../Images/About-Us/Communication/positive-feedback.png";
import departments from "../../../Images/About-Us/Communication/departments.png";
import industry from "../../../Images/About-Us/Communication/industry.png";
import HardwareSolutions from "../../../Images/About-Us/Communication/Hardware-solutions.png";
import manageService from "../../../Images/About-Us/Communication/manage-service.png";
import dataCenter from "../../../Images/About-Us/Communication/data-center.png";
import securitServillience from "../../../Images/About-Us/Communication/security and servillience.png";

const CommunicationInfo = () => {
  return (
    <div className="CommunicationIn-fullDiv container">
      <section className="container">
        <div className="ISO-tag">
          <img className="ISO-img" src={ISOCerti} alt="" />
          <h2 className="ISO-h2">ISO 9001:2015 Certified Company</h2>
        </div>
        <p className="ISO-p">
          Our company has been ISO certified for Quality Management Systems
          since 2009. Pakiza Technovation Limited, PTVL in short is a developed
          organization and providing quality IT solutions to the different Govt.
          and Private Organizations and solving their problems of aligning IT
          with business direction.
        </p>

        <div className="mt-5 mb-5">
          <div className="overviews">
            <div className="overview-icons-div">
              <h3 className="overview-icons">OVERVIEW</h3>
            </div>
            <h4 className="ms-3 overview-icons-num">PTVL by NUMBERS</h4>
          </div>
          <div className="row mt-3">
            <div className="col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="icons-OV mb-4">
                <img className="icons-com" src={teamMem} alt="" />
                <div className="icons-text">
                  <h6 className="icons-h6">50+</h6>
                  <p className="icons-p2">TEAM MEMBER</p>
                </div>
              </div>
              <div className="icons-OV">
                <img className="icons-com" src={client} alt="" />
                <div className="icons-text">
                  <h6 className="icons-h6">200+ </h6>
                  <p className="icons-p2">NUMBER OF CLIENTS</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="icons-OV mb-4">
                <img className="icons-com" src={software} alt="" />
                <div className="icons-text">
                  <h6 className="icons-h6">10+</h6>
                  <p className="icons-p2">SOFTWARE & SOLUTION</p>
                </div>
              </div>
              <div className="icons-OV">
                <img className="icons-com" src={positiveFeedback} alt="" />
                <div className="icons-text">
                  <h6 className="icons-h6">100+</h6>
                  <p className="icons-p2">POSITIVE FEEDBACK</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="icons-OV mb-4">
                <img className="icons-com" src={departments} alt="" />
                <div className="icons-text">
                  <h6 className="icons-h6">6+</h6>
                  <p className="icons-p2">DEPARTMENTS</p>
                </div>
              </div>
              <div className="icons-OV">
                <img className="icons-com" src={industry} alt="" />
                <div className="icons-text">
                  <h6 className="icons-h6">11+</h6>
                  <p className="icons-p2">INDUSTRY EXPERTS</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="container mt-5">
        <div className="overviews">
          <div className="overview-icons-div">
            <h3 className="overview-icons">TECHNOLOGY</h3>
          </div>
          <h4 className="ms-3 overview-icons-num">OUR SKILL MATRIX</h4>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 col-12">
            <Lottie
              animationData={communication}
              loop={true}
              className="lottie-commmunication"
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 col-12">
            <div className="mt-5 text-commu">
              <h1 className="text-com-h1">Software & Web developement</h1>
              <p className="text-com-p">
                ASP.NET, PHP, JavaScript, Python, JQuery, Ajax, HTML5, CSS
                MSSqlServer, Oracle, MySql, Firebase
              </p>
            </div>
            <div className=" text-commu">
              <h1 className="text-com-h1">E-Commerce</h1>
              <p className="text-com-p">
                PhpCMSl, Woocommerce, PrestaShop, Magento
              </p>
            </div>
            <div className=" text-commu">
              <h1 className="text-com-h1">Mobile application</h1>
              <p className="text-com-p">
                Flutter, Kotlin, Progressive web application (PWA)
              </p>
            </div>
            <div className=" text-commu">
              <h1 className="text-com-h1">Reports</h1>
              <p className="text-com-p">
                Google Analytics, Crystal Reports, JReports, WForms
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="commu-last-div mt-5">
        <div className="row mt-3 container">
          <div className="col-lg-6 col-md-6 col-sm-12 col-12">
            <a href="./hardwaresolution" className="icons-BC mb-4">
              <img className="icons-com" src={HardwareSolutions} alt="" />
              <div className="icons-text">
                <h6 className="icons-h6">Hardware solution</h6>
                <p className="text-com-p">
                  HP, HPE, CISCO, JUNIPER, F5, FORTINET, SHOPHOS
                </p>
              </div>
            </a>
            <a href="./manangedServices" className="icons-BC">
              <img className="icons-com" src={manageService} alt="" />
              <div className="icons-text">
                <h6 className="icons-h6">Manged Service</h6>
                <p className="text-com-p">
                  BreakFix, Monitoring, Help desk, Resource outsourcing
                </p>
              </div>
            </a>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 col-12">
            <a href="./dataCenter" className="icons-BC mb-4">
              <img className="icons-com" src={dataCenter} alt="" />
              <div className="icons-text">
                <h6 className="icons-h6">Data center Infra. solution</h6>
                <p className="text-com-p">
                  CommScope, Rosenberger, Online UPS, PAC, EMS, FIRE
                </p>
              </div>
            </a>
            <a href="./securitySarvillance" className="icons-BC">
              <img className="icons-com" src={securitServillience} alt="" />
              <div className="icons-text">
                <h6 className="icons-h6">Security & Surveillance</h6>
                <p className="text-com-p">
                  HikVision, ZKTEco, Grrrett, Suprema, P A System
                </p>
              </div>
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CommunicationInfo;

// import React, { Component } from "react";
// import Slider from "react-slick";
// import "./CommunicationInfo.css";
// import img1 from "../../../Images/About-Us/Communication/com-1.png";
// import img2 from "../../../Images/About-Us/Communication/com-2.png";
// import img3 from "../../../Images/About-Us/Communication/com-3.png";
// import img4 from "../../../Images/About-Us/Communication/com-4.png";
// import imgmob1 from "../../../Images/About-Us/Communication/com-mob-1.png";
// import imgmob2 from "../../../Images/About-Us/Communication/com-mob-2.png";

// export default class CommunicationInfo extends Component {
//   render() {
//     const settings = {
//       infinite: true,
//       slidesToShow: 2,
//       slidesToScroll: 1,
//       autoplay: true,
//       speed: 2000,
//       autoplaySpeed: 2000,
//       cssEase: "linear",
//     };
//     return (
//       <div className="full-div-com-info">
//         <section className="container">
//           <div className="commu-infodiv">
//             <Slider {...settings}>
//               <div className="">
//                 <img className="img-fluid" src={img1} alt="" />
//               </div>
//               <div>
//                 <img className="img-fluid" src={img2} alt="" />
//               </div>
//               <div>
//                 <img className="img-fluid" src={img3} alt="" />
//               </div>
//               <div>
//                 <img className="img-fluid" src={img4} alt="" />
//               </div>
//               <div>
//                 <img className="img-fluid" src={img2} alt="" />
//               </div>
//               <div>
//                 <img className="img-fluid" src={img3} alt="" />
//               </div>
//             </Slider>
//           </div>
//           <div className="commun-text-full-div">
//             <h1 className="com-h1">
//               The Information and Communications Technology (ICT) sector of the
//               country
//             </h1>
//             <p className="com-p">
//               PTVL always love to optimize, not to be lowest in price, its
//               solution to meet its client’s requirement and thus ensure value
//               for money As the visibility of ICT projects are result driven, it
//               ensures the promised result and the project outcome becomes
//               visible. <br />
//               <br />
//               The leadership team are responsible for ensuring that the quality
//               objectives, and commitments to satisfy any applicable requirements
//               continually improve the quality management system.
//             </p>
//             <div className="com-mob-sec">
//               <p className="comp-p2">
//                 Lorem ipsum dolor sit amet consectetur. At nunc at amet
//                 tristique sed sem ultrices morbi. Id lacus ornare pharetra
//                 tellus pellentesque. Egestas neque dictumst vitae massa
//                 adipiscing a et. Ullamcorper in velit id lectus.
//               </p>
//               <img className="com-img2" src={imgmob1} alt="" />
//             </div>
//             <div className="com-mob-sec">
//               <p className="comp-p3">
//                 Lorem ipsum dolor sit amet consectetur. Purus turpis egestas
//                 facilisis commodo ullamcorper purus. Vivamus orci ut gravida
//                 vivamus cursus amet massa est. <br />
//                 <br /> Eget sem fermentum consectetur vulputate. Ultrices
//                 pharetra velit magna nunc neque lectus vel in. Dolortincidunt
//                 pretium imperdiet tristique non habitant nisl et amet. Est
//                 pellentesque lobortis sit ultrices.
//               </p>
//               <img className="com-img2" src={imgmob1} alt="" />
//             </div>
//           </div>
//         </section>
//       </div>
//     );
//   }
// }
