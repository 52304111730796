import React from "react";
import Footer from "../../Shared/Footer/Footer";
import NavigationBar from "../../Shared/NavigationBar/NavigationBar";
import "./CustomizeSolution.css";
import Lottie from "lottie-react";
import customizeLottie from "./Customize-Solution.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faBookOpen } from "@fortawesome/free-solid-svg-icons";
import Customidea from "../../../Images/Product & Services/CustomizeSolution/idea.png";
import Customrequrment from "../../../Images/Product & Services/CustomizeSolution/requirment.png";
import CustomSolution from "../../../Images/Product & Services/CustomizeSolution/solution.png";
import CustomPrototype from "../../../Images/Product & Services/CustomizeSolution/prototype.png";
import CustomsoftwareDev from "../../../Images/Product & Services/CustomizeSolution/software dev.png";
import CustomSoftwareTesting from "../../../Images/Product & Services/CustomizeSolution/software test.png";
import CustomSolutionDev from "../../../Images/Product & Services/CustomizeSolution/solution dev.png";
import Custommaintainance from "../../../Images/Product & Services/CustomizeSolution/maintainance.png";

const CustomizeSolution = () => {
  // Button
  const handleClickCustom = () => {
    window.location.href = "tel:+8801713060441";
  };
  return (
    <div>
      <section>
        <NavigationBar />
      </section>
      <section id="customize-herder-section">
        <div className="container container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 custom-Lottie-div">
              <Lottie
                animationData={customizeLottie}
                loop={true}
                className="custom-Lottie"
              />
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 customize-head-text-div">
              <div className="custom-tags-line">
                <p className="custom-tag">Customize</p>
                <h6 className="custom-tag-span">SOLUTION</h6>
              </div>
              <div className="customize-p1-div">
                <p className="customize-p1">
                  Custom software development refers to the creation of software
                  applications tailored to meet specific business requirements.
                  Unlike off-the-shelf software, custom software is designed and
                  developed to fit the unique needs of a particular
                  organization. The development process typically involves close
                  collaboration between the software development team and the
                  client to understand the organization's requirements,
                  constraints, and goals. This leads to the creation of a
                  customized solution that is optimized for the specific needs
                  of the business, delivering improved efficiency and better
                  outcomes compared to generic software solutions. Custom
                  software development can also provide organizations with a
                  competitive advantage by allowing them to differentiate their
                  offerings and better meet the needs of their customers.
                </p>
              </div>
              <div className="customs-buttons-div">
                <button
                  onClick={handleClickCustom}
                  className="custom-button-demo"
                >
                  <span className="me-2">
                    <FontAwesomeIcon icon={faPhone} />
                  </span>
                  CALL FOR APPOINMENT AND DEMO
                </button>
                <button className="custom-button-discover">
                  <span className="me-2">
                    <FontAwesomeIcon icon={faBookOpen} />
                  </span>
                  DISCOVER CASE STUDIES
                </button>
              </div>
            </div>
          </div>
          <div className="mt-5">
            <div className="custom-icon-outside-div">
              <img className="custom-icon-outside" src={Customidea} alt="" />
              <h6 className="custom-icons-h6">IDEA</h6>
              <p className="custom-icons-p">BRAINSTROM</p>
            </div>
            <div className="row custom-icon-inside-div">
              <div className="col-12 col-md-4 col-lg-4">
                <img
                  className="img img-fluid custom-icon"
                  src={Customrequrment}
                  alt=""
                />
                <h6 className="custom-icons-h6">REQUREMENTS</h6>
                <p className="custom-icons-p">COLLECTION</p>
              </div>
              <div className="col-12 col-md-4 col-lg-4">
                <img
                  className="img img-fluid custom-icon"
                  src={CustomSolution}
                  alt=""
                />
                <h6 className="custom-icons-h6">SOLUTION</h6>
                <p className="custom-icons-p">DESIGN</p>
              </div>
              <div className="col-12 col-md-4 col-lg-4">
                <img
                  className="img img-fluid custom-icon"
                  src={CustomPrototype}
                  alt=""
                />
                <h6 className="custom-icons-h6">PROTOTYPE</h6>
                <p className="custom-icons-p">DEVELOPMENT</p>
              </div>
            </div>
            <div className="row custom-icon-inside-div">
              <div className="col-12 col-md-4 col-lg-4">
                <img
                  className="img img-fluid custom-icon"
                  src={CustomsoftwareDev}
                  alt=""
                />
                <h6 className="custom-icons-h6">SOFTWARE</h6>
                <p className="custom-icons-p">DEVELOPMENT</p>
              </div>
              <div className="col-12 col-md-4 col-lg-4">
                <img
                  className="img img-fluid custom-icon"
                  src={CustomSoftwareTesting}
                  alt=""
                />
                <h6 className="custom-icons-h6">SOFTWARE</h6>
                <p className="custom-icons-p">TESTING</p>
              </div>
              <div className="col-12 col-md-4 col-lg-4">
                <img
                  className="img img-fluid custom-icon"
                  src={CustomSolutionDev}
                  alt=""
                />
                <h6 className="custom-icons-h6">SOLUTION</h6>
                <p className="custom-icons-p">DEPLOYMENT</p>
              </div>
            </div>
            <div className="custom-icon-outside-div">
              <img
                className="custom-icon-outside"
                src={Custommaintainance}
                alt=""
              />
              <h6 className="custom-icons-h6">MAINTAINANCE</h6>
              <p className="custom-icons-p">SUPPORT</p>
            </div>
          </div>
          <div className="custom-button-demo-lower-div">
            <button
              onClick={handleClickCustom}
              className="custom-button-demo-lower"
            >
              <span className="me-2">
                <FontAwesomeIcon icon={faPhone} />
              </span>
              CALL FOR APPOINMENT AND DEMO
            </button>
          </div>
        </div>
      </section>
      <section>
        <Footer />
      </section>
    </div>
  );
};

export default CustomizeSolution;
