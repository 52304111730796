import React from "react";
import Footer from "../Footer/Footer";
import NavigationBar from "../NavigationBar/NavigationBar";
import { Parallax } from "react-parallax";
import quatationBg from "../../../Images/buttons-bg/ButtonBG.png";
import "./RequestDemo.css";
import { Grid, MenuItem } from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";

// MUI
const currencies = [
  {
    value: "cp",
    label: "Cripton pro",
  },
  {
    value: "cvp",
    label: "Cripton vat pro",
  },
  {
    value: "ssp",
    label: "SSP",
  },
  {
    value: "ba",
    label: "Business Asist",
  },
  {
    value: "ac",
    label: "Alumni Connect",
  },
];
const currencies1 = [
  {
    value: "Full",
    label: "Full",
  },
  // {
  //   value: "EUR",
  //   label: "Half",
  // },
  // {
  //   value: "BTC",
  //   label: "Little",
  // },
];

const RequestDemo = () => {
  return (
    <div>
      <section>
        <NavigationBar />
      </section>
      <section className="parallax-demo">
        <Parallax
          bgImage={quatationBg}
          bgImageAlt="background-image"
          strength={50}
        >
          <div className="demo-header-texts">
            <h6 className="container team-header-h6">REQUEST FOR</h6>
            <h3 className="container team-header-h3">Demonstartion</h3>
          </div>
        </Parallax>
      </section>

      <section className="demo-full-div">
        <div className="demo-inpu-text-div container">
          <h3 className="demo-inpu-text-h3">Request a Demo</h3>
          <p className="demo-inpu-text-p">
            Need a demonstration before buying our signature products? Just fill
            out the below form and our expert personnel quickly connect with
            you. Schedule a meeting , on time our team will give you a demo.
          </p>
        </div>
        <div className="demo-lower-part ">
          <div className="demo-lower-full-input-div container">
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={6}>
                <MenuItem>
                  <TextField
                    className="select-demo"
                    id="outlined-select-currency-native"
                    select
                    label="Select your product"
                    size="small"
                    defaultValue="cp"
                    SelectProps={{
                      native: true,
                    }}
                  >
                    {currencies.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                </MenuItem>
              </Grid>
              <Grid item xs={6}>
                <MenuItem>
                  <TextField
                    className="select-demo"
                    id="outlined-select-currency-native"
                    select
                    label="Select module"
                    defaultValue="Full"
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                  >
                    {currencies1.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                </MenuItem>
              </Grid>
              <Grid item xs={6}>
                <MenuItem>
                  <Box
                    component="form"
                    sx={{
                      "& .MuiTextField-root": { m: 1 },
                    }}
                    noValidate
                    autoComplete="on"
                  >
                    <div>
                      <TextField
                        required
                        className="input-demo"
                        size="small"
                        id="outlined-multiline-flexible"
                        label="Your name"
                        multiline
                        maxRows={4}
                      />
                    </div>
                  </Box>
                </MenuItem>
              </Grid>
              <Grid item xs={6}>
                <MenuItem>
                  {" "}
                  <Box
                    component="form"
                    sx={{
                      "& .MuiTextField-root": { m: 1 },
                    }}
                    noValidate
                    autoComplete="on"
                  >
                    <TextField
                      required
                      size="small"
                      id="outlined-number"
                      className="input-number-demo"
                      label="Number"
                      type="number"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Box>
                </MenuItem>
              </Grid>
              <Grid item xs={6}>
                <MenuItem>
                  <Box
                    component="form"
                    sx={{
                      "& .MuiTextField-root": { m: 1 },
                    }}
                    noValidate
                    autoComplete="on"
                  >
                    <div>
                      <TextField
                        size="small"
                        id="outlined-multiline-flexible"
                        className="input-demo"
                        label="Your official email"
                        multiline
                        maxRows={4}
                      />
                    </div>
                  </Box>
                </MenuItem>
              </Grid>
              <Grid item xs={6}>
                <MenuItem>
                  {" "}
                  <Box
                    component="form"
                    sx={{
                      "& .MuiTextField-root": { m: 1 },
                    }}
                    noValidate
                    autoComplete="on"
                  >
                    <div>
                      <TextField
                        required
                        size="small"
                        className="input-demo"
                        id="outlined-multiline-flexible"
                        label="Your personal email"
                        multiline
                        maxRows={4}
                      />
                    </div>
                  </Box>
                </MenuItem>
              </Grid>
              <Grid item xs={6}>
                <MenuItem>
                  <Box
                    component="form"
                    sx={{
                      "& .MuiTextField-root": { m: 1 },
                    }}
                    noValidate
                    autoComplete="on"
                  >
                    <div>
                      <TextField
                        required
                        size="small"
                        className="input-demo"
                        id="outlined-multiline-flexible"
                        label="Your company/office/organization name"
                        multiline
                        maxRows={4}
                      />
                    </div>
                  </Box>
                </MenuItem>
              </Grid>
              <Grid item xs={6}>
                <MenuItem>
                  <Box
                    component="form"
                    sx={{
                      "& .MuiTextField-root": { m: 1 },
                    }}
                    noValidate
                    autoComplete="on"
                  >
                    <div>
                      <TextField
                        required
                        size="small"
                        className="input-demo"
                        id="outlined-multiline-flexible"
                        label="Office address"
                        multiline
                        maxRows={4}
                      />
                    </div>
                  </Box>
                </MenuItem>
              </Grid>
              <Grid item xs={6}>
                <MenuItem>
                  <Stack component="form" noValidate spacing={3}>
                    <TextField
                      className="date-demo"
                      id="date"
                      label="Demonstration date"
                      size="small"
                      type="date"
                      defaultValue="2017-05-24"
                      sx={{}}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Stack>
                </MenuItem>
              </Grid>
              <Grid item xs={6}>
                <MenuItem>
                  <Stack component="form" noValidate spacing={3}>
                    <TextField
                      className="time-demo"
                      size="small"
                      id="time"
                      label="Time"
                      type="time"
                      defaultValue="00:00"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        step: 300, // 5 min
                      }}
                      sx={{}}
                    />
                  </Stack>
                </MenuItem>
              </Grid>
            </Grid>

            {/* <div className="row g-2">
              <div className="input-group col-md">
                <label className="">
                  Select your product
                  <select className="form-select" id="inputGroupSelect02">
                    <option selected>Cripton pro erp</option>
                    <option value="1">Cripton pro erp1</option>
                    <option value="2">Cripton pro erp2</option>
                    <option value="3">Cripton pro erp3</option>
                  </select>
                </label>
              </div>
              <div className="input-group col-md">
                <label className="">
                  Select Module
                  <select
                    className="form-select full-in"
                    id="inputGroupSelect02"
                  >
                    <option selected>Full</option>
                    <option value="1">Half</option>
                    <option value="2">Semi-half</option>
                  </select>
                </label>
              </div>
            </div>
            <div className="row g-2 mt-2">
              <div className="col-md">
                <div className="form-floating">
                  <input
                    type="name"
                    className="form-control"
                    id="floatingInputGrid"
                  />
                  <label for="floatingInputGrid">name*</label>
                </div>
              </div>
              <div className="col-md">
                <div className="form-floating">
                  <input
                    type="number"
                    className="form-control"
                    id="floatingInputGrid"
                  />
                  <label for="floatingSelectGrid">phone number*</label>
                </div>
              </div>
            </div>
            <div className="row g-2 mt-2">
              <div className="col-md">
                <div className="form-floating">
                  <input
                    type="email"
                    className="form-control"
                    id="floatingInputGrid"
                  />
                  <label for="floatingSelectGrid">official email*</label>
                </div>
              </div>
              <div className="col-md">
                <div className="form-floating">
                  <input
                    type="email"
                    class="form-control"
                    id="floatingInputGrid"
                  />
                  <label for="floatingInputGrid">personal email*</label>
                </div>
              </div>
            </div>
            <div className="row g-2 mt-2">
              <div className="col-md">
                <div className="form-floating">
                  <input
                    type="text"
                    className="form-control"
                    id="floatingInputGrid"
                  />
                  <label for="floatingSelectGrid">company name*</label>
                </div>
              </div>
              <div className="col-md">
                <div className="form-floating">
                  <input
                    type="text"
                    class="form-control"
                    id="floatingInputGrid"
                  />
                  <label for="floatingInputGrid">Office address</label>
                </div>
              </div>
            </div>
            <div className="form-floating mt-3">
              <textarea
                className="form-control hard-texT-area"
                placeholder="Leave a comment here"
                id="floatingTextarea"
              ></textarea>
              <label for="floatingTextarea">Your querties</label>
            </div> */}
            <div>
              <button className="demo-submit-button" type="submit">
                Submit Your Request
              </button>
            </div>
          </div>
        </div>
      </section>
      <section>
        <Footer />
      </section>
    </div>
  );
};

export default RequestDemo;

{
  /* <div className="col-md">
<div className="form-floating">
  <input
    type="name"
    class="form-control"
    id="floatingInputGrid"
  />
  <label for="floatingInputGrid">Your Name</label>
</div>
</div>
<div className="col-md">
<div className="form-floating">
  <input
    type="email"
    className="form-control"
    id="floatingInputGrid"
  />
  <label for="floatingInputGrid">Email</label>
</div>
</div> */
}
