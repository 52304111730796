import React, { Component } from "react";
import Slider from "react-slick";
import techno1 from "../../../Images/Using Technology/docker.png";
import techno2 from "../../../Images/Using Technology/firebase.png";
import techno4 from "../../../Images/Using Technology/flutter.png";
import techno5 from "../../../Images/Using Technology/golang.png";
import techno6 from "../../../Images/Using Technology/java.png";
import techno7 from "../../../Images/Using Technology/javascript.png";
import techno8 from "../../../Images/Using Technology/jenkins.png";
import techno9 from "../../../Images/Using Technology/kotlin.png";
import techno10 from "../../../Images/Using Technology/laravel.png";
import techno11 from "../../../Images/Using Technology/oracle.png";
import techno12 from "../../../Images/Using Technology/php.png";
import techno13 from "../../../Images/Using Technology/pngwing.com.png";
import techno14 from "../../../Images/Using Technology/python.png";
import techno15 from "../../../Images/Using Technology/reactjs.png";
import techno16 from "../../../Images/Using Technology/selenium.png";
import techno17 from "../../../Images/Using Technology/splunk.png";

export default class TechLang extends Component {
  render() {
    const settings = {
      infinite: true,
      slidesToShow: 6,
      slidesToScroll: 3,
      autoplay: true,
      speed: 2000,
      autoplaySpeed: 2000,
      cssEase: "linear",
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <div>
        <Slider {...settings}>
          <div className="m-3">
            <img className="techSlider1" src={techno1} alt="" />
          </div>
          <div className="m-3">
            <img className="techSlider2" src={techno2} alt="" />
          </div>
          <div className="m-3">
            <img className="techSlider3" src={techno4} alt="" />
          </div>
          <div className="m-3">
            <img className="techSlider4" src={techno5} alt="" />
          </div>
          <div className="m-3">
            <img className="techSlider5" src={techno6} alt="" />
          </div>
          <div className="m-3">
            <img className="techSlider7" src={techno7} alt="" />
          </div>
          <div className="m-3">
            <img className="techSlider8" src={techno8} alt="" />
          </div>
          <div className="m-3">
            <img className="techSlider9" src={techno9} alt="" />
          </div>
          <div className="m-3">
            <img className="techSlider10" src={techno10} alt="" />
          </div>
          <div className="m-3">
            <img className="techSlider11" src={techno11} alt="" />
          </div>
          <div className="m-3">
            <img className="techSlider12" src={techno12} alt="" />
          </div>
          <div className="m-3">
            <img className="techSlider13" src={techno13} alt="" />
          </div>
          <div className="m-3">
            <img className="techSlider14" src={techno14} alt="" />
          </div>
          <div className="m-3">
            <img className="techSlider15" src={techno15} alt="" />
          </div>
          <div className="m-3">
            <img className="techSlider16" src={techno16} alt="" />
          </div>
          <div className="m-3">
            <img className="techSlider17" src={techno17} alt="" />
          </div>
        </Slider>
      </div>
    );
  }
}
