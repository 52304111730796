import React from "react";
import "./Footer.css";

const footerLastBg = {
  backgroundColor: "#E9E9E9",

  backgroundRepeat: "no-repeat",
  position: "relative",
  backgroundSize: "cover",
  overflowX: "hiden",
};
 const getYear={
  data:  new Date().getFullYear(),
};

const FooterLast = () => {
  return (
    <div style={footerLastBg} className="f-last">
      <h5 className="mobile-f-last">
        &copy; {getYear.data} Pakiza Technovation Limited
      </h5>
    </div>
  );
};

export default FooterLast;
