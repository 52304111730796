import React from "react";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Works.css";

const QualityBelive = ({ qualityBelive }) => {
  return (
    <div>
      <section className="container">
        <div className="qb-service-div">
          <div>
            <img className="qb-service-img" src={qualityBelive.img} alt="" />
            <h6 className="qb-service-h6">{qualityBelive.name}</h6>
          </div>
          <div>
            <p className="qb-service-p">{qualityBelive.description}</p>
          </div>
          <a className="qbservice-anchor" href={qualityBelive.URL}>
            Explore{" "}
            <span>
              <FontAwesomeIcon icon={faChevronRight} />
            </span>
          </a>
        </div>
      </section>
    </div>
  );
};

export default QualityBelive;
