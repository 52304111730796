import React, { useRef, useState, useEffect } from "react";
import "./Pservices.css";
import softImg1 from "../../../Images/Product & Services/Software/Software1.png";
import softImg2 from "../../../Images/Product & Services/Software/Software2.png";
import softImg3 from "../../../Images/Product & Services/Software/cripton ERP.png";
import softImg4 from "../../../Images/Product & Services/Software/criptonVatPro.png";
import softImg5 from "../../../Images/Product & Services/Software/business assist.png";

// Tabs image
import softtab1 from "../../../Images/Product & Services/Software/cripton.png";
import softtab2 from "../../../Images/Product & Services/Software/micorsoft.png";
import softtab3 from "../../../Images/Product & Services/Software/vmware.png";
import softtab4 from "../../../Images/Product & Services/Software/veritas.png";
import softtab5 from "../../../Images/Product & Services/Software/veeam.png";
import softtab6 from "../../../Images/Product & Services/Software/trend.png";

import Slider from "react-slick";
import NavigationBar from "../../Shared/NavigationBar/NavigationBar";
import Footer from "../../Shared/Footer/Footer";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}
const Pservices = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 3000,
    swipeToSlide: true,
    beforeChange: function (currentSlide, nextSlide) {
      console.log("before change", currentSlide, nextSlide);
    },
    afterChange: function (currentSlide) {
      console.log("after change", currentSlide);
    },
  };

  // Reload 0
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <NavigationBar />
      <div className="container-fw">
        <div className="row">
          <div className="slider-div">
            <Slider {...settings}>
              <div>
                <img
                  className=" img img-fluid softwar-img"
                  src={softImg1}
                  alt=""
                />
              </div>
              <div>
                <img className="img-fluid softwar-img" src={softImg2} alt="" />
              </div>
              <div>
                <img className="img-fluid softwar-img" src={softImg3} alt="" />
              </div>
              <div>
                <img className="img-fluid softwar-img" src={softImg4} alt="" />
              </div>
              <div>
                <img className="img-fluid softwar-img" src={softImg5} alt="" />
              </div>
              <div>
                <img className="img-fluid softwar-img" src={softImg1} alt="" />
              </div>
            </Slider>
          </div>
        </div>
      </div>

      <div className="container-fluid mt-5">
        <div className="row">
          <h2> PRODUCTS AND SERVCES</h2>

          <section className="container">
            <Box
              sx={{
                flexGrow: 1,
                bgcolor: "background.paper",
                display: "flex",
                height: 324,
              }}
            >
              <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                sx={{ borderRight: 1, borderColor: "divider" }}
              >
                <Tab label="Software Solution" {...a11yProps(0)} />
                <Tab label="Hardware Solution" {...a11yProps(1)} />
                <Tab label="Managed Service" {...a11yProps(2)} />
                <Tab label="Security & Surveillance" {...a11yProps(3)} />
                <Tab label="Network" {...a11yProps(4)} />
                <Tab label="Trend Micro" {...a11yProps(5)} />
              </Tabs>
              <TabPanel value={value} index={0}>
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                    <img src={softtab1} alt="" />
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 col-12"></div>
                </div>
              </TabPanel>
              <TabPanel value={value} index={1}>
                Item Two
              </TabPanel>
              <TabPanel value={value} index={2}>
                Item Three
              </TabPanel>
              <TabPanel value={value} index={3}>
                Item Four
              </TabPanel>
              <TabPanel value={value} index={4}>
                Item Five
              </TabPanel>
              <TabPanel value={value} index={5}>
                Item Six
              </TabPanel>
            </Box>
          </section>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default Pservices;
