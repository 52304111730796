import "./ConnectCutomerServices.css";
import InnovativeWebsite from "./InnovativeWebsite";
import { useState, useEffect } from "react";
import criptonImg1 from "../../../Images/Product & Services/Software/cripton.png";
import criptonImg2 from "../../../Images/Product & Services/Software/cvp_new.png";
import criptonImg3 from "../../../Images/Product & Services/Software/ac.png";
import criptonImg4 from "../../../Images/Product & Services/Software/ba.png";
import criptonImg5 from "../../../Images/Product & Services/Software/s_s_p.png";
import ssp from "../../../Images/youtubethumbs/ssp.png";
import hrbang1 from "../../../Images/youtubethumbs/hrbang1.png";
import cripton from "../../../Images/youtubethumbs/cripton.png";
import ie from "../../../Images/youtubethumbs/ie.png";

const ConnectCutomerServices = () => {
  const [activeYoutubeFrame, setActiveYoutubeFrame] = useState(
    "https://www.youtube.com/embed/0JUqp8ev7AI"
  );

  console.log("frame changing", activeYoutubeFrame);

  return (
    <div className="container-fluid" id="connect_customer_section">
      <div className="row container-padding ">
        <div className="col-xxl-8 col-xl-6 col-lg-6 col-md-7 col-sm-12 ">
          <h2 className="connect-h2">Explore with us</h2>
          <p className="sub-header"> Explore the era of emerging technology!</p>
          <p className="connect-p">
            We offer, Leverage agile frameworks to provide a robust synopsis for
            high level overviews. Iterative approaches to corporate strategy
            foster collaborative thinking to further the overall value
            proposition. It encompasses a wide range of technologies and
            applications, including everything from mobile apps and cloud
            computing to big data analytics and artificial intelligence. This
            industry is playing a critical role in driving innovation and growth
            across a wide range of industries, from healthcare and finance to
            transportation and retail. The software technology industry is also
            responsible for creating a variety of new jobs and career
            opportunities, from software developers and data scientists to
            project managers and business analysts. As the world becomes
            increasingly digital, the software technology industry will continue
            to play a vital role in driving economic growth and shaping the way
            we live and work.
          </p>
          <br />

          <p className="key-product-crip-logo"> Our Key products</p>
          <div className="cripton-logos-home-banner-div">
            <div className="place-center">
              <img
                className="img img-fluid home-product-logo"
                src={criptonImg1}
                alt="cripton pro erp"
              />
              <h4>Cripton Pro ERP</h4>
            </div>
            <div className="place-center">
              <img
                className="img img-fluid home-product-logo"
                src={criptonImg2}
                alt=" cripton vat pro"
              />
              <h4>Cripton VAT pro</h4>
            </div>
            <div className="place-center">
              <img
                className="img img-fluid home-product-logo"
                src={criptonImg4}
                alt="Business assist"
              />
              <h4>Business Assist</h4>
            </div>
            <div className="place-center">
              <img
                className=" img img-fluid home-product-logo"
                src={criptonImg5}
                alt="Self service portal"
              />

              <h4>Self service portal</h4>
            </div>
            <div className="place-center">
              <img
                className="img img-fluid home-product-logo"
                src={criptonImg3}
                alt="Alumni connect"
              />
              <h4>Alumni Connect</h4>
            </div>
          </div>
        </div>
        <div className="col-xxl-4 col-xl-6 col-lg-6 col-md-5 col-sm-12  ipad-container">
          {/* CC-Video-div */}
          {/* monitor */}
          {/* CC-Video */}
          <iframe
            width="500"
            className="ipad-frame"
            height="305"
            src={activeYoutubeFrame}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>

          <div
            className="youtube-video-i-frames"
            onClick={(e) => console.log("hello frame wrapper")}
          >
            <div
              className=""
              onClick={(e) =>
                setActiveYoutubeFrame(
                  "https://www.youtube.com/embed/i1C-GyFvlok"
                )
              }
            >
              <img src={ssp} className="youtube-thumbnail-images" />
            </div>

            <div
              className="mx-2    "
              onClick={() =>
                setActiveYoutubeFrame(
                  "https://www.youtube.com/embed/Euz-qZ1bWZI"
                )
              }
            >
              <img src={hrbang1} className="youtube-thumbnail-images" />
            </div>
            <div
              className="mx-2    "
              onClick={() =>
                setActiveYoutubeFrame(
                  "https://www.youtube.com/embed/_5T4ZvVVEYc"
                )
              }
            >
              <img src={cripton} className="youtube-thumbnail-images" />
            </div>

            <div
              className="mx-2 border   "
              onClick={() =>
                setActiveYoutubeFrame(
                  "https://www.youtube.com/embed/e_ZCBp1f5r0"
                )
              }
            >
              <img src={ie} className="youtube-thumbnail-images" />
            </div>

            <div
              className=""
              onClick={(e) =>
                setActiveYoutubeFrame(
                  "https://www.youtube.com/embed/i1C-GyFvlok"
                )
              }
            >
              <img src={ssp} className="youtube-thumbnail-images" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConnectCutomerServices;
