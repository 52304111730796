import React, { useEffect, useRef, Component } from "react";
import "./HomeBanner.css";
import homeBannerImg from "../../../Images/HomeBanner.png";
import hbnpic from "../../../Images/hbn-pic.jpg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import bannerlogo from "./banner.json";
import Lottie from "lottie-react";
import Typed from "react-typed";
import { Link } from "react-router-dom";

import Slider from "react-slick";

// import ScrollTopButton from "../../ScrollTopButton/ScrollTopButton";

// import animationData from "../../../../public/banner.json";
// import Lottie from "lottie-web";
//HOme banner page
const state = {
  searchtext: "",
  showSuggestion: false,
};
const inputHandler = (e) => {
  let updatetext = e.target.value;
  console.log(updatetext);
  //  searchtext = updatetext;
  //this.setState({ searchtext: updatetext });
  //console.log(updatedName);
};
const onSubmitHandler = (e) => {
  e.preventDefault();
  this.setState({
    showSuggestion: true,
  });
};

const homeBanner = {
  background: `url(${hbnpic})`,
  // width: "100%",
  height: "100vh",
  marginTop: 70,
  backgroundRepeat: "no-repeat",
  position: "relative",
  backgroundSize: "cover",
  overflowX: "hidden",
};

const HomeBanner = (e) => {
  //   const defaultOptions = {
  //     loop: true,
  //     autoplay: true,
  //     animationData: animationData,
  //   };

  const settings = {
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 3000,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  return (
    <div className="mb-5">
      <div style={homeBanner} className="home-banner-slider-div">
        <div className="container home-banner-slider">
          <Slider {...settings}>
            <div>
              <div className="single-slide-text-div">
                <h1 className="single-slide-h1">
                  1-Day Implementation, <br /> superfast.
                </h1>
                <p className="single-slide-p">
                  Implement VAT 2012 software <br /> in one to five days
                </p>
              </div>
              <div className="single-slide-buttons">
                <a className="features-learn-more" href="#" target="_blank">
                  FEATURES
                </a>
                <a className="Free-demo" href="#" target="_blank">
                  FREE DEMO
                </a>
              </div>
            </div>
            <div>
              <div className="single-slide-text-div">
                <h1 className="single-slide-h1">
                  Be VAT compliant, , <br /> automatically.
                </h1>
                <p className="single-slide-p">
                  PrismVAT ensures efficient compliance <br /> with
                  comprehensive VAT returns
                </p>
              </div>
              <div className="single-slide-buttons">
                <a className="features-learn-more" href="#" target="_blank">
                  NBR ENLISTMENT
                </a>
                <a className="Free-demo" href="#" target="_blank">
                  FREE DEMO
                </a>
              </div>
            </div>
            <div>
              <div className="single-slide-text-div">
                <h1 className="single-slide-h1">
                  One Step Production, <br /> easier.
                </h1>
                <p className="single-slide-p">
                  PrismVAT allows one click processing <br /> of a production
                  order
                </p>
              </div>
              <div className="single-slide-buttons">
                <a className="features-learn-more" href="#" target="_blank">
                  FEATURES
                </a>
                <a className="Free-demo" href="#" target="_blank">
                  FREE DEMO
                </a>
              </div>
            </div>
            <div>
              <div className="single-slide-text-div">
                <h1 className="single-slide-h1">
                  Versatile File Import, <br /> API integration..
                </h1>
                <p className="single-slide-p">
                  PrismVAT allows user to bring data <br /> from file and 3rd
                  Party System
                </p>
              </div>
              <div className="single-slide-buttons">
                <a className="features-learn-more" href="#" target="_blank">
                  LEARN MORE
                </a>
                <a className="Free-demo" href="#" target="_blank">
                  FREE DEMO
                </a>
              </div>
            </div>
            <div>
              <div className="single-slide-text-div">
                <h1 className="single-slide-h1">
                  VAT compliance through <br /> automation.
                </h1>
                <p className="single-slide-p">
                  Divine IT jointly organised <br /> VAT awareness program
                </p>
              </div>
              <div className="single-slide-buttons">
                <a className="features-learn-more" href="#" target="_blank">
                  NEWS AND EVENTS
                </a>
                <a className="Free-demo" href="#" target="_blank">
                  FREE DEMO
                </a>
              </div>
            </div>
            <div>
              <div className="single-slide-text-div">
                <h1 className="single-slide-h1">
                  1-Day Implementation, <br /> superfast.
                </h1>
                <p className="single-slide-p">
                  Implement VAT 2012 software <br /> in one to five days
                </p>
              </div>
              <div className="single-slide-buttons">
                <a className="features-learn-more" href="#" target="_blank">
                  FEATURES
                </a>
                <a className="Free-demo" href="#" target="_blank">
                  FREE DEMO
                </a>
              </div>
            </div>
          </Slider>
        </div>
      </div>
      <div className="banner-div-full">
        <section className="container">
          <div className="banner-div">
            {/* <div>
            <img className="banner-img" src={homeBannerImg} alt="" />
          </div> */}
            <div className="lottie-1-div">
              <Lottie
                animationData={bannerlogo}
                loop={true}
                className="lottie-1"
              />
            </div>

            <div className=" banner-text-div">
              <h1 className="h1-banner">
                We help to make your life better <br /> by our{" "}
                <span style={{ color: "#27559e" }}>
                  <Typed
                    data-aos="zoom-out-up"
                    className="strings-texts"
                    strings={["INNOVATION", "IDEAS", "PLANNING"]}
                    typeSpeed={80}
                    backSpeed={70}
                    loop
                  />
                </span>
              </h1>
              <p className="p-banner">
                "Innovation is the ability to see change as an opportunity – not
                a threat."
                <br /> - Steve Case
              </p>

              <div>
                <form className="mt-4 input-button" method="">
                  <div className=" ">
                    <input
                      type="text"
                      className="form-control"
                      name="searchtext"
                      id="inputText"
                      placeholder="enter the service"
                      onChange={inputHandler}
                      value={state.searchtext}
                    />
                  </div>
                  <div className="">
                    <button
                      type="submit"
                      onClick={onSubmitHandler}
                      className="btn banner-search"
                    >
                      <span>
                        {"   "}
                        {"   "}
                        <FontAwesomeIcon icon={faSearch} />
                      </span>
                      Search
                    </button>
                  </div>
                </form>
              </div>
              <div className="home-buttons">
                <form className="d-flex align-items-center justify-content-center banner-button">
                  <button className=" me-2 h-b-button1" type="submit">
                    <Link className="button-que" to="/RequestQuotation">
                      Request for Quotation
                    </Link>
                  </button>
                  <button className=" h-b-button2" type="submit">
                    <Link className="button-demo" to="/RequestDemo">
                      Get Demo
                    </Link>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </section>
        {/* <ScrollTopButton /> */}
      </div>
    </div>
  );
};

export default HomeBanner;
