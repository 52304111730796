import React, { useEffect } from "react";
import "./Works.css";
import workBannerImg from "../../../Images/WorksRoute/workRouteBanner.png";
import customerRelation from "../../../Images/WorksRoute/customers-relationship.png";
import betterUsterstanding from "../../../Images/WorksRoute/better-usterstanding.png";
import costEffect from "../../../Images/WorksRoute/cost-effect.png";
import timelyDelivary from "../../../Images/WorksRoute/timely delivary.png";
import quality from "../../../Images/WorksRoute/quality.png";
import relibility from "../../../Images/WorksRoute/relibility.png";
import customerDedication from "../../../Images/WorksRoute/customer-dedication.png";
import agileScrum from "../../../Images/WorksRoute/agile_scurm.png";
import teamsLottie from "./team-lottie.json";
import Lottie from "lottie-react";
import QualityBelives from "./QualityBelives";
import NavigationBar from "../../Shared/NavigationBar/NavigationBar";
import Footer from "../../Shared/Footer/Footer";
import { Parallax } from "react-parallax";
import ScrollTopButton from "../../ScrollTopButton/ScrollTopButton";

// const workBannerbg = {
//   background: `url(${workBannerImg})`,
//   backgroundRepeat: "no-repeat",
//   position: "relative",
//   backgroundSize: "cover",
//   overFlowX: "hiden",
//   position: "relative",
// };

const Work = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <NavigationBar />
      <Parallax
        blur={10}
        bgImage={workBannerImg}
        bgImageAlt="background-color"
        strength={200}
      >
        <div className="container wr-div">
          <h6 className="wr-t-h6">
            We love to talk with you about our Services
          </h6>
          <h1 className="wr-t-h1">How we work</h1>
        </div>
      </Parallax>

      <section>
        <div className="container">
          <div className="tag-hr">
            <h4 className="wr-t-h4">Exclusive Project & Team management</h4>
            <hr className="WorkRes_hr" />
          </div>
          <div className="row wr-middle-section">
            <div className="col-lg-6 col-md-6 col-12 wr-text-div">
              <div className="work-projects">
                <img className="work-img" src={customerRelation} alt="" />
                <h6 className="work-h6">Customer Relationship</h6>
              </div>
              <div className="work-projects">
                <img className="work-img" src={betterUsterstanding} alt="" />
                <h6 className="work-h6">Better Understanding </h6>
              </div>
              <div className="work-projects">
                <img className="work-img" src={costEffect} alt="" />
                <h6 className="work-h6">Cost Effective</h6>
              </div>
              <div className="work-projects">
                <img className="work-img" src={timelyDelivary} alt="" />
                <h6 className="work-h6">Timely Delivery</h6>
              </div>
              <div className="work-projects">
                <img className="work-img" src={quality} alt="" />
                <h6 className="work-h6">Quality Assurance</h6>
              </div>
              <div className="work-projects">
                <img className="work-img" src={relibility} alt="" />
                <h6 className="work-h6">Reliability & Commitment</h6>
              </div>
              <div className="work-projects">
                <img className="work-img" src={customerDedication} alt="" />
                <h6 className="work-h6">Dedicated customer support</h6>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 col-12 lottie-div-5">
              <Lottie
                animationData={teamsLottie}
                loop={true}
                className="lottie-5"
              />
              <div className="mobile-work-projects">
                <div className="work-projects">
                  <img className="work-img" src={customerRelation} alt="" />
                  <h6 className="work-h6">Customer Relationship</h6>
                </div>
                <div className="work-projects">
                  <img className="work-img" src={betterUsterstanding} alt="" />
                  <h6 className="work-h6">Better Understanding </h6>
                </div>
                <div className="work-projects">
                  <img className="work-img" src={costEffect} alt="" />
                  <h6 className="work-h6">Cost Effective</h6>
                </div>
                <div className="work-projects">
                  <img className="work-img" src={timelyDelivary} alt="" />
                  <h6 className="work-h6">Timely Delivery</h6>
                </div>
                <div className="work-projects">
                  <img className="work-img" src={quality} alt="" />
                  <h6 className="work-h6">Quality Assurance</h6>
                </div>
                <div className="work-projects">
                  <img className="work-img" src={relibility} alt="" />
                  <h6 className="work-h6">Reliability & Commitment</h6>
                </div>
                <div className="work-projects">
                  <img className="work-img" src={customerDedication} alt="" />
                  <h6 className="work-h6">Dedicated customer support</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="container mb-5">
        <div className="container agile-img-div">
          <img className="img img-fluid agile-img" src={agileScrum} alt="" />
        </div>
        <QualityBelives />
      </section>
      <Footer />
      <ScrollTopButton />
    </div>
  );
};

export default Work;
