import React, { useState, useEffect, useRef } from 'react';
import { Link, Navigate, useNavigate, Routes, Route } from "react-router-dom";
import axios from 'axios';
import Swal from 'sweetalert2';
import MasterDashboardLayout from '../../AdminPanel/MasterDashboardLayout'


function CreateOurTeam() {
    const navigate = useNavigate();

    const [ourTeam, setourTeam] = useState({
   
        name: '',
        designation: "",
        email_link:"",
        linkedin_link:""

    })

    const handleInputChange = (e) => {
        setourTeam({
            ...ourTeam, [e.target.name]: e.target.value
        })
    }

    const [image, setImage] = useState('');
    console.log('image info', image)
    const [picture, setPicture] = useState('');

    const onChangePicture = e => {
        console.log('picture: ', picture);
        setPicture(URL.createObjectURL(e.target.files[0]));
        setImage(e.target.files[0]);
    };


    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('name', ourTeam.name);
        formData.append('designation', ourTeam.designation);
        formData.append('email_link', ourTeam.email_link);
        formData.append('linkedin_link', ourTeam.linkedin_link);

        formData.append('image', image);


        console.log('check all data', formData);



        axios.post(`/api/save-our-team`, formData, {
            headers: { "Content-Type": "multipart/form-data" }
        }).then(res => {
            if (res.data.status == 200) {
                Swal.fire(res.data.message, '', 'success')
                navigate('/view-our-team')
                setourTeam({
                    name: '',
                    designation: "",
                    email_link:"",
                    linkedin_link:""
            
                });

                setImage('');
                setPicture('');
                document.getElementById('image').value = "";
            }
            // else if (res.data.status == 400) {
            //     setjobDesc({ ...jobDesc, error_list: res.data.errors });
            //     Swal.fire(jobDesc.error_list.job_id[0], '', 'error')

            // }
        })

    }
    return (
        <MasterDashboardLayout>
            <div class="col-12 grid-margin stretch-card">
                <div class="card">
                    <div class="card-body">
                        <div className='d-flex justify-content-between my-2'>
                            <h4 class="card-title">Add Our Team</h4>
                            <Link to="/view-testimonial"><button type='button' className='btn btn-warning btn-sm fw-bold rounded-pill'>View</button></Link>
                        </div>

                        <form class="forms-sample" onSubmit={handleSubmit} >
       
                            <div class="form-group">
                                <label for="exampleInputName1">Name</label>
                                <input type="text" class="form-control" id="exampleInputName1" placeholder="Name" onChange={handleInputChange} name="name" value={ourTeam.name} />
                            </div>
                            <div class="form-group">
                                <label for="exampleInputName1">Designation</label>
                                <input type="text" class="form-control" id="exampleInputName1" placeholder="Designation" onChange={handleInputChange} name="designation" value={ourTeam.designation} />
                            </div>
                            <div class="form-group">
                                <label for="exampleInputName1">Linkedin Link</label>
                                <input type="text" class="form-control" id="exampleInputName1" placeholder="Name" onChange={handleInputChange} name="linkedin_link" value={ourTeam.linkedin_link} />
                            </div>
                            <div class="form-group">
                                <label for="exampleInputName1">Email Link</label>
                                <input type="text" class="form-control" id="exampleInputName1" placeholder="Name" onChange={handleInputChange} name="email_link" value={ourTeam.email_link} />
                            </div>

                            <div class="form-group">
                                <label>Image</label>
                                <div class="input-group col-xs-12">
                                    <input type="file" class="form-control file-upload-info" placeholder="Upload Image" id="image" name="image" onChange={onChangePicture} />
                                    {/* <span class="input-group-append">
                                        <button class="file-upload-browse btn btn-primary" type="button">Upload</button>
                                    </span> */}
                                </div>
                            </div>

                            <button type="submit" class="btn btn-primary mr-2" onSubmit={handleSubmit}>Submit</button>
                        </form>
                    </div>
                </div>
            </div>
        </MasterDashboardLayout>
    )
}

export default CreateOurTeam