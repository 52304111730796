import React, { useState, useEffect } from 'react';
import { Link, Navigate, useNavigate, Routes, Route, useParams } from "react-router-dom";

import Swal from 'sweetalert2';
import axios from 'axios';


function AdminPasswordResetForm() {
    const navigate = useNavigate();

    const { id } = useParams()
    const [state, setstate] = useState({
        email: '',
        new_password: '',
        re_type_password: '',
        token: id
    })
    const handleChange = (e) => {
        setstate({
            ...state, [e.target.name]: e.target.value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        // setClickedRender(true);
        console.log('admin info check', state)

        // update('yes')

        // console.log('state checking', state)

        axios.get('sanctum/csrf-cookie').then(response => {

            axios.post('/api/reset-admin-password', state).then(res => {


                if (res.data.status == 200) {

                    Swal.fire(res.data.message, '', 'success')

                    navigate('/admin-login')

                    //     if (res.data.user_type=== 'admin') {

                    //       navigate('/dashboard')
                    //   }
                    //   else if(res.data.user_type=== 'user'){
                    //       navigate('/')

                    //   }

                }

                else {
                    Swal.fire('Invalid Credentials', '', 'warning')
                    //   setClickedRender(false)

                }
            })

        });
    }

    return (
        <>

            <div class="container-scroller">
                <div class="container-fluid page-body-wrapper full-page-wrapper">
                    <div class="content-wrapper d-flex align-items-center auth px-0">
                        <div class="row w-100 mx-0">
                            <div class="col-lg-4 mx-auto">
                                <div class="auth-form-light text-left py-5 px-4 px-sm-5">
                                    <div class="brand-logo">
                                        <img src="../../images/logo.svg" alt="logo" />
                                    </div>
                                    <h6 class="font-weight-light">Reset Password Form.</h6>
                                    <form class="pt-3" onSubmit={handleSubmit}>
                                        <div class="form-group">
                                            <input type="email" class="form-control form-control-lg" id="exampleInputEmail1" placeholder="Email" name='email' onChange={handleChange} />
                                        </div>

                                        <div class="form-group">
                                            <input type="password" class="form-control form-control-lg" id="exampleInputPassword1" placeholder="Password" name='new_password' onChange={handleChange} />
                                        </div>

                                        <div class="form-group">
                                            <input type="password" class="form-control form-control-lg" id="exampleInputPassword1" placeholder="Confirm Password" name='re_type_password' onChange={handleChange} />
                                        </div>
                                        <div class="mt-3">
                                            <button class="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn" type='submit' >UPDATE</button>
                                        </div>
                                        <div class="my-2 d-flex justify-content-between align-items-center">
                                            {/* <div class="form-check">
                    <label class="form-check-label text-muted">
                      <input type="checkbox" class="form-check-input"/>
                      Keep me signed in
                    <i class="input-helper"></i></label>
                  </div> */}
                                            <Link to="/admin-forgot-password">  <a href="" class="auth-link text-black my-2">Forgot password?</a></Link>
                                        </div>

                                        <div class="text-center mt-4 font-weight-light">
                                            Don't have an account? <a href="register.html" class="text-primary">Create</a>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default AdminPasswordResetForm