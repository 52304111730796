import React, { useState, useEffect } from 'react';

import { Link, Navigate, useNavigate, Routes, Route, Outlet } from "react-router-dom";

import Swal from 'sweetalert2';
import axios from 'axios';

function ProtectedRoutes() {

    const navigates=useNavigate();
//   const [storage, setStorage] = useState('');
//   useEffect(() => {
//     setStorage(localStorage.getItem('user_type') === 'admin');
//   }, [])
//   console.log('yeeo',storage)

    if(localStorage.getItem('user_type') === 'admin'){
        return <Outlet/>
    }
    else if(localStorage.getItem('user_type') ===!'admin'){
  return <Navigate to="/admin-login" />
    }
    else{
        return <Navigate to="/admin-login" />

    }
    

          
            // {storage?<Outlet /> :  <Navigate to="/admin-login" />
            // }
     
    
}

export default ProtectedRoutes;