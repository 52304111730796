import React from "react";
import "./Services.css";

const Service = ({ service }) => {
  return (
    <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4">
      <container>
        <a href={service.URL} className="service-div">
          <div>
            <img className="service-img" src={service.img} alt="" />
            <h6 className="service-h6">{service.name}</h6>
          </div>
          <div>
            <p className="service-p">{service.description}</p>
          </div>
        </a>
      </container>
    </div>
  );
};

export default Service;
