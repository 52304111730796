import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import Slider from "react-slick";
import img1 from "../../../Images/About-Us/Inovaiontion/icons8-heart-48.png";
import img2 from "../../../Images/About-Us/Inovaiontion/icons8-us-dollar-48.png";
import img3 from "../../../Images/About-Us/Inovaiontion/icons8-eye-48.png";
import img4 from "../../../Images/About-Us/Inovaiontion/icons8-star-48.png";
import img5 from "../../../Images/About-Us/Inovaiontion/icons8-share-48.png";
import img6 from "../../../Images/About-Us/Inovaiontion/about-heart-icon.png";
import "slick-carousel/slick/slick.css";

import "slick-carousel/slick/slick-theme.css";
import "./InformationTechnology.css";

const InformationTechnology = () => {
  var settings = {
    dots: true,
    infinite: true,
    autoPlay:true,
    autoplaySpeed: 3000,

    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,

        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,

        },
      },
    ],
  };
  return (
    <section className="container">
      <div className="information-div">
        <h3 className="hardware-h3">We believe in</h3>
        <p className="information-p1">
          Pakiza Technovation Limited believes in delivering Excellence through
          Innovation using Technology and ensuring high Satisfaction level of
          its clients.
        </p>
      </div>
      <div>
        <Slider {...settings}
            autoplaySpeed={3000}
            autoplay={true}
        
        >
          <div className="infor-card-div">
            <img className="img-fluid info-img" src={img1} alt="" />
            <h4 className="infor-h4">Customer Satisfaction</h4>
            <p className="infor-p">
              Pakiza Technovation Limited team is keen to understand its clients
              expectations and thrives to surpass expectations through expertise
              and achieve loyalty.
            </p>
          </div>
          <div className="infor-card-div">
            <img className="img-fluid info-img" src={img2} alt="" />
            <h4 className="infor-h4">Value For money</h4>
            <p className="infor-p">
              Pakiza Technovation Limited always loves to optimize, not to be
              lowest in price, its solution to meet its clients requirement and
              thus ensure vaue for money.
            </p>
          </div>
          <div className="infor-card-div">
            <img className="img-fluid info-img" src={img3} alt="" />
            <h4 className="infor-h4">Visibility</h4>
            <p className="infor-p">
              Visibility of ICT projects are result oriented. Hence Pakiza
              Technovation Limited ensures the desired result and makes it
              visible by showing exact result that is promised.
            </p>
          </div>
          <div className="infor-card-div">
            <img className="img-fluid info-img" src={img4} alt="" />
            <h4 className="infor-h4">High Quality</h4>
            <p className="infor-p">
              Ensuring high quality in both product and service pakiza
              Technovation Limited wants to give its clients a great experience
              and to be reliable and first choice of its kind.
            </p>
          </div>
          <div className="infor-card-div">
            <img className="img-fluid info-img" src={img5} alt="" />
            <h4 className="infor-h4">Right to know</h4>
            <p className="infor-p">
              We believe that clients have the right to know in and outs of
              every elements of a solution or service or product and we do
              ensure.
            </p>
          </div>
          <div className="infor-card-div">
            <img className="img-fluid info-img" src={img6} alt="" />
            <h4 className="infor-h4">Service Excellence</h4>
            <p className="infor-p">
              Pakiza Technovation Limited team is hungry to generate Innovative
              ideas, implement those using right technologies and ensure
              Excellence is delivered.
            </p>
          </div>
        </Slider>
      </div>
      {/* <div className="row full-infor-card-div">
        <div className="col-lg-3 col-md-3 col-sm-6 col-12 infor-card-div">
          <img className="img-fluid info-img" src={inoImg1} alt="" />
          <h4 className="infor-h4">Lorem ipsum dolor sit amet</h4>
          <p className="infor-p">
            Lorem ipsum dolor sit amet consectetur. At nunc at amet tristique
            sed sem ultrices morbi. Id lacus ornare pharetra.
          </p>
          <a className="text-decoration-none info-anchor" href="#">
            Read More{" "}
            <span>
              <FontAwesomeIcon icon={faArrowRight} />
            </span>
          </a>
        </div>
        <div className="col-lg-3 col-md-3 col-sm-6 col-12 infor-card-div">
          <img className="img-fluid info-img" src={inoImg3} alt="" />
          <h4 className="infor-h4">Lorem ipsum dolor sit amet</h4>
          <p className="infor-p">
            Lorem ipsum dolor sit amet consectetur. At nunc at amet tristique
            sed sem ultrices morbi. Id lacus ornare pharetra.
          </p>
          <a className="text-decoration-none info-anchor" href="#">
            Read More{" "}
            <span>
              <FontAwesomeIcon icon={faArrowRight} />
            </span>
          </a>
        </div>
        <div className="col-lg-3 col-md-3 col-sm-6 col-12 infor-card-div">
          <img className="img-fluid info-img" src={inoImg4} alt="" />
          <h4 className="infor-h4">Lorem ipsum dolor sit amet</h4>
          <p className="infor-p">
            Lorem ipsum dolor sit amet consectetur. At nunc at amet tristique
            sed sem ultrices morbi. Id lacus ornare pharetra.
          </p>
          <a className="text-decoration-none info-anchor" href="#">
            Read More{" "}
            <span>
              <FontAwesomeIcon icon={faArrowRight} />
            </span>
          </a>
        </div>
        <div className="col-lg-3 col-md-3 col-sm-6 col-12 infor-card-div">
          <img className="img-fluid info-img" src={inoImg6} alt="" />
          <h4 className="infor-h4">Lorem ipsum dolor sit amet</h4>
          <p className="infor-p">
            Lorem ipsum dolor sit amet consectetur. At nunc at amet tristique
            sed sem ultrices morbi. Id lacus ornare pharetra.
          </p>
          <a className="text-decoration-none info-anchor" href="#">
            Read More{" "}
            <span>
              <FontAwesomeIcon icon={faArrowRight} />
            </span>
          </a>
        </div>
        <div className="col-lg-3 col-md-3 col-sm-6 col-12 infor-card-div">
          <img className="img-fluid info-img" src={inoImg5} alt="" />
          <h4 className="infor-h4">Lorem ipsum dolor sit amet</h4>
          <p className="infor-p">
            Lorem ipsum dolor sit amet consectetur. At nunc at amet tristique
            sed sem ultrices morbi. Id lacus ornare pharetra.
          </p>
          <a className="text-decoration-none info-anchor" href="#">
            Read More{" "}
            <span>
              <FontAwesomeIcon icon={faArrowRight} />
            </span>
          </a>
        </div>
        <div className="col-lg-3 col-md-3 col-sm-6 col-12 infor-card-div">
          <img className="img-fluid info-img" src={inoImg3} alt="" />
          <h4 className="infor-h4">Lorem ipsum dolor sit amet</h4>
          <p className="infor-p">
            Lorem ipsum dolor sit amet consectetur. At nunc at amet tristique
            sed sem ultrices morbi. Id lacus ornare pharetra.
          </p>
          <a className="text-decoration-none info-anchor" href="#">
            Read More{" "}
            <span>
              <FontAwesomeIcon icon={faArrowRight} />
            </span>
          </a>
        </div>
        <div className="col-lg-3 col-md-3 col-sm-6 col-12 infor-card-div">
          <img className="img-fluid info-img" src={inoImg2} alt="" />
          <h4 className="infor-h4">Lorem ipsum dolor sit amet</h4>
          <p className="infor-p">
            Lorem ipsum dolor sit amet consectetur. At nunc at amet tristique
            sed sem ultrices morbi. Id lacus ornare pharetra.
          </p>
          <a className="text-decoration-none info-anchor" href="#">
            Read More{" "}
            <span>
              <FontAwesomeIcon icon={faArrowRight} />
            </span>
          </a>
        </div>
        <div className="col-lg-3 col-md-3 col-sm-6 col-12 infor-card-div">
          <img className="img-fluid info-img" src={inoImg6} alt="" />
          <h4 className="infor-h4">Lorem ipsum dolor sit amet</h4>
          <p className="infor-p">
            Lorem ipsum dolor sit amet consectetur. At nunc at amet tristique
            sed sem ultrices morbi. Id lacus ornare pharetra.
          </p>
          <a className="text-decoration-none info-anchor" href="#">
            Read More{" "}
            <span>
              <FontAwesomeIcon icon={faArrowRight} />
            </span>
          </a>
        </div>
      </div> */}
    </section>
  );
};

export default InformationTechnology;
