import React, { Component } from "react";
import Slider from "react-slick";
import "./ValuableClients.css";
import client1 from "../../../Images/Company-Client/Clients- 1.png";
import client2 from "../../../Images/Company-Client/Clients- 2.jfif";
import client3 from "../../../Images/Company-Client/Clients- 3.jpg";
import client4 from "../../../Images/Company-Client/Clients- 4.png";
import austta from "../../../Images/Company-Client/aust_logo.png";
import client6 from "../../../Images/Company-Client/Clients- 6.jfif";
import client7 from "../../../Images/Company-Client/Clients- 7.jfif";
import client8 from "../../../Images/Company-Client/Clients- 8.png";
import client9 from "../../../Images/Company-Client/Clients- 9.png";
import client10 from "../../../Images/Company-Client/Clients- 10.jfif";
import client11 from "../../../Images/Company-Client/Clients- 11.png";
import client12 from "../../../Images/Company-Client/Clients- 12.jfif";
import client13 from "../../../Images/Company-Client/Clients- 13.jfif";

export default class ValuableClients extends Component {
  render() {
    const settings = {
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      speed: 2000,
      autoplaySpeed: 2000,
      cssEase: "linear",
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <div className="container valuableClientsDiv">
        <div>
          <h5 className="valu-h5">OUR WORK WITH PRIDE</h5>
          <h1 className="valu-h1">Our Valuable Clients</h1>
          <p className="valu-p">
            We are honored to serve our valued clients and strive to exceed
            their expectations
          </p>
        </div>
        <Slider {...settings}>
          <div>
            <img className="clients-imgs img-fluid" src={client1} alt="" />
          </div>
          <div>
            <img className="clients-imgs img-fluid" src={client2} alt="" />
          </div>
          <div>
            <img className="clients-imgs img-fluid" src={client3} alt="" />
          </div>
          <div>
            <img className="clients-imgs img-fluid" src={client4} alt="" />
          </div>
          <div>
            <img className="clients-imgs img-fluid" src={austta} alt="" />
          </div>
          <div>
            <img className="clients-imgs img-fluid" src={client6} alt="" />
          </div>
          <div>
            <img className="clients-imgs img-fluid" src={client7} alt="" />
          </div>
          <div>
            <img className="clients-imgs img-fluid" src={client8} alt="" />
          </div>
          <div>
            <img className="clients-imgs img-fluid" src={client9} alt="" />
          </div>
          <div>
            <img className="clients-imgs img-fluid" src={client10} alt="" />
          </div>
          <div>
            <img className="clients-imgs img-fluid" src={client11} alt="" />
          </div>
          <div>
            <img className="clients-imgs img-fluid" src={client12} alt="" />
          </div>
          <div>
            <img className="clients-imgs img-fluid" src={client13} alt="" />
          </div>
        </Slider>
      </div>
    );
  }
}

// import React from "react";
// import valuClient from "../../../Images/Company-Client/companies.png";
// import "./ValuableClients.css";

// const ValuableClients = () => {
//   return (
//     <div className="valu-div-full">
//       <div>
//         <h5 className="valu-h5">Our Work With Pride</h5>
//         <h1 className="valu-h1">Our Valuable Clients</h1>
//         <p className="valu-p">
//           I am very helped in making a website and business development that
//           makes the product that I have to have a quality for use by the user
//         </p>
//       </div>
//       <div
//         id="carouselExampleIndicators"
//         className="carousel slide container"
//         data-bs-ride="true"
//       >
//         <div className="carousel-indicators">
//           <button
//             type="button"
//             data-bs-target="#carouselExampleIndicators"
//             data-bs-slide-to="0"
//             className="active"
//             aria-current="true"
//             aria-label="Slide 1"
//           ></button>
//           <button
//             type="button"
//             data-bs-target="#carouselExampleIndicators"
//             data-bs-slide-to="1"
//             aria-label="Slide 2"
//           ></button>
//           <button
//             type="button"
//             data-bs-target="#carouselExampleIndicators"
//             data-bs-slide-to="2"
//             aria-label="Slide 3"
//           ></button>
//         </div>
//         <div className="carousel-inner">
//           <div className="carousel-item active">
//             <img src={valuClient} className="d-block w-100" alt="..." />
//           </div>
//           <div className="carousel-item">
//             <img src={valuClient} className="d-block w-100" alt="..." />
//           </div>
//           <div className="carousel-item">
//             <img src={valuClient} className="d-block w-100" alt="..." />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ValuableClients;
