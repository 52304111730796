import React, { useState, useEffect, Component } from "react";
import Footer from "../../Shared/Footer/Footer";
import NavigationBar from "../../Shared/NavigationBar/NavigationBar";
import PropTypes from "prop-types";

import ssp from "../../../Images/Product & Services/Software/s_s_p.png";
import ba from "../../../Images/Product & Services/Software/ba.png";
import softwarebg from "../../../Images/Product & Services/Software/softwarebg.jpg";
import cvp_new from "../../../Images/Product & Services/Software/cvp_new.png";
import ac from "../../../Images/Product & Services/Software/ac.png";

import softTabimg6 from "../../../Images/Product & Services/Software/cripton.png";

import dataBG from "../../../Images/Product & Services/DataS/dataBannerBg.png";
import softwareIcon from "../../../Images/Product & Services/Software/software.png";
import criptonproerp from "../../../Images/Product & Services/Software/criptonproerp.jpg";
import "../DataCenter/DataCenter.css";
import "../SoftwareSolution/SoftwareSolution.css";
import { faFileArrowDown, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Slider from "react-slick";
import YoutubeEmbed from "../YouTubeVideo";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { textAlign } from "@mui/system";
import { Link } from "react-router-dom";
import ScrollTopButton from "../../ScrollTopButton/ScrollTopButton";
import { Helmet } from "react-helmet";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const SoftwareSolution = () => {
  // Button
  const handleClickCustom = () => {
    window.location.href = "tel:+8801713060441";
  };
  // Modal
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [isDownloading, setIsDownloading] = useState(false);
  const handleDownload = () => {
    setIsDownloading(true);
    const link = document.createElement("a");
    link.href = "/";
    link.download = "/";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setIsDownloading(false);
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // react slick
  const settings = {
    dots: true,
    infinite: true,

    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
  };
  // Reload 0
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [youtubeVideoRender, setYoutubeVideoRender] = useState("");
  const [targetedModal, setTargetedModal] = useState("");

  return (
    <div className="main_product_page software_solution">
      <section>
        <NavigationBar />
      </section>
      <Helmet>
        <title>Software Solution</title>
        <meta name="description" content="To get Your Software Solution"></meta>
      </Helmet>
      <div className="data-center-wrapper">
        <img className="img img-fluid bg_img" src={softwarebg} />

        <div className="data-mobile-texts-div ">
          <div className="data-mobile-texts">
            <div className="d-flex align-items-center text-center">
              <img
                className="img img-fluid"
                src={softwareIcon}
                alt="software"
              />
              <h5 className="data-head-tag ms-2">Software & Solution</h5>
            </div>

            <p className="data-p2">
              Software is an essential component of modern technology, and
              software solutions can provide effective solutions to a wide range
              of problems. By automating tasks, improving collaboration, and
              providing valuable insights, software can help businesses and
              individuals become more productive and efficient. As technology
              continues to evolve, we can expect to see even more innovative
              software solutions that will help us work smarter and achieve our
              goals more effectively. Software solutions is their ability to
              automate repetitive tasks, allowing users to focus on more
              valuable work. For example, a marketing team can use marketing
              automation software to streamline their campaigns and reduce the
              time and effort needed to carry out various tasks. Similarly, a
              customer service team can use a ticketing system to manage
              customer requests more efficiently, reducing the workload on
              individual agents.
            </p>
          </div>
        </div>
      </div>
      <div className="gap"></div>
      <div className="gap"></div>
      <div className="gap"></div>

      <div className="container-fluid featured-product-mobile">
        <h4 className="d-flex featured-product-text">
          <span className="software-offer-header justify-content-center ">
            {" "}
            Our featured products
          </span>
        </h4>
        <div className="gap"></div>
        <div className="row container-fluid ">
          <div className="software-solution-card-items col-xl-4 col-lg-4 p-2 col-md-4 col-sm-6 col-12  ">
            <div className="img-header-text   ">
              <div className="software-solution-img1">
                <img className="" src={softTabimg6} alt="" />
              </div>
              <div className="">
                <h4 className="soft-crip-tags">CRIPTON PRO</h4>
                <p>ERP solution</p>
              </div>
            </div>

            <div className="my-4 ms-3 ">
              <h6>
                {" "}
                CORE <strong>MODULES </strong>
              </h6>

              <div className="">
                <ul>
                  <li> CRIPTON PRO</li>
                  <li>ERP SOLUTION </li>
                  <li>Human Resources & Payroll. </li>
                  <li> Self Service Portal.</li>
                  <li>Purchase & Procurement. </li>
                  <li>Inventory Management. </li>
                  <li>Commercial. </li>
                  <li>Accounts. </li>
                  <li>Merchandising. </li>
                  <li>IE Planning. </li>
                  <li>Production Management System. </li>
                  <li>Asset Management System. </li>
                  <li>Vehicle Management System. </li>
                  <li>Visitor Management System. </li>
                  <li>Gate Pass Management System. </li>
                  <li>Management Information System (MIS). </li>
                  <li>Mobile Application (iOS & Android) </li>
                </ul>
              </div>
            </div>

            <div className=" last-sec-btn  text-center">
              <div className=" py-0 my-3 ">
                <a href="https://play.google.com/store/apps/details?id=com.ptvl.cripton_erp&pli=1">
                  <img
                    src="https://cdn-icons-png.flaticon.com/512/300/300218.png "
                    class="icons-style me-2"
                  />
                </a>
                <img
                  src="https://www.apple.com/v/app-store/b/images/overview/icon_appstore__ev0z770zyxoy_large_2x.png "
                  class="icons-style"
                />
              </div>

              <button
                onClick={() =>
                  setYoutubeVideoRender(
                    "https://www.youtube.com/watch?v=_5T4ZvVVEYc&t=5s"
                  )
                }
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop"
                className="see-video-button "
              >
                <i className="fa fa-video me-2"></i> See video
              </button>
              <div
                class="modal fade modal-width"
                id="staticBackdrop"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabindex="-1"
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog">
                  <div class="modal-content">
                    <div class="modal-header py-3">
                      <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div class="modal-body">
                      <div className="modal-iframe-wrapper ">
                        <iframe
                          width="100%"
                          height="315"
                          src="https://www.youtube.com/embed/_5T4ZvVVEYc"
                          title="YouTube video player"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          allowfullscreen
                        ></iframe>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="software-solution-card-items col-xl-4 col-lg-4 p-2 col-md-4 col-sm-6 col-12 ">
            <div className="img-header-text ">
              <div className="software-solution-img1">
                <img className="" src={cvp_new} alt="" />
              </div>
              <div className="">
                <h4 className="soft-crip-tags">CRIPTON VAT PRO</h4>
                <p>Makes VAT easy</p>
              </div>
            </div>

            <div className="my-4 ms-3 ">
              <h6>
                {" "}
                CORE <strong>MODULES </strong>
              </h6>

              <div className="">
                <ul>
                  {" "}
                  <li>
                    Dynamic product management for all industries and
                    categories.{" "}
                  </li>
                  <li>Product HS code integration.</li>
                  <li>Supplier and customer management.</li>
                  <li>Local and foreign purchase. .</li>
                  <li>Multi warehouse manage. . </li>
                  <li>Product tracking by barcode system. . </li>
                  <li>Product quality control.</li>
                  <li>BOM for finished goods. . </li>
                  <li>Production process.</li>
                  <li>Distribution process.</li>
                  <li>Sales and collection.</li>
                  <li>Customer relationship management.</li>
                  <li>Customer visit log tracking.</li>
                  <li>Accounting solution.</li>
                  <li>Profit and loss statement</li>
                  <li>Accounts receivable and payable. </li>
                  <li>Mushak reports (NBR complaint)</li>{" "}
                </ul>
              </div>
              <div className="See-more-btn">
                <a href="/criptonVatPro" target="_blank">
                  See More
                </a>
              </div>
            </div>

            <div className=" last-sec-btn  text-center">
              <button
                onClick={() =>
                  setYoutubeVideoRender(
                    "https:www.youtube.com/watch?v=_5T4ZvVVEYc&t=5s"
                  )
                }
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop2"
                className="see-video-button "
              >
                <i className="fa fa-video me-2"></i> See video
              </button>
              <div
                class="modal fade modal-width"
                id="staticBackdrop2"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabindex="-1"
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog">
                  <div class="modal-content">
                    <div class="modal-header py-3">
                      <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div class="modal-body">
                      <div className="modal-iframe-wrapper ">
                        <iframe
                          width="100%"
                          height="315"
                          src="https://www.youtube.com/embed/XhhpdI8wfm4"
                          title="YouTube video player"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          allowfullscreen
                        ></iframe>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="software-solution-card-items col-xl-4 col-lg-4 p-2 col-md-4 col-sm-6 col-12 ">
            <div className="img-header-text ">
              <div className="software-solution-img1">
                <img className="" src={ssp} alt="" />
              </div>
              <div className="">
                <h4 className="soft-crip-tags">Self Service Portal</h4>
                <p>Go paperless</p>
              </div>
            </div>

            <div className="my-4 ms-3 ">
              <h6>
                {" "}
                CORE <strong>MODULES </strong>
              </h6>

              <div className="">
                <ul>
                  <li> Document creation</li>
                  <li> Workflow creation</li>
                  <li> Requistions</li>
                  <li> Approvals</li>
                  <li> Implementation</li>
                  <li> Audit</li>{" "}
                  {/* <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br />{" "}
//                 <br /> <br /> <br /> <br />
//                 <br /> */}{" "}
                </ul>
              </div>
            </div>

            <div className=" last-sec-btn  text-center">
              {/* <div className=" py-0 my-3 ">
                <a href="https://play.google.com/store/apps/details?id=com.ptvl.cripton_erp&pli=1">
                  <img
                    src="https://cdn-icons-png.flaticon.com/512/300/300218.png "
                    class="icons-style me-2"
                  />
                </a>
                <img
                  src="https://www.apple.com/v/app-store/b/images/overview/icon_appstore__ev0z770zyxoy_large_2x.png "
                  class="icons-style"
                />
              </div> */}

              <button
                onClick={() =>
                  setYoutubeVideoRender(
                    "https://www.youtube.com/embed/i1C-GyFvlok"
                  )
                }
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop3"
                className="see-video-button "
              >
                <i className="fa fa-video me-2"></i> See video
              </button>
              <div
                class="modal fade modal-width"
                id="staticBackdrop3"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabindex="-1"
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog">
                  <div class="modal-content">
                    <div class="modal-header py-3">
                      <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div class="modal-body">
                      <div className="modal-iframe-wrapper ">
                        <iframe
                          width="100%"
                          height="315"
                          src="https://www.youtube.com/embed/i1C-GyFvlok"
                          title="YouTube video player"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          allowfullscreen
                        ></iframe>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="software-solution-card-items col-xl-4 col-lg-4 p-2 col-md-4 col-sm-6 col-12 mt-5">
            <div className="img-header-text ">
              <div className="software-solution-img1">
                <img className="" src={ba} alt="" />
              </div>
              <div className="">
                <h4 className="soft-crip-tags">BUSINESS ASSIST</h4>
                <p>Integrated CRM System</p>
              </div>
            </div>

            <div className="my-4 ms-3 ">
              <h6>
                {" "}
                CORE <strong>MODULES </strong>
              </h6>

              <div className="">
                <ul>
                  <li> CRIPTON PRO</li>
                  <li>ERP SOLUTION </li>
                  <li>Human Resources & Payroll. </li>
                  <li> Self Service Portal.</li>
                  <li>Purchase & Procurement. </li>
                  <li>Inventory Management. </li>
                  <li>Commercial. </li>
                  <li>Accounts. </li>
                  <li>Merchandising. </li>
                  <li>IE Planning. </li>
                  <li>Production Management System. </li>
                  <li>Asset Management System. </li>
                  <li>Vehicle Management System. </li>
                  <li>Visitor Management System. </li>
                  <li>Gate Pass Management System. </li>
                  <li>Management Information System (MIS). </li>
                  <li>Mobile Application (iOS & Android) </li>{" "}
                </ul>
              </div>
            </div>

            <div className=" last-sec-btn  text-center">
              {/* <div className=" py-0 my-3 ">
                <a href="https://play.google.com/store/apps/details?id=com.ptvl.cripton_erp&pli=1">
                  <img
                    src="https://cdn-icons-png.flaticon.com/512/300/300218.png "
                    class="icons-style me-2"
                  />
                </a>
                <img
                  src="https://www.apple.com/v/app-store/b/images/overview/icon_appstore__ev0z770zyxoy_large_2x.png "
                  class="icons-style"
                />
              </div> */}

              <button
                onClick={() =>
                  setYoutubeVideoRender(
                    "https:www.youtube.com/watch?v=_5T4ZvVVEYc&t=5s"
                  )
                }
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop4"
                className="see-video-button "
              >
                <i className="fa fa-video me-2"></i> See video
              </button>
              <div
                class="modal fade modal-width"
                id="staticBackdrop4"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabindex="-1"
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog">
                  <div class="modal-content">
                    <div class="modal-header py-3">
                      <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div class="modal-body">
                      <div className="modal-iframe-wrapper ">
                        {/* <iframe
                          width="100%"
                          height="315"
                          src="https://www.youtube.com/embed/_5T4ZvVVEYc"
                          title="YouTube video player"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          allowfullscreen
                        ></iframe> */}
                        <button
                          onClick={handleClickCustom}
                          className="custom-button-demo-lower"
                        >
                          <span className="me-2">
                            <FontAwesomeIcon icon={faPhone} />
                          </span>
                          CALL FOR APPOINMENT AND DEMO
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="software-solution-card-items col-xl-4 col-lg-4 p-2 col-md-4 col-sm-6 col-12 mt-5">
            <div className="img-header-text ">
              <div className="software-solution-img1">
                <img className="" src={ac} alt="" />
              </div>
              <div className="">
                <h4 className="soft-crip-tags">ALUMNI CONNECT</h4>
                <p>Connecting Endless</p>
              </div>
            </div>

            <div className="my-4 ms-3 ">
              <h6>
                {" "}
                CORE <strong>MODULES </strong>
              </h6>

              <div className="">
                <ul>
                  <li>
                    {" "}
                    Easy connection with the alumni & fellows within the
                    institute
                  </li>
                  <li> Secure and faster</li>
                  <li>
                    {" "}
                    Integrated news, shout, job, event, article, advertisement,
                    etc portal feature
                  </li>
                  <li> Integrated dynamic notification</li>
                  <li> Dynamic user and profile management</li>
                  <li> Seamless integration with dynamic admin panel</li>
                  <li> One-to-one communication (secure) feature</li>
                  <li>
                    All available & important 3rd parties integration (payment,
                    sms, mail, etc)
                  </li>
                  <li>Cloud-enabled</li>
                  <li>Mobile Application (iOS & Android) </li>
                </ul>
              </div>
            </div>

            <div className=" last-sec-btn  text-center">
              {/* <div className=" py-0 my-3 ">
                <a href="https://play.google.com/store/apps/details?id=com.ptvl.cripton_erp&pli=1">
                  <img
                    src="https://cdn-icons-png.flaticon.com/512/300/300218.png "
                    class="icons-style me-2"
                  />
                </a>
                <img
                  src="https://www.apple.com/v/app-store/b/images/overview/icon_appstore__ev0z770zyxoy_large_2x.png "
                  class="icons-style"
                />
              </div> */}

              <button
                onClick={() =>
                  setYoutubeVideoRender(
                    "https:www.youtube.com/watch?v=_5T4ZvVVEYc&t=5s"
                  )
                }
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop5"
                className="see-video-button "
              >
                <i className="fa fa-video me-2"></i> See video
              </button>
              <div
                class="modal fade modal-width"
                id="staticBackdrop5"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabindex="-1"
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog">
                  <div class="modal-content">
                    <div class="modal-header py-3">
                      <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div class="modal-body">
                      <div className="modal-iframe-wrapper ">
                        {/* <iframe
                          width="100%"
                          height="315"
                          src="https://www.youtube.com/embed/_5T4ZvVVEYc"
                          title="YouTube video player"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          allowfullscreen
                        ></iframe> */}
                        <button
                          onClick={handleClickCustom}
                          className="custom-button-demo-lower"
                        >
                          <span className="me-2">
                            <FontAwesomeIcon icon={faPhone} />
                          </span>
                          CALL FOR APPOINMENT AND DEMO
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr className="half-hr" />

      <section>
        <Footer />
      </section>
      <ScrollTopButton />
    </div>
  );
};

export default SoftwareSolution;
